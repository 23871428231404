export const startsWith = (source, val) => {
  if (source instanceof Array) {
    if (val instanceof Array) {
      if (val.length > source.length) return false;

      for (const i in val) {
        const si = parseInt(source[i]);
        const vi = parseInt(val[i]);

        // support if numeric index is stringified in one but not the other
        if (!isNaN(si) || !isNaN(vi)) {
          if (si !== vi) {
            return false;
          }
        } else if (source[i] !== val[i]) {
          return false;
        }
      }
    }
  } else if (typeof source === 'string') {
    if (typeof val === 'string') {
      return source.startsWith(val);
    }
  } else {
    return false;
  }

  return true;
};

// finds a value for a key/regex deeply nested in an object
export const deepFind = (object = {}, key) => {
  if (typeof object !== 'object' || !key) return;

  let result;

  const regex = key instanceof RegExp && new RegExp(key);

  for (const prop in object) {
    if (object.hasOwnProperty(prop)) {
      if ((regex && regex.test(prop)) || key === prop) {
        result = object[prop];
        break;
      }

      if (typeof object[prop] === 'object') {
        result = deepFind(object[prop], key);
        if (result) {
          break;
        }
      }
    }
  }

  return result;
};
