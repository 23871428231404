import _ from 'lodash';

import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask';
import { faServer } from '@fortawesome/pro-solid-svg-icons/faServer';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons/faHtml5';
// import { faCss3 } from '@fortawesome/free-brands-svg-icons/faCss3';

import { safeParse } from '@platform/utils/json';
import { safeLoad } from '@platform/utils/yaml';

export const defaultFileContents = {
  hub: {
    title: 'New Hub',
    header: {
      nav: {
        left: [],
        center: [],
        right: [
          {
            title: 'Help',
            path: 'https://docs.stoplight.io',
          },
        ],
      },
    },
    pages: {
      '/': {
        title: 'Home',
        data: {
          blocks: [
            {
              type: 'text',
              data:
                '# Welcome to your new hub. \n\nIts kind of empty in here - click the **"Design"** button at the top of the editor to switch to edit mode to add new pages, content, and subpages!\n\nUse references to power parts of your Hub with other files in this project (like Modeling/OAS/Swagger files).',
            },
          ],
        },
      },
    },
  },

  oas2: {
    swagger: '2.0',
    info: {
      title: 'My API',
      version: '1.0',
    },
    host: 'example.com',
    paths: {},
  },

  oas3: {
    openapi: '3.0.0',
    info: {
      title: 'My API',
      version: '1.0.0',
    },
    paths: {},
  },

  scenarios: {
    scenarioVersion: '1.1',
    scenarios: {},
  },

  prism: {
    apis: [],
    rules: [],
  },

  markdown: `# Header

A paragraph of content.
`,
};

export const getDefaultFileContents = ({ type, name, filePath }) => {
  const contents = defaultFileContents[type];
  if (!contents) return '';

  if (name) {
    switch (type) {
      case 'hub':
        contents.title = `${_.startCase(name)} Hub`;
        break;
      case 'oas2':
        contents.info.title = `${_.startCase(name)} API`;
        break;

      case 'oas3':
        contents.info.title = `${_.startCase(name)} API`;
        break;
      default:
        break;
    }
  }

  return contents;
};

// "{extension}.{fileType}" or "(anything).{extension}.{fileType}"
export const defaultFileFilter = {
  documentation: [/(^|.*?\.)hub\.(json|y(a?)ml)$/],
  modeling: [/(^|.*?\.)oas(2|3)\.(json|y(a?)ml)$/],
  testing: [/(^|.*?\.)scenarios\.(json|y(a?)ml)$/],
  servers: [/(^|.*?\.)prism\.(json|y(a?)ml)$/],
  markdown: [/.*?\.md$/],
  html: [/.*?\.html$/],
  // css: [/.*?\.html$/],
  config: [/((^|.*?\.)stoplight\.(json|y(a?)ml)$)/, /^lint.yml$/],
};

export const publishFileFilter = _.pick(defaultFileFilter, ['documentation', 'modeling']);

export const filterMap = {
  modeling: {
    type: 'oas',
    name: 'API Reference',
    label: 'NAME',
    placeholder: 'for example, "Payments Service"',
    icon: faPuzzlePiece,
    iconName: 'puzzle',
    subTypes: [
      { type: 'oas2', name: 'OpenAPI v2', extension: 'oas2.yml' },
      { type: 'oas3', name: 'OpenAPI v3', extension: 'oas3.yml' },
    ],
  },
  markdown: {
    type: 'markdown',
    name: 'Article',
    label: 'ARTICLE TITLE',
    placeholder: 'for example, "Getting Started"',
    extension: 'md',
    icon: faPencilAlt,
    iconName: 'pencil',
    requireName: true,
  },
  testing: {
    type: 'scenarios',
    label: 'NAME',
    placeholder: 'for example, "User Tests"',
    name: 'Test Suite',
    extension: 'scenarios.yml',
    icon: faFlask,
    iconName: 'flask',
  },
  html: {
    type: 'html',
    name: 'HTML Page',
    extension: 'html',
    icon: faHtml5,
    iconName: 'html5',
    requireName: true,
  },
  // css: { type: 'css', extension: 'css', icon: faCss3, requireName: true },
  documentation: {
    type: 'hub',
    name: 'Hub',
    extension: 'hub.yml',
    icon: faBook,
    iconName: 'book',
    deprecated: true,
  },
  servers: {
    type: 'prism',
    name: 'Prism Server',
    extension: 'prism.yml',
    icon: faServer,
    iconName: 'server',
    deprecated: true,
  },
  config: {
    type: 'config',
    extension: 'yml',
    noName: true,
    noAdd: true,
    noRemove: true,
  },
};

export const filterFiles = ({ files = [], fileFilter = defaultFileFilter }) => {
  const filtered = {};

  // filter the files
  _.forEach(files, file => {
    _.forEach(fileFilter, (filter, name) => {
      filtered[name] = filtered[name] || [];

      _.forEach(filter, matcher => {
        if (file.path.match(matcher)) {
          filtered[name].push(file);
        }
      });
    });
  });

  // sort the filtered files alphabetically
  _.forEach(filtered, (files, filter) => {
    filtered[filter] = _.sortBy(files, ['record.data.name', 'name']);
  });

  return filtered;
};

export const findFileType = ({
  filePath,
  contentType,
  contents,
  fileFilter = defaultFileFilter,
}) => {
  if (!filePath && !contentType && !contents) return;

  // remove hashes and query strings
  const cleanedFilePath = _.replace(filePath, /(\?|\#).*$/, '');

  let fileType = _.findKey(fileFilter, (filter, key) => {
    return _.find(filter, matcher => matcher.test(cleanedFilePath));
  });

  if (fileType) {
    return fileType;
  }

  if (contentType) {
    if (contentType === 'text/html') {
      return 'html';
    } else if (contentType === 'text/markdown') {
      return 'markdown';
    }
  }

  if (contents) {
    if (typeof contents !== 'object') {
      if (/\.json$/.test(cleanedFilePath)) {
        contents = safeParse(contents, contents);
      } else if (/\.y(a?)ml$/.test(cleanedFilePath)) {
        contents = safeLoad(contents, contents);
      }
    }

    if (typeof contents === 'object') {
      if (contents.swagger || contents.openapi) {
        return 'modeling';
      } else if (contents.scenarioVersion) {
        return 'testing';
      } else if (contents.pages) {
        return 'documentation';
      }
    }
  }

  return fileType;
};

export const findFileSubType = ({
  filePath,
  contentType,
  contents,
  fileFilter = defaultFileFilter,
}) => {
  if (!filePath && !contentType && !contents) return;

  // remove hashes and query strings
  const cleanedFilePath = _.replace(filePath, /(\?|\#).*$/, '');

  if (contents) {
    if (typeof contents !== 'object') {
      if (/\.json$/.test(cleanedFilePath)) {
        contents = safeParse(contents, contents);
      } else if (/\.y(a?)ml$/.test(cleanedFilePath)) {
        contents = safeLoad(contents, contents);
      }
    }

    if (typeof contents === 'object') {
      if (contents.swagger) {
        return 'oas2';
      } else if (contents.openapi) {
        return 'oas3';
      }
    }
  }

  return fileType;
};
