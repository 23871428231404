import _ from 'lodash';
import fuzzysearch from 'fuzzysearch';
import Fuse from 'fuse.js';

const getValue = props => {
  let v = props.value;

  if (props.rule.dataFactory) {
    v = props.rule.dataFactory(props);
  }

  return v;
};

const getSearchValue = props => {
  let v = props.value;

  if (props.rule.searchFactory) {
    v = props.rule.searchFactory(props);
  }

  return v;
};

const func = ({ spec, data, rules, filtered, parentPath = [], parentRulePath = [] }, opts) => {
  const { hoist, exclusive, search = {} } = opts;

  let anyMatched = false;

  _.forEach(data, (value, key) => {
    const currentPath = parentPath.concat([key]);

    for (const name in rules) {
      if (!rules.hasOwnProperty(name)) continue;
      const rule = rules[name];
      let matched = false;
      let childMatched = false;
      let shouldRecurse = false;

      let rulePath = [];
      if (hoist || rule.hoist || !parentRulePath.length) {
        rulePath = [name];
      } else {
        rulePath = parentRulePath.concat(['rules', name]);
      }

      if (rule.matcher) {
        matched = rule.matcher({ parentPath, currentPath, key, value, spec });
        _.set(filtered, rulePath, _.get(filtered, rulePath, { matched: [] }));
      }

      if (matched) {
        const data = getValue({ spec, rule, value, parentPath, currentPath, key });

        const matchedData = {
          path: currentPath,
          data,
        };

        if (search.query) {
          matchedData.searchable = getSearchValue({
            spec,
            rule,
            value,
            parentPath,
            currentPath,
            key,
          });

          matchedData.searchable = JSON.stringify(
            _.values(matchedData.searchable || matchedData.data || {})
          )
            .toLowerCase()
            .replace(/\W/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();

          matched = fuzzysearch(search.query.toLowerCase().trim(), matchedData.searchable);
        }

        if (matched) {
          _.get(filtered, rulePath).matched.push(matchedData);
        }
      }

      if (!shouldRecurse && rule.recursive) {
        shouldRecurse = _.isFunction(rule.recursive)
          ? rule.recursive({ spec, rule, value, parentPath, currentPath, key })
          : rule.recursive;
      }

      if ((shouldRecurse || rule.rules) && _.isObject(value)) {
        let nextRulePath = parentRulePath;
        let nextRules = {
          [name]: rule,
        };

        if (matched && !rule.circular) {
          nextRules = rule.rules;
          nextRulePath = nextRulePath.concat(name);
        }

        childMatched = func(
          {
            spec,
            data: value,
            rules: nextRules,
            filtered,
            parentPath: currentPath,
            parentRulePath: nextRulePath,
          },
          opts
        );
      }

      anyMatched = anyMatched || matched || childMatched;

      // break out if we are matching exclusively (stopping at the first rule that matches)
      if ((matched || childMatched) && (exclusive || rule.exclusive)) {
        break;
      }
    }
  });

  return anyMatched;
};

export const routeData = ({ data = {}, rules = {} }, opts = {}) => {
  const { search = {}, flatten, limit } = opts;
  let filtered = {};

  if (_.isEmpty(rules)) {
    return filtered;
  }

  func({ spec: data, data, rules, filtered }, opts);

  if (search.query) {
    _.forEach(filtered, (rule, name) => {
      const fuse = new Fuse(rule.matched || [], {
        keys: ['searchable'],
        threshold: 0.6,
        distance: 250,
        ...(search.options || {}),
      });

      filtered[name].matched = fuse.search(search.query);
    });
  }

  if (flatten) {
    // a place for our flattened results
    filtered.flattened = {
      matched: [],
    };

    // concat all the matched results into the flattened results
    _.forEach(filtered, (rule, name) => {
      if (name !== 'flattened') {
        filtered.flattened.matched = filtered.flattened.matched.concat(rule.matched);
      }
    });

    // remove the other results
    filtered = {
      flattened: filtered.flattened,
    };
  }

  if (limit) {
    _.forEach(filtered, (rule, name) => {
      filtered[name].matched = rule.matched.slice(0, limit);
    });
  }

  return filtered;
};
