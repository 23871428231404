import _ from 'lodash';
import ColorHash from 'color-hash';
import tinycolor from 'tinycolor2';

import shortid from '../shortid';

const colorHash = new ColorHash({ lightness: 0.5 });

const hardcoded = {
  get: '#4EB509',
  post: '#5E98CD',
  put: '#786B6D',
  patch: '#59484b',
  delete: '#c0392b',
  script: '#B33F77',
  model: '#B33F77',
};

const colorCache = {};

export const stringToColor = (str, options) => {
  let res = str;

  if (!res) {
    res = shortid();
  }

  res = res.toLowerCase();

  if (hardcoded[res]) {
    return hardcoded[res];
  }

  if (colorCache[res]) {
    return colorCache[res];
  }

  colorCache[res] = colorHash.hex(res);

  return colorCache[res];
};

export const rgbaString = (color, fallback) => {
  if (_.isEmpty(color)) return fallback;

  const { r, g, b, a } = color;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const checkIsWhite = color => {
  if (!color) return true;

  return tinycolor(color).getBrightness() >= 225;
};
