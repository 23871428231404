import _ from 'lodash';
import base32 from 'thirty-two';

const atob = (data = '') => {
  let buff = new Buffer(data, 'base64');
  return buff.toString('utf8');
};

export const encodeBase32 = ({ value = '', stripPadding = false, lower = false }) => {
  value = base32.encode(value).toString();

  if (stripPadding) {
    value = _.trimEnd(value, '=');
  }

  if (lower) {
    value = _.toLower(value);
  }

  return value;
};

export const decodeBase32 = ({ value = '' }) => {
  // add padding characters
  let padding = '';
  const toAdd = value.length % 8;

  if (toAdd === 2) {
    padding = '======';
  } else if (toAdd === 4) {
    padding = '====';
  } else if (toAdd === 5) {
    padding = '===';
  } else if (toAdd === 7) {
    padding = '==';
  }
  value += padding;

  return base32.decode(_.toUpper(value)).toString();
};

export const b64EncodeUnicode = (str = '') => {
  const encoded = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
    return String.fromCharCode(parseInt(p1, 16));
  });

  if (typeof bota !== 'undefined') {
    return btoa(encoded);
  }

  return Buffer.from(encoded).toString('base64');
};

export const b64DecodeUnicode = (str = '') => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};
