import _ from 'lodash';
import { isOnPrem, getExperimentalFeatures } from '../config';

const GUEST = ['view'];
const REPORTER = GUEST.concat(['create:project']);
const MEMBER = REPORTER.concat(['push:project']);
const ADMIN = MEMBER.concat([
  'manage:team',
  'manage:member',
  'create:team',
  'manage:collaboration',
  'remove:project',
  'update:project',
  'publish:project',
  'view:billing_usage',
]);
const OWNER = ADMIN.concat(['update', 'manage:billing']);

export const matrix = {
  GUEST,
  REPORTER,
  MEMBER,
  ADMIN,
  OWNER,
};

export const accessLevels = Object.freeze({
  OWNER: 50,
  ADMIN: 40,
  MEMBER: 30,
  REPORTER: 20,
  GUEST: 10,
});

export const accessRoles = Object.freeze({
  50: 'OWNER',
  40: 'ADMIN',
  30: 'MEMBER',
  20: 'REPORTER',
  10: 'GUEST',
});

export const accessRolesHuman = Object.freeze({
  50: 'OWNER',
  40: 'ADMIN',
  30: 'CONTRIBUTOR',
  20: 'CONTRIBUTOR', // treating reporter as a member in some cases (like orgs)
  10: 'READER',
});

export const entityMemberAccessLevels = Object.freeze({
  org: [accessLevels.OWNER, accessLevels.ADMIN, accessLevels.REPORTER, accessLevels.GUEST],
  team: [accessLevels.OWNER, accessLevels.ADMIN, accessLevels.MEMBER],
  project: [accessLevels.OWNER, accessLevels.ADMIN, accessLevels.MEMBER],
});

export const checkIsPublic = (entity = {}) => {
  return entity.visibility === 'public';
};

// find the role that is less than or equal to our access_level
export const getAccessRole = (level = 0, { human } = {}) => {
  return human ? accessRolesHuman[level] : accessRoles[level];
};

export const getHighestLevel = ({ entity, isPublic, explicit } = {}) => {
  const publicAccess = isPublic || checkIsPublic(entity);
  let level = 0;

  if (explicit) {
    level = Math.max(
      _.get(
        entity,
        'permissions.project_access.access_level',
        _.get(entity, 'resolved.access_level', _.get(entity, 'access_level', 0))
      )
    );
  } else {
    _.forOwn(entity, (value, key) => {
      let val = 0;

      if (key === 'access_level') {
        val = value;
      } else if (value && value.access_level) {
        val = value.access_level;
      } else if (_.isObject(value)) {
        val = getHighestLevel({ entity: value, isPublic: publicAccess });
      }

      if (val > level) {
        level = val;
      }
    });
  }

  return level;
};

export const getHighestRole = ({ entity, explicit, human } = {}) => {
  return getAccessRole(getHighestLevel({ entity, explicit }), { human });
};

export const canUser = options => {
  const { entity, action, user = {} } = options || {};

  if (!entity) {
    return false;
  }

  if (user.is_admin) {
    return true;
  }

  const role = getHighestRole({ entity });

  return _.includes(matrix[role], action);
};

export const hasRole = ({ entity, role } = {}) => {
  return getHighestRole({ entity }) === role;
};

export const hasLevel = ({ entity, level } = {}) => {
  return getHighestLevel({ entity }) === level;
};

export const filterByRoles = ({ entities = [], roles = [] } = {}) => {
  return _.filter(entities, entity => {
    return _.includes(roles, accessRoles[entity.access_level]);
  });
};

export const checkRole = ({ entity, role, exact = true, inclusive = false }) => {
  if (!entity) {
    return false;
  }

  if (exact) {
    const accessRole = _.get(accessRoles, entity.access_level);

    // Check if user has required role
    return accessRole && accessRole === role;
  }

  // Check if user has required role or above
  const accessLevel = _.get(accessLevels, role);

  if (inclusive) {
    return accessLevel && accessLevel <= entity.access_level;
  }

  return accessLevel && accessLevel < entity.access_level;
};

// experimental features will be disabled by default, and need to be explicity defined in config var to enable them app wide
// feature permission can be explicity overwritten at the namespace level as a custom attribute
export const isFeatureEnabled = ({ org = {}, user = {}, feature }) => {
  // disable explorer functionality when running on premises
  if (isOnPrem && feature.includes('explorer')) {
    return false
  }

  // features are enabled for stoplight admins all the time
  if (!_.includes(getExperimentalFeatures(), feature) || user.is_admin) {
    return true;
  }

  const userAccess = _.get(user.feature_flags, feature);
  const orgAccess = _.get(org.feature_flags, feature);

  // check for explicit user enable/disable of feature (acts as a sudo admin for this feature)
  if (!_.isNil(userAccess)) {
    return userAccess;
  }

  // check for explicit org enable/disable of feature
  if (!_.isNil(orgAccess)) {
    return _.isFinite(orgAccess) ? org.access_level >= orgAccess : orgAccess;
  }
};
