export const sdk = () => {
  if (typeof window !== 'undefined' && window.Bugsnag) {
    return window.Bugsnag;
  }
};

/**
 * opts: https://docs.bugsnag.com/platforms/browsers/js/reporting-handled-errors/#sending-errors
 */
export const notify = (e, opts = {}) => {
  const bs = sdk();

  if (bs) {
    bs.notify(e, opts);
  }
};

export const init = data => {
  const Bugsnag = sdk();

  if (Bugsnag) {
    if (data) {
      Bugsnag.user = data;
    }
  }
};

export const script = ({ apiKey, appVersion, releaseStage }, { htmlProps, basePath = '' } = {}) => {
  if (!apiKey) {
    return htmlProps ? {} : '';
  }

  const src = `${basePath}/js/bugsnag.min.js`;
  const script = `window.Bugsnag = bugsnag({
  apiKey: '${apiKey}',
  appVersion: '${appVersion}',
  releaseStage: '${releaseStage}',
  notifyReleaseStages: [ 'development', 'staging', 'production' ],
  metaData: { /* some metaData to attach to every report */ },
  user: {},
  beforeSend: function (report) {},
  autoBreadcrumbs: false
});`;

  if (htmlProps) {
    return {
      src,
      script,
    };
  }

  return `
    <script defer async src="${src}" onload="this.onload = undefined; ${script}"></script>
  `;
};
