import SAlert from 'react-s-alert';

// TODO: timeouts because of: https://github.com/juliancwirko/react-s-alert/issues/49#issuecomment-333866776
const _alert = {};

['success', 'warning', 'error', 'info', 'closeAll'].forEach(functionName => {
  _alert[functionName] = text => {
    setTimeout(() => {
      SAlert[functionName](text);
    }, 0);
  };
});

export const alert = _alert;
