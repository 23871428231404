import * as React from 'react';

const cn: any = require('classnames');

import { inputFormHandler } from './events';
import { ListScroller } from './ListScroller';
import { Popup } from './Popup';

export type IDropdownInputRenderInput = (
  attributes: {
    value: string;
    onChange: inputFormHandler;
    onFocus: inputFormHandler;
    onBlur: inputFormHandler;
    ref: (el: HTMLInputElement) => void;
  }
) => any;

export type IDropdownOnItemSelect = (item: IDropdownInputItem) => string;

export interface IDropdownInputItem {
  name: string;
}

export interface IDropdownInput {
  query: string;
  renderInput: IDropdownInputRenderInput;
  onItemSelect: IDropdownOnItemSelect;
  items: IDropdownInputItem[];
  emptyMsg?: string;
}

export interface IDropdownInputState {
  originalQuery: string;
  query: string;
  isFocused: boolean;
}

export class DropdownInput extends React.Component<IDropdownInput, IDropdownInputState> {
  private _input?: HTMLInputElement;
  private _rowHeight = 27;
  private _maxVisibleRows = 10;

  constructor(props: IDropdownInput) {
    super(props);

    this.state = {
      isFocused: false,
      originalQuery: this.props.query,
      query: this.props.query,
    };
  }

  public componentWillUpdate(nextProps: IDropdownInput, nextState: IDropdownInputState) {
    if (nextProps.query !== nextState.originalQuery) {
      nextState.originalQuery = nextProps.query;
      nextState.query = nextProps.query || '';
    }
  }

  public render() {
    const { renderInput } = this.props;

    return (
      <Popup
        posX="left"
        posY="bottom"
        hideDelay={0}
        show={this._isOpen}
        padding={0}
        offset={{
          top: -5,
        }}
        renderTrigger={attributes => {
          return (
            <div {...attributes} className="flex-1">
              {renderInput({
                value: this.state.query || '',
                onChange: this._onChange,
                onFocus: this._onFocus,
                onBlur: this._onBlur,
                ref: this._setRef,
              })}
            </div>
          );
        }}
        renderContent={attributes => {
          return (
            <div {...attributes} className="bg-grey-darkest shadow rounded">
              {this._renderItems()}
            </div>
          );
        }}
      />
    );
  }

  private get _isOpen() {
    return this.state.isFocused;
  }

  // private get _isEmpty() {
  //   return this.state.query.trim().length ? false : true;
  // }

  private _onChange: inputFormHandler = e => {
    const value = e.currentTarget.value;
    this.setState({ query: value });
  };

  private _onFocus: inputFormHandler = () => {
    this.setState({ isFocused: true });
  };

  private _onBlur: inputFormHandler = () => {
    this.setState({ isFocused: false });
  };

  private _setRef = (el: HTMLInputElement) => {
    this._input = el;
  };

  private _renderItems = () => {
    const { items = [] } = this.props;

    // only grow as much as needed to show the number of items, contraining to a max height
    // default to row height if we have no items
    const height =
      Math.min(items.length * this._rowHeight, this._maxVisibleRows * this._rowHeight) ||
      this._rowHeight;

    return (
      <div style={{ height }}>
        <ListScroller
          rowCount={items.length}
          rowHeight={this._rowHeight}
          noRowsRenderer={this._renderEmptyMsg}
          rowRenderer={this._renderItem}
        />
      </div>
    );
  };

  private _renderEmptyMsg = () => {
    const { emptyMsg = 'No results.' } = this.props;
    return (
      <div className="px-3 text-lighten-700 flex items-center" style={{ height: this._rowHeight }}>
        {emptyMsg}
      </div>
    );
  };

  private _renderItem = ({ key, index, style }: { key: string; index: number; style: any }) => {
    const { items = [] } = this.props;
    const item = items[index];

    return (
      <div
        key={key}
        style={style}
        className={cn('flex items-center px-3 text-lighten-700 hover:bg-lighten-50 cursor-pointer')}
        onMouseDown={() => {
          const newValue = this.props.onItemSelect(item);

          if (this._input) {
            // noop
          }

          this.setState({
            query: typeof newValue === 'string' ? newValue : this.state.query,
          });
        }}
      >
        {item.name}
      </div>
    );
  };
}
