import shortid from 'shortid';

export default ({ length, lowercase } = {}) => {
  let id = shortid.generate();

  if (length) {
    id = id.substring(0, length);
  }

  if (lowercase) {
    id = id.toLowerCase();
  }

  return id;
};
