import { types, getEnv } from 'mobx-state-tree';
import _ from 'lodash';

// Instances should extend this
export const BaseInstance = types
  .model({
    id: types.union(types.number, types.string),
  })
  .views(self => {
    return {};
  })
  .actions(self => {
    return {};
  });

/**
 * This provides utilities to help manage stores that require multiple instances.
 */
export const BaseManager = types
  .model({
    instances: types.optional(types.array(types.frozen()), []),
  })
  .views(self => {
    return {};
  })
  .actions(self => {
    // Models that extend this model should set this - the one above is just a minimum template.
    self.instanceModel = null;

    return {
      register(id, data, options) {
        if (!self.instanceModel) {
          console.error('Must set an instanceModel to use BaseManager.register!');
          return;
        }

        const store = self.getInstance(id);
        if (store) {
          return store;
        }

        const newInstance = self.instanceModel.create(
          Object.assign({ id }, data || {}),
          getEnv(self)
        );

        self.instances.push(newInstance);

        return newInstance;
      },

      getInstance(id) {
        return _.find(self.instances, { id });
      },
    };
  });
