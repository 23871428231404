import React from 'react';
import _ from 'lodash';
import { inject } from 'mobx-react';

import { URI } from '@core/uri';

import { stringifyQuery, parseQuery } from '../query';

import { Link as NavLink } from './NavLink';

// A wrapper around the stoplight/ui Link
const Link = props => {
  const { routerStore, to, href, ...linkProps } = props;

  let target = href || to;
  let pathname;
  let query;
  let hash;

  if (typeof target === 'object') {
    pathname = target.pathname;
    query = target.query;
    hash = target.hash;
  } else {
    const parts = _.split(target, '?');
    pathname = parts[0];

    const other = _.split(parts[1], '#');
    query = parseQuery(other[0] || '');
    hash = other[1] ? `#${other[1]}` : '';
  }

  // Used by the Hub-Builder to preserve __build_id
  if (!_.isEmpty(routerStore.globalQueryParams)) {
    query = Object.assign({}, query, routerStore.globalQueryParams);
  }

  query = stringifyQuery(query);

  let url = pathname;

  // Make sure theres no trailing ?
  if (query) {
    url += `?${query}`;
  }

  if (hash) {
    url += hash;
  }

  return <NavLink {...linkProps} uri={URI.parse(url)} />;
};

export default inject('routerStore')(Link);
