import _ from 'lodash';

import { replaceVariables } from '@platform/utils/variables';
import { cleanSlashes } from '@platform/utils/url';

import * as sidebar from './sidebar/viewer';
export const sidebarUtils = sidebar;

// Notes on this in the utils/index file
export const blockConfig = {
  text: {
    padded: true,

    solo: {
      padded: true,
    },

    nested: {
      padded: true,
    },
  },

  code: {
    padded: true,
    standalone: true,
    outlined: true,

    solo: {
      padded: false,
      outlined: false,
    },

    nested: {
      padded: true,
      outlined: false,
      canvas: true,
    },
  },

  tabs: {
    padded: true,
    standalone: true,

    solo: {
      padded: false,
      outlined: false,
    },
  },

  jsonSchema: {
    padded: true,
    standalone: true,
    outlined: true,

    solo: {
      padded: false,
      outlined: false,
    },

    nested: {
      padded: true,
      outlined: false,
    },
  },

  http: {
    padded: true,
    standalone: true,
    paddedInner: true,
    canvas: true,

    solo: {
      padded: false,
      canvas: false,
    },
  },

  image: {
    padded: true,
    standalone: true,

    nested: {
      padded: true,
    },
  },

  callout: {
    padded: true,
    standalone: true,
    paddedInner: true,

    nested: {
      padded: true,
    },
  },

  html: {
    padded: true,

    solo: {
      padded: false,
    },

    nested: {
      padded: true,
    },
  },

  container: {
    padded: true,
    outlined: true,
  },

  hero: {},
  barList: {},
  cardList: {},
  operation: {},
  ref: {},

  accordion: {
    padded: true,
    standalone: true,
    outlined: true,

    solo: {
      padded: false,
      outlined: false,
    },
  },
};

/**
 * Iterate recursively through subpage tree to build up a path to the first routable subpage with
 * blocks.
 */
export const findFirstSubpagePath = ({ subpages = [], basePath = '', pathTree = '' }) => {
  let path = '';

  _.forOwn(subpages, subpage => {
    path = `${_.get(subpage, 'route.path', '')}`;

    if (path && !_.isEmpty(_.get(subpage, 'data.blocks'))) {
      path = `${pathTree}${path}`;
    } else if (!_.isEmpty(_.get(subpage, 'data.children'))) {
      path = `${findFirstSubpagePath({
        subpages: subpage.data.children,
        pathTree: `${pathTree}${path}`,
      })}`;
    }

    if (path) {
      return false;
    }
  });

  return `${basePath}${path}`.replace('//', '/');
};

export const findPageByPath = props => {
  const { pages = {}, path = '/' } = props;

  const collectedData = {
    data: undefined, // the found page data
    treePath: [], // path to page in the tree structure we use for things like sidebar (n/a here)
    parsedPath: [], // path to page in parsed property
  };

  const pathParts = _.split(path, '/');

  let pagePath = _.findKey(pages, (page, pathPath) => {
    return `/${pathParts[1]}` === pathPath;
  });

  // if we couldn't find a specific page but have a root route, use it
  if (!pagePath && pages['/']) {
    pagePath = '/';
  }

  const page = pages[pagePath];

  if (page) {
    collectedData.data = {
      path: pagePath,
      ...page,
    };
    collectedData.parsedPath = ['pages', pagePath];
  }

  return collectedData;
};

// Assumes each subpage only has one part, ie /foo/bar is two subpages, with bar nested inside of foo
// not /foo/bar being one subpage with the path "/foo/bar"
export const findSubpageByPath = props => {
  const {
    subpages = [],
    pathParts = [],
    collectedData = {
      searchedPath: props.pathParts, // helpful to know if we had something to look for but didn't find it (page not found screen)
      data: undefined, // the found subpage data
      treePath: [], // path to subpage in the tree structure we use for things like sidebar
      parsedPath: [],
    },
  } = props;

  const path = pathParts[0];

  let foundSubpageIndex;
  let foundSubpage;

  for (const index in subpages) {
    if (foundSubpage) break;

    const subpage = subpages[index];

    if (_.get(subpage, 'route.path') === `/${path}`) {
      foundSubpageIndex = index;
      foundSubpage = subpage;
    }
  }

  if (foundSubpage) {
    collectedData.data = {
      ...foundSubpage,
      path: _.get(foundSubpage, 'route.path'),
    };
    collectedData.treePath.push(foundSubpageIndex);

    collectedData.parsedPath = collectedData.parsedPath.concat(['data', 'children']);
    collectedData.parsedPath.push(foundSubpageIndex);
  }

  // we're not done searching
  if (pathParts[1]) {
    if (_.isEmpty(collectedData, 'data.data.children')) {
      // doesn't exist
      collectedData.data = undefined;
      return collectedData;
    }

    // recurse through children, passing the path parts minus one part
    return findSubpageByPath({
      subpages: _.get(collectedData, 'data.data.children'),
      pathParts: pathParts.slice(1),
      collectedData,
    });
  }

  return collectedData;
};

// [pages, /api-reference, data, children, 0]
// returns compiled route (as a string), like /api-reference/intro
export const childToRoute = ({ jsonPath, spec }) => {
  let route = jsonPath[1];

  _.forEach(jsonPath, (value, index) => {
    if (_.isFinite(value)) {
      const path = _.slice(jsonPath, 0, index + 1).concat(['route', 'path']);
      const childRoute = _.get(spec, path);
      route += childRoute;
    }
  });

  return route;
};

export const replaceBlockVariables = ({ block, env }) => {
  if (_.isEmpty(block)) return {};
  if (_.isEmpty(env)) return block;

  let data = block.data || {};

  if (data && !_.isEmpty(data.children)) {
    const children = _.map(_.compact(data.children), child => {
      const blocks = _.map(_.compact(child.blocks), childBlock =>
        replaceBlockVariables({ block: childBlock, env })
      );

      return {
        ...replaceVariables(child, { env }),
        blocks,
      };
    });

    data = {
      ...replaceVariables(data, { env }),
      children,
    };
  } else if (block.type !== 'http') {
    data = replaceVariables(data, { env });
  }

  return {
    ...block,
    data,
  };
};

export const buildViewPath = ({ path = '', version = '', basePath = '' }) => {
  let viewPath = cleanSlashes(`/${path}`);

  const containsBase = cleanSlashes(
    `${basePath ? `/${basePath}/` : ''}${version ? `/${version}/` : ''}.*`
  );

  const re = new RegExp(`^${containsBase}`);
  if (!re.test(viewPath)) {
    return cleanSlashes(`/${basePath}/${version}/${viewPath}`);
  }

  return viewPath;
};
