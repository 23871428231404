import { INumberedObject } from '@core/types';

export const enumToOptions = (target: any) => {
  const options: INumberedObject<any> = {};

  for (const k in target) {
    if (!target[k]) continue;
    const v = target[k];
    if (isFinite(parseInt(k))) {
      options[k] = v;
    }
  }

  return options;
};

export enum themes {
  dark = 1,
  light,
}

export enum colors {
  primary = 1,
  secondary,
  accent,
  active,
  negative,
  warning,
  positive,
  muted,
  'muted-light',
  darken,
  lighten,
  black,
  white,
  grey,
  red,
  orange,
  yellow,
  green,
  teal,
  blue,
  indigo,
  purple,
  pink,
}
export const defaultColor = colors.grey;

export enum sizes {
  xs = 1,
  sm,
  md,
  lg,
  xl,
}
export const defaultSize = sizes.md;

export enum posX {
  left = 1,
  right,
}
export enum posY {
  top = 1,
  bottom,
}
export enum pos {
  posX,
  posY,
}
