import React from 'react';
import cn from 'classnames';
import { Popup, Icon } from 'semantic-ui-react';

const FormInputTip = props => {
  const { className, tip } = props;

  if (!tip || typeof tip !== 'string') {
    return null;
  }

  return (
    <div className={cn('FormInputTip', className)}>
      <Popup
        trigger={<Icon name="question" size="small" />}
        content={tip}
        size="small"
        wide="very"
        hoverable
      />
    </div>
  );
};

export default FormInputTip;
