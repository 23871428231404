import _ from 'lodash';
import { types, getEnv } from 'mobx-state-tree';
import { getConfigVar } from '@platform/utils/config';
import { alert } from '@platform/utils/alert';

import { BaseStore } from './_base';

export const create = ({ data = {}, env, options = {} }) => {
  const Base = types
    .model({
      bugsnagKey: '',
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      return {
        afterCreate() {
          self.bugsnagKey = getConfigVar('BUGSNAG_KEY');

          const rootStore = getEnv(self).rootStore;

          if (rootStore.isClient) {
            if (rootStore.releaseStage !== 'production') {
              window.addEventListener('unhandledrejection', event => {
                alert.error('Yo, got some sort of unhandled rejection here! Check the console.');
              });
            }
          }

          if (typeof Bugsnag !== 'undefined') {
            Bugsnag.beforeNotify = (payload, metaData) => {
              metaData.context = rootStore.isClient ? 'client' : 'server';

              // NOTE: cannot store more data than the below https://github.com/bugsnag/bugsnag-js/issues/245
              metaData.user = _.pick(_.get(rootStore, 'stores.userService.authorizedUser'), [
                'id',
                'username',
              ]);

              return true;
            };
          }
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      Base
    )
    .named('ErrorStore');

  return Service.create(Object.assign({}, data), env);
};
