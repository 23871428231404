import * as React from 'react';

const cn: any = require('classnames');

import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';

import { borderClassName, iconClassName, paddingClassName, radiusClassName } from './classNames';
import { colors, defaultColor, defaultSize, posX, sizes, themes } from './enums';
import { divClickHandler, inputClickHandler, inputFormHandler } from './events';
import { Icon } from './Icon';

export interface ITextFieldProps {
  value: string;
  onChange: inputFormHandler;
  onEnter?: () => void;
  onEscape?: () => void;
  placeholder?: string;
  type?: string;
  label?: any;
  error?: string;
  className?: string;
  style?: object;
  inputClassName?: string;
  theme?: themes;
  color?: colors;
  size?: sizes;
  loading?: boolean;
  disabled?: boolean;
  solid?: boolean;
  fluid?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  outlined?: boolean;
  icon?: any;
  focus?: boolean;
  iconPos?: posX;
  onClick?: inputClickHandler;
  onFocus?: inputFormHandler;
  onBlur?: inputFormHandler;
  onClickIcon?: divClickHandler;
}

export class TextField extends React.Component<ITextFieldProps> {
  private input?: HTMLInputElement | null;

  public componentDidMount() {
    const { focus } = this.props;

    if (this.input) {
      if (focus) {
        this.input.focus();
        this.input.select();
      }
    }
  }

  public render() {
    const {
      value = '',
      placeholder,
      type = 'text',
      label,
      error,
      className,
      style,
      inputClassName,
      theme,
      color = defaultColor,
      size = defaultSize,
      loading,
      disabled,
      solid,
      fluid,
      rounded,
      shadow,
      outlined = true,
      icon,
      iconPos = posX.right,
      onClickIcon,
    } = this.props;

    const colorVal = colors[color];
    const sizeVal = sizes[size];
    const enabled = !disabled && !loading;
    const hasBorder = !solid;

    const paddingClasses = paddingClassName({
      size,
      iconClickable: onClickIcon ? true : false,
      iconPos: icon ? iconPos : undefined,
    });

    const borderClasses = outlined
      ? borderClassName({
          theme,
          color,
          size,
          enabled,
          hasBorder,
          error,
        })
      : '';

    const radiusClasses = radiusClassName({ rounded });

    let bgClasses = {};
    if (theme && theme === themes.dark) {
      bgClasses = {
        [`bg-transparent`]: hasBorder,
        [`bg-darken-200`]: !hasBorder,
        [`focus:bg-lighten-50`]: true,
        ['opacity-75']: !enabled,
      };
    } else if (theme && theme === themes.light) {
      bgClasses = {
        [`bg-transparent`]: hasBorder,
        [`bg-darken-50`]: !hasBorder,
        [`focus:bg-darken-100`]: !hasBorder,
        [`focus:bg-darken-50`]: hasBorder,
        ['opacity-75']: !enabled,
      };
    } else {
      bgClasses = {
        [`focus:bg-${colorVal}-lightest`]: enabled && hasBorder,
        [`bg-${colorVal}-lightest`]: !hasBorder,
        [`focus:bg-${colorVal}-lighter`]: enabled && !hasBorder,
        ['opacity-75']: !enabled,
      };
    }

    let textClasses = {};
    if (theme && theme === themes.dark) {
      textClasses = {
        [`text-lighten-600`]: true,
        [`focus:text-white`]: true,
      };
    } else if (theme && theme === themes.light) {
      // TODO
    } else {
      textClasses = {
        [`text-${colorVal}-darker`]: true,
        [`hover:text-${colorVal}-darkest`]: enabled && !hasBorder,
        [`focus:text-${colorVal}-darkest`]: enabled && !hasBorder,
      };
    }
    textClasses[`text-${sizeVal}`] = true;
    textClasses['text-red'] = error;

    let iconElem;
    if (icon) {
      iconElem = (
        <div
          className={cn(
            bgClasses,
            textClasses,
            iconClassName({
              color,
              size,
              iconPos,
              enabled,
              hasBorder,
              error,
              clickable: onClickIcon ? true : false,
            })
          )}
          onClick={enabled ? this.onIconClick : undefined}
        >
          <Icon icon={loading ? faSpinner : icon} pulse={loading} />
        </div>
      );
    }

    return (
      <div
        className={cn(className, 'TextField flex flex-col', {
          'w-full': fluid,
          'theme-light': theme && theme === themes.light,
          'theme-dark': theme && theme === themes.dark,
        })}
      >
        {label && (
          <label
            className={cn(
              'block tracking-wide ',
              `text-${colorVal}-dark`,
              `text-${sizes[size - 1] || sizes[0]}`,
              {
                'font-bold': size > sizes.xs,
                'mb-1/2': size < sizes.md,
                'mb-1': size === sizes.md,
                'mb-2': size > sizes.md,
                'ml-1/2': size > sizes.md,
              }
            )}
            onClick={enabled ? this.onLabelClick : undefined}
          >
            {label}
          </label>
        )}

        <div
          className={cn('flex', `h-${sizes[size]}`, radiusClasses, borderClasses, {
            // shadows
            shadow,
          })}
        >
          {iconPos === posX.left && iconElem}

          <input
            spellCheck={false}
            ref={this.setInputRef}
            type={type}
            value={value}
            placeholder={placeholder}
            readOnly={!enabled}
            style={style}
            className={cn(
              inputClassName,
              paddingClasses,
              bgClasses,
              textClasses,
              'appearance-none',
              {
                // cursor
                'cursor-not-allowed': disabled,
                'cursor-wait': loading,

                // structure
                'w-full': fluid,
              }
            )}
            onKeyPress={this.onKeyPress}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            onFocus={enabled ? this.onFocus : undefined}
            onBlur={enabled ? this.onBlur : undefined}
            onClick={enabled ? this.onInputClick : undefined}
          />

          {iconPos === posX.right && iconElem}
        </div>
      </div>
    );
  }

  private onIconClick = (e: any) => {
    if (this.props.onClickIcon) {
      this.props.onClickIcon(e);
    } else if (this.input) {
      this.input.focus();
    }
  };

  private onLabelClick = () => {
    if (this.input) this.input.focus();
  };

  private setInputRef = (node: any) => {
    this.input = node;
  };

  private onChange = (e: any) => {
    this.props.onChange(e, {
      value: e.target.value,
    });
  };

  private onKeyPress = (e: any) => {
    if (this.props.onEnter && e.key === 'Enter') {
      return this.props.onEnter();
    }
  };

  private onKeyDown = (e: any) => {
    if (this.props.onEscape && e.keyCode === 27) {
      return this.props.onEscape();
    }
  };

  private onInputClick = (e: any) => {
    if (!this.props.onClick) return;

    this.props.onClick(e, {
      value: this.props.value,
    });
  };

  private onBlur = (e: any) => {
    if (!this.props.onBlur) return;

    this.props.onBlur(e, {
      value: this.props.value,
    });
  };

  private onFocus = (e: any) => {
    if (!this.props.onFocus) return;

    this.props.onFocus(e, {
      value: this.props.value,
    });
  };
}
