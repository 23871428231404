import _ from 'lodash';

import { safeParse } from '../json';

export const emptySchema = `{
    "type": "object"
}`;

export const emptyJsonSchema = JSON.parse(emptySchema);

export const isSchemaEmpty = schema => {
  if (_.isEmpty(schema)) {
    return true;
  }

  let parsed = schema;
  if (typeof parsed === 'string') {
    try {
      parsed = JSON.parse(parsed);
    } catch (e) {
      return true;
    }
  }

  if (
    !parsed ||
    !Object.keys(parsed).length ||
    (parsed.properties && !Object.keys(parsed.properties).length)
  ) {
    return true;
  }

  if (parsed.allOf || parsed.oneOf || parsed.anyOf || parsed.patternProperties) {
    return false;
  }

  if (parsed.type === 'object' && _.isEmpty(parsed.properties)) {
    return true;
  }

  if (parsed.type === 'array' && _.isEmpty(parsed.items)) {
    return true;
  }

  return false;
};

export const isValidJSON = target => {
  try {
    if (typeof target === 'object') {
      target = JSON.stringify(target);
    }
    JSON.parse(target);
    return true;
  } catch (e) {
    return false;
  }
};

// MM TODO: this is pretty meh... what if it's {type: "string"}, etc? Check for specific
// allowed combinations of property/values (type: string|number|...)
export const isJsonSchema = target => {
  const keys = _.keys(target);
  return _.intersection(keys, [
    'allOf',
    'oneOf',
    'anyOf',
    'properties',
    'items',
    'patternProperties',
  ]).length > 0
    ? true
    : false;
};

export const buildExampleFromSchema = (s, schemas) => {
  let schema = safeParse(s);
  if (typeof schemas !== 'undefined') {
    schema = dereferenceSchema(schema, schemas, true);
  }

  const schemaType = Array.isArray(schema.type) ? schema.type : [schema.type];
  const { properties } = schema;
  const values = {};

  if (properties) {
    for (const k in properties) {
      if (!Object.prototype.hasOwnProperty.call(properties, k)) {
        continue;
      }

      const prop = properties[k];
      if (!prop.type) {
        continue;
      }

      const propType = Array.isArray(prop.type) ? prop.type : [prop.type];
      if (prop.hasOwnProperty('default')) {
        values[k] = prop.default;
        if (propType.indexOf('string') === -1) {
          try {
            values[k] = JSON.parse(prop.default);
          } catch (e) {}
        }
      } else if (prop.enum) {
        values[k] = _.first(prop.enum);
      }

      if (typeof values[k] === 'undefined') {
        values[k] = buildExampleFromSchema(prop);
      }
    }

    return values;
  } else if (schema.items) {
    return [buildExampleFromSchema(schema.items)];
  } else if (schema.allOf) {
    for (const item of schema.allOf) {
      _.merge(values, buildExampleFromSchema(item));
    }
    return values;
  } else if (schema.oneOf) {
    return buildExampleFromSchema(schema.oneOf[0]);
  } else if (schema.anyOf) {
    return buildExampleFromSchema(schema.anyOf[0]);
  } else if (schemaType.indexOf('object') !== -1) {
    return {};
  }

  let finalProp;
  const propType = _.first(_.without(schemaType, 'null'));
  switch (propType) {
    case 'number':
    case 'integer':
      finalProp = 123;
      break;
    case 'boolean':
      finalProp = true;
      break;
    case 'string':
      finalProp = 'string';
      if (schema.format) {
        finalProp = `${finalProp} (${schema.format})`;
      }

      break;
    default:
      finalProp = schema.type;
      break;
  }

  return finalProp;
};

const _dereferenceSchema = (options = {}) => {
  let {
    target = {},
    schemas = {},
    previousRefs = [],
    refCache = {},
    allOfParent,
    combinerParent,
    hideInheritedFrom,
    isRoot,
  } = options;

  let schema = target;
  let properties;
  let isCombiner;
  let isAllOf;

  if (_.isEmpty(schemas)) {
    return schema;
  }

  if (_.get(schema, '$ref')) {
    schema = _dereferenceSchemaRef({
      target: schema,
      schemas,
      ref: target.$ref,
      previousRefs,
      refCache,
      allOfParent,
      combinerParent,
      hideInheritedFrom,
      isRoot,
    });
  } else if (_.get(schema, 'properties')) {
    properties = schema.properties;
  } else if (_.get(schema, 'items')) {
    if (schema.items.$ref) {
      schema.items = _dereferenceSchemaRef({
        target: schema.items,
        schemas,
        ref: schema.items.$ref,
        previousRefs,
        refCache,
        allOfParent,
        combinerParent,
        hideInheritedFrom,
      });
    } else if (schema.items.properties) {
      properties = schema.items.properties;
    } else if (schema.items.allOf) {
      properties = schema.items.allOf;
      isCombiner = true;
    } else if (schema.items.oneOf) {
      properties = schema.items.oneOf;
      isCombiner = true;
    } else if (schema.items.anyOf) {
      properties = schema.items.anyOf;
      isCombiner = true;
    }
  } else if (_.get(schema, 'allOf')) {
    properties = schema.allOf;
    isCombiner = true;
    isAllOf = true;
  } else if (_.get(schema, 'oneOf')) {
    properties = schema.oneOf;
    isCombiner = true;
  } else if (_.get(schema, 'anyOf')) {
    properties = schema.anyOf;
    isCombiner = true;
  } else if (_.get(schema, 'additionalProperties')) {
    properties = schema.additionalProperties;
  } else if (_.get(schema, 'patternProperties')) {
    properties = schema.patternProperties;
  } else if (_.get(schema, 'dependencies')) {
    properties = schema.dependencies;
  }

  if (properties) {
    for (const k in properties) {
      if (properties.hasOwnProperty(k)) {
        properties[k] = _dereferenceSchema({
          target: properties[k],
          schemas,
          previousRefs,
          refCache,
          allOfParent: isAllOf,
          combinerParent: isCombiner,
          hideInheritedFrom,
          isRoot: isRoot && isAllOf,
        });
      }
    }
  }

  return schema;
};

const _dereferenceSchemaRef = (options = {}) => {
  const {
    target,
    schemas,
    ref,
    previousRefs = [],
    refCache = {},
    allOfParent,
    combinerParent,
    hideInheritedFrom,
    isRoot,
  } = options;

  if (refCache[ref]) {
    return refCache[ref].resolved;
  }

  if (previousRefs.indexOf(ref) !== -1) {
    const resolved = {
      type: '@circular',
    };

    if (!hideInheritedFrom) {
      resolved.__inheritedFrom = { name: ref, ref };
    }

    return resolved;
  }

  const newRefs = previousRefs.concat(ref);

  // defensive clone to protect against mutations :(
  const refPath = ref.replace('#/', '').split('/');
  const schema = _.cloneDeep(_.get(schemas, refPath.concat('schema')) || _.get(schemas, refPath));

  if (schema) {
    let propsInherited = false;
    let isAllOf = allOfParent;

    if (schema.properties) {
      if (combinerParent) {
        propsInherited = 'properties';
      } else if (!hideInheritedFrom) {
        schema.__inheritedFrom = { name: ref, ref };
      }
    } else if (schema.items) {
      if (combinerParent) {
        propsInherited = 'items';
      } else if (!hideInheritedFrom) {
        schema.__inheritedFrom = { name: ref, ref };
      }
    } else if (schema.allOf) {
      propsInherited = 'allOf';
      isAllOf = true;
    } else if (schema.oneOf) {
      propsInherited = 'oneOf';
      isAllOf = false;
    } else if (schema.anyOf) {
      propsInherited = 'anyOf';
      isAllOf = false;
    }

    if (propsInherited) {
      const iterFunc = _.isArray(schema[propsInherited]) ? _.map : _.mapValues;

      schema[propsInherited] = iterFunc(schema[propsInherited], item => {
        if (item.properties) {
          item.properties = _.mapValues(item.properties, val => {
            const obj = { ...val };

            // Don't show hideInheritedFrom for combiners anymore.
            if (!isAllOf && !hideInheritedFrom) {
              obj.__inheritedFrom = { name: ref, ref };
            }

            return obj;
          });

          return item;
        }

        const obj = { ...item };

        // Don't show hideInheritedFrom for combiners anymore.
        if (!isAllOf && !hideInheritedFrom) {
          obj.__inheritedFrom = { name: ref, ref };
        }

        return obj;
      });
    }

    const resolved = _dereferenceSchema({
      target: schema,
      schemas,
      previousRefs: newRefs,
      refCache,
      hideInheritedFrom,
    });

    if (!isRoot && !hideInheritedFrom) {
      resolved.__inheritedFrom = { name: ref, ref };
    }

    refCache[ref] = {
      depth: previousRefs.length,
      resolved,
    };

    return resolved;
  }

  refCache[ref] = {
    depth: previousRefs.length,
    target,
  };

  return target;
};

/**
 * Replace all $ref's with their respective JSON Schema objects
 * @param  {Object} target             - Schema to dereference
 * @param  {Object} schemas            - Schemas that the target may reference. For example a Swagger or {definitions: {}}. This function will use _.get and .split('/') to find the reference path. #/responses/foo will be in {responses: {foo: {}}}
 * @param  {Boolean} hideInheritedFrom - Don't add the __inheritedFrom property to the dereferenced schema
 * @return {Object}                    - An object with no $refs
 */
export const dereferenceSchema = (target, schemas, hideInheritedFrom) => {
  if (!target) {
    return {};
  }

  const schema = safeParse(_.cloneDeep(target));

  return _dereferenceSchema({ target: schema, schemas, hideInheritedFrom, isRoot: true });
};

// If there are two schemas with the same namespace, the more specific one wins
// By more specific, we mean a schema belonging to an environment > schema belonging to a workspace
export const uniqueByNamespace = schemas => {
  const newSchemas = {};
  for (const s of schemas) {
    if (newSchemas[s.namespace]) {
      if (s.environment) {
        newSchemas[s.namespace] = s;
      }
    } else {
      newSchemas[s.namespace] = s;
    }
  }

  return _.values(newSchemas);
};

export const findPropertyByRef = ({ schema = {}, refPath = [], namespacePath = [] } = {}) => {
  let sParsed = schema.definition;
  if (typeof sParsed === 'string') {
    try {
      sParsed = JSON.parse(schema.definition);
    } catch (e) {
      return [
        {
          type: 'error',
          message: `Error parsing schema definition with namespace ${schema.namespace}. ${e}`,
        },
      ];
    }
  }

  let currentSchema = sParsed;
  for (const p of refPath) {
    if (p === '' || p === namespacePath[1]) {
      continue;
    }
    const pathParts = p.split('/');
    for (const part of pathParts) {
      if (!currentSchema) {
        break;
      }
      if (currentSchema.type === 'object') {
        currentSchema = currentSchema.properties;
      }
      currentSchema = currentSchema[part];
    }
  }

  if (currentSchema) {
    return true;
  }

  return false;
};

export const findSchemaByRef = ({ schemas = [], ref = '' } = {}) => {
  for (const s of schemas) {
    const namespace = s.namespace.split('#/');
    const refPath = ref.split('#/');
    if (namespace.length > 0 && refPath.length > 0 && refPath[1] === namespace[1]) {
      // Find the property
      let found = true;
      if (refPath.length > 1) {
        found = findPropertyByRef({ schema: s, refPath, namespacePath: namespace });
      }

      if (found) {
        return true;
      }
    }
  }

  return false;
};

export const publicJSON = resource => {
  return _.omit(resource, [
    'skipCompliance',
    'createdAt',
    'updatedAt',
    'owner',
    'workspace',
    'project',
    'environment',
    'pendingChanges',
    'committedAt',
    'commits',
    'compliance',
    'groupId',
    'summary',
    'discovered',
    'mock',
    'operationId',
  ]);
};

export const resolveSchema = ({ schema, swagger } = {}) => {
  if (_.isEmpty(swagger)) {
    return schema;
  }

  const resolvedSchema = _.cloneDeep(schema);
  resolvedSchema.definition = dereferenceSchema(resolvedSchema.definition, swagger);
  return resolvedSchema;
};

/**
 * Marks all properties as required for objects and array child objects for
 * passed schema.
 * @param {object} schema - schema object.
 * @param {boolean} [mutate=false] - pass true if you want to mutate source schema.
 * @return {object} - modified schema.
 */
export const markFieldsRequired = (schema, mutate = false) => {
  const resolvedSchema = mutate ? schema : _.cloneDeep(schema);
  const props =
    _.get(resolvedSchema, ['items', 'properties']) || _.get(resolvedSchema, ['properties']);

  if (props) {
    _.forOwn(props, (value, key) => {
      if (_.isObject(value)) {
        value = markFieldsRequired(value, true);
      }

      if (resolvedSchema.required && !resolvedSchema.required.includes(key)) {
        resolvedSchema.required.push(key);
      } else {
        resolvedSchema.required = [key];
      }
    });
  }

  return resolvedSchema;
};

export const schemaMerger = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.uniq(objValue.concat(srcValue));
  }
};

/**
 * Merge keys in all schemas, error if keys are incompatible, create of union of "required" values.
 * @param {array} jsonSchemas - schemas to merge.
 * @return {object}
 */
export const mergeSchemas = jsonSchemas => {
  if (!_.isArray(jsonSchemas) || jsonSchemas.length < 1) {
    throw new Error('Must merge at least 1 JSON schema.');
  }

  return _.mergeWith({}, ...jsonSchemas, (mergedValue, newValue, key) => {
    if (_.isNil(mergedValue)) {
      return;
    }

    if (key === 'required') {
      return _.uniq(mergedValue.concat(newValue));
    }

    // Force override of existing properties. Remove this to restore original ability to merge json schemas
    if (key === 'properties') {
      for (const field in newValue) {
        if (newValue.hasOwnProperty(field)) {
          if (mergedValue.hasOwnProperty(field)) {
            mergedValue[field] = newValue[field];
          }
        }
      }
    }

    if (_.isPlainObject(mergedValue)) {
      if (!_.isPlainObject(newValue)) {
        throw new Error(`Failed to merge schemas because "${key}" has different values.`);
      }

      return;
    }

    if (!_.isEqual(mergedValue, newValue)) {
      throw new Error(
        `Failed to merge schemas because "${key}" has different values: ${JSON.stringify(
          mergedValue
        )} and ${JSON.stringify(newValue)}.`
      );
    }
  });
};

export const isSchemaViewerEmpty = schema => {
  const keys = _.keys(schema);
  const combinerTypes = ['allOf', 'oneOf', 'anyOf'];

  if (keys.length === 1 && _.includes(combinerTypes, keys[0])) {
    return _.isEmpty(_.get(schema, keys[0], []));
  }

  return false;
};
