import _ from 'lodash';
import aws4 from 'aws4';
import OAuth from 'oauth-1.0a';
import HmacSHA1 from 'crypto-js/hmac-sha1';
import EncBASE64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';

import { createURL } from '../url';
import { setQuery } from '../query';
import { safeStringify } from '../json';
import { replaceVariables } from '../variables';

const AUTH_TYPES = ['basic', 'digest', 'oauth1', 'oauth2', 'aws'];

export const authUrlOptions = [
  'https://accounts.google.com/o/oauth2/auth',
  'https://github.com/login/oauth/authorize',
  'https://bitbucket.org/api/1.0/oauth/authenticate',
  'https://www.dropbox.com/1/oauth2/authorize',
  'https://www.box.com/api/oauth2/authorize',
  'https://api.createsend.com/oauth?type=web_server',
  'https://foursquare.com/oauth2/authenticate',
  'https://launchpad.37signals.com/authorization/new?type=web_server',
  'https://secure.meetup.com/oauth2/authorize',
  'https://soundcloud.com/connect',
  'https://login.live.com/oauth20_authorize.srf',
  'https://login.uber.com/oauth/v2/authorize',
  'https://angel.co/api/oauth/authorize',
  'https://app.asana.com/-/oauth_authorize',
  'https://accounts.spotify.com/authorize',
];

export const accessUrlOptions = [
  'https://accounts.google.com/o/oauth2/token',
  'https://github.com/login/oauth/access_token',
  'https://bitbucket.org/api/1.0/oauth/access_token',
  'https://www.dropbox.com/1/oauth2/authorize',
  'https://www.box.com/api/oauth2/token',
  'https://api.createsend.com/oauth/token',
  'https://foursquare.com/oauth2/access_token',
  'https://launchpad.37signals.com/authorization/token',
  'https://secure.meetup.com/oauth2/access',
  'https://api.soundcloud.com/oauth2/token',
  'https://login.live.com/oauth20_token.srf',
  'https://login.uber.com/oauth/v2/token',
  'https://angel.co/api/oauth/token',
  'https://app.asana.com/-/oauth_authorize',
  'https://accounts.spotify.com/api/token',
];

export const updateInputWithAuth = (input, authNode, variables) => {
  const processedAuthNode = replaceVariables(authNode, {
    env: variables,
  });

  let newInput = _.merge({}, input);

  newInput.url = setQuery(newInput.url, input.query);

  const processedUrl = createURL(newInput.url);

  _.set(newInput, 'host', processedUrl.host);
  _.set(newInput, 'path', processedUrl.pathname + processedUrl.search);

  const patch = generateAuthPatch(processedAuthNode, newInput, {
    signAws(request, keys) {
      const newRequest = aws4.sign(request, keys);
      return newRequest.headers || {};
    },
  });

  newInput = _.merge({}, newInput, patch);

  _.unset(newInput, 'host');
  _.unset(newInput, 'path');

  return newInput;
};

export const generateBasicAuth = ({ username, password }, request, options = {}) => {
  const string = new Buffer([username, password].join(':')).toString('base64');

  return {
    headers: {
      Authorization: 'Basic ' + string,
    },
  };
};

const hashFunction = (method, encode, options = {}) => {
  return (baseString, key) => {
    let hash;

    switch (method) {
      case 'HMAC-SHA1':
        hash = HmacSHA1(baseString, key);
        break;
      case 'HMAC-SHA256':
        hash = HmacSHA256(baseString, key);
        break;
      default:
        return key;
    }

    if (encode) {
      return hash.toString(EncBASE64);
    }

    return hash.toString();
  };
};

export const generateOAuth1 = (data, request, options = {}) => {
  let patch = {};

  const signatureMethod = data.signatureMethod || 'HMAC-SHA1';

  const encode = data.encode;
  const oauth = OAuth({
    consumer: {
      key: data.consumerKey,
      secret: data.consumerSecret,
    },
    signature_method: signatureMethod,
    hash_function: hashFunction(signatureMethod, encode, options),
    version: data.version || '1.0',
    nonce_length: data.nonceLength || 32,
    parameter_seperator: data.parameterSeperator || ', ',
  });

  let token = null;
  if (data.token) {
    token = {
      key: data.token,
      secret: data.tokenSecret,
    };
  }

  const requestToAuthorize = {
    url: request.url,
    method: (request.method || 'get').toUpperCase(),
    data: request.body,
  };

  if (_.isEmpty(request.url)) {
    console.warn('editor/updateAuth parse url error', request.url);
  }

  const authPatch = oauth.authorize(requestToAuthorize, token);
  patch.authorization = {
    oauth1: {
      nonce: authPatch.oauth_nonce,
    },
  };

  if (data.useHeader) {
    // add to the header
    const headerPatch = oauth.toHeader(authPatch);
    patch = {
      headers: {
        Authorization: headerPatch.Authorization,
      },
    };
  } else {
    patch = {
      query: authPatch,
    };
  }

  return patch;
};

export const generateOAuth2 = (data, request, options = {}) => {
  let patch = {};

  if (data.useHeader) {
    _.unset(request, 'query.access_token');
    // add to the header
    patch = {
      headers: {
        Authorization: 'Bearer {$$.env.oauth_access_token}',
      },
    };
  } else {
    _.unset(request, 'headers.Authorization');
    // add to the query string
    patch = {
      query: {
        access_token: '{$$.env.oauth_access_token}',
      },
    };
  }

  return patch;
};

export const generateAws = (data, request, options = {}) => {
  let patch = {};

  if (!options.signAws) {
    console.warn('authorization/generateAws signAws function not supplied!');
    return patch;
  }

  const requestToAuthorize = {
    ...request,
    method: (request.method || 'get').toUpperCase(),
    body: safeStringify(request.body) || '',
    service: data.service,
    region: data.region,
  };

  const headers = options.signAws(requestToAuthorize, {
    secretAccessKey: data.secretKey,
    accessKeyId: data.accessKey,
    sessionToken: data.sessionToken,
  });

  // add to the header
  patch = {
    headers,
  };

  return patch;
};

export const generateAuthPatch = (authNode, request, options = {}) => {
  let patch = {};
  if (!authNode || !_.includes(AUTH_TYPES, authNode.type)) {
    return patch;
  }

  if (_.isEmpty(authNode)) {
    return patch;
  }

  // unset these so they re-create properly
  _.unset(request, ['headers', 'X-Amz-Date']);
  _.unset(request, ['headers', 'X-Amz-Security-Token']);

  switch (authNode.type) {
    case 'basic':
      patch = generateBasicAuth(authNode, request, options);
      break;
    case 'oauth1':
      patch = generateOAuth1(authNode, request, options);
      break;
    case 'oauth2':
      patch = generateOAuth2(authNode, request, options);
      break;
    case 'aws':
      patch = generateAws(authNode, request, options);
      break;
    default:
      console.warn(authNode.type, 'auth not implemented');
      break;
  }

  return patch;
};
