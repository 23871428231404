import _ from 'lodash';
import produce from 'immer';

import shortid from '@platform/utils/shortid';
import { registerLogger } from '@platform/utils/logging';

const log = registerLogger('@platform/stores', 'JsonPathCache');

const emptyObj = Object.freeze({});
const emptyCacheObj = Object.freeze({
  id: 'new',
  updated: 1,
  data: {},
});

const newCacheObj = ({ id, updated, data = {} } = {}) => {
  return {
    __cache: {
      id: id || shortid(),
      updated: updated || new Date().getTime(),
      data,
    },
  };
};

// register a new cache obj on the tree if not exists already
export const register = ({ data = {}, id, path = [], updated }) => {
  const exists = _.get(data, [...path, '__cache', 'id']);

  if (_.isEmpty(path)) {
    log.debug('cannot register an empty path', { data, id, path });
    return data;
  }

  if (!exists) {
    log.debug('register', path, data);
    return produce(data, draft => {
      _.set(draft, path, newCacheObj({ id, updated }));
    });
  }

  return data;
};

export const applyUpdate = props => {
  const { data, transformation, path = [], value, options = {}, id, updated } = props;

  const head = path[0];
  let updatedArray = false;
  let up = updated;
  if (!up) {
    up = new Date().getTime();
  }

  return produce(data, newData => {
    // if we have no more data, we are done
    if (data && data[head]) {
      // if this node has been registered (has __cache), trigger an update
      if (data[head].__cache) {
        _.set(newData, [head, '__cache', 'updated'], up);
      }

      // if path is not empty, we are not done recuring down through the tree
      if (!_.isEmpty(path)) {
        // if we're unsetting and are just before leaf, we should unset it
        if (transformation === 'unset' && _.size(path) === 1) {
          // handle a weird case if for some reason we are trying to unset an array
          if (_.isArray(newData)) {
            _.pullAt(newData, path);
            updatedArray = true;
          } else {
            _.unset(newData, path);
          }
        } else {
          _.set(
            newData,
            [head],
            applyUpdate({
              data: data[head],
              transformation,
              lastHead: head,
              path: path.slice(1),
              value,
              options,
              id,
              updated: up,
            })
          );
        }
      }
    }

    const { splice = {} } = options;
    let newObj = {};
    if (transformation && _.isEmpty(path)) {
      // we're at the leaf! time to handle special transformations, if any
      switch (transformation) {
        case 'pull':
          if (_.isArray(newData)) {
            _.pullAt(newData, value);
            updatedArray = true;
          } else {
            // handle a weird case where we are for some reason pull on an object
            _.unset(newData, value);
          }
          break;
        case 'push':
          // if other elements in this array are registered, this should be registered as well
          // otherwise we don't have to anything since push just adds to the array and does not
          // affect position of other elements
          if (_.get(newData, [0, '__cache'])) {
            newData.push(newCacheObj({ id, updated }));
          }

          break;
        case 'splice':
          if (!_.isNumber(splice.index)) {
            log.error(
              'applyUpdate: the splice transformation requires a numeric splice.index option to be passed in',
              {
                splice,
                newData,
              }
            );
            break;
          }

          if (!newData || !_.isArray(newData)) {
            log.error('applyUpdate: splice can only be used on arrays', {
              splice,
              newData,
            });

            return;
          }

          // if other elements in this array are registered, this should be registered as well
          if (_.get(newData, [0, '__cache'])) {
            newObj = newCacheObj({ id, updated });
          }

          newData.splice(splice.index, splice.howMany || 0, newObj);
          updatedArray = true;
          break;
      }
    }

    if (updatedArray) {
      _.forOwn(newData, (d, k) => {
        if (d && d.__cache) {
          _.set(newData, [k, '__cache', 'updated'], up);
        }
      });
    }
  });
};

export const triggerUpdate = props => {
  const { data, transformation, path = [], value, options, id, updated } = props;

  let updatedData = data;

  // special handling to unset the correct part of of the jsonPathCache tree
  if (transformation === 'unset' && _.isEmpty(path)) {
    return emptyObj;
  }

  // recurses through tree updating relevant nodes
  return applyUpdate({ data: updatedData, transformation, path, value, options, id, updated });
};

export default class JsonPathCache {
  data = {};

  register = (props = {}) => {
    const { cacheKey, id, path = [] } = props;
    this.data[cacheKey] = register({ data: this.data[cacheKey], id, path });
  };

  // pre-registers x number of children at the target basePath + path (this must resolve to an array)
  registerChildren = (props = {}) => {
    const { cacheKey, basePath = [], path = [], children } = props;

    if (!_.isArray(children)) {
      return;
    }

    _.times(_.size(children), x => {
      this.register({
        cacheKey,
        path: [...basePath, ...path, x],
      });
    });
  };

  // return an array of all the cache ids for a given target path
  getChildrenCacheIds = ({ cacheKey, basePath = [], path = [] } = {}) => {
    const targetArr = _.get(this.data[cacheKey], basePath.concat(path), []);
    return _.map(targetArr, item => _.get(item, '__cache.id'));
  };

  getCache = ({ cacheKey, path = [] }) => {
    return _.get(this.data[cacheKey], path.concat(['__cache']), emptyCacheObj);
  };

  triggerUpdate = (transformation, path = [], value, { cacheKey, options = {} } = {}) => {
    log.debug('triggerUpdate', cacheKey, transformation, path, value, options);

    this.data[cacheKey] = triggerUpdate({
      data: this.data[cacheKey],
      transformation,
      path,
      value,
      options,
    });
  };

  updateCacheFactory = ({ cacheKey, basePath = [] }) => {
    return (transformation, path = [], value, options = {}) => {
      log.debug('triggerCacheUpdate', { cacheKey, basePath, transformation, path, value, options });

      const { silent } = options;
      let targetData = this.data[cacheKey];
      targetData = produce(targetData, draft => {
        if (!targetData) {
          log.error('updateCache cache must already exist', {
            basePath,
            transformation,
            path,
            value,
            cacheKey,
            targetData,
          });
          return targetData;
        }

        // special case, just forcing a refresh
        if (transformation === 'refresh') {
          return triggerUpdate({
            data: targetData,
            path: basePath,
          });
        }

        const pathArray = _.isArray(path) ? path : [path];

        let newCacheData;
        switch (transformation) {
          case 'set':
            _.set(draft, [...basePath, '__cache', 'data', ...pathArray], value);
            break;
          case 'unset':
            newCacheData = _.get(draft, [...basePath, '__cache', 'data'], {});

            // handle a weird case if for some reason we are trying to unset an array
            if (_.isArray(newCacheData)) {
              _.pullAt(newCacheData, pathArray);
            } else {
              _.unset(newCacheData, pathArray);
            }

            _.set(draft, [...basePath, '__cache', 'data'], newCacheData);
            break;
          default:
            console.error(`updateCache ${transformation} transformation not implemented`);
            return;
        }
      });

      if (!silent) {
        this.data[cacheKey] = triggerUpdate({
          data: targetData,
          path: basePath,
        });
      } else {
        this.data[cacheKey] = targetData;
      }
    };
  };
}
