import * as React from 'react';

const cn: any = require('classnames');

import { Button, IButtonProps } from './Button';
import { sizes } from './enums';
import { Header, IHeader } from './Header';
import { ISidebar, Sidebar } from './Sidebar';

export interface ILayout {
  content: any;
  header?: ILayoutHeader | any;
  sidebar?: ILayoutSidebar;
  footer?: ILayoutFooter;
  className?: string;
}

export interface ILayoutHeader extends IHeader {}
export interface ILayoutSidebar extends ISidebar {}
export interface ILayoutFooter {
  left?: {
    actions?: IButtonProps[];
  };
  right?: {
    actions?: IButtonProps[];
  };
}

const LayoutFooter = (props: ILayoutFooter) => {
  const leftActions = props.left && props.left.actions ? props.left.actions : [];
  const rightActions = props.right && props.right.actions ? props.right.actions : [];

  if (!leftActions.length && !rightActions.length) {
    return null;
  }

  return (
    <div className="flex p-6 border-t border-grey-light rounded-b-lg bg-white">
      <div>
        {leftActions.map((action, i) => {
          return <Button key={i} className="mr-2" size={sizes.lg} {...action} />;
        })}
      </div>

      <div className="flex-1" />

      <div>
        {rightActions.map((action, i) => {
          return <Button key={i} className="ml-2" size={sizes.lg} {...action} />;
        })}
      </div>
    </div>
  );
};

export class Layout extends React.Component<ILayout> {
  public render() {
    const { className, header, sidebar, content, footer } = this.props;

    let headerElem;
    if (header) {
      if (React.isValidElement(header)) {
        headerElem = header;
      } else {
        headerElem = <Header {...header} />;
      }
    }

    return (
      <div className={cn('flex flex-col w-full h-full', className)}>
        {headerElem}

        <div
          className={cn('flex flex-1 bg-white', {
            'rounded-t-lg': !headerElem,
            'rounded-b-lg': !footer,
          })}
        >
          {sidebar && <Sidebar className={cn(sidebar.className)} sections={sidebar.sections} />}
          <div className="flex flex-col flex-1">{content}</div>
        </div>

        {footer && <LayoutFooter {...footer} />}
      </div>
    );
  }
}
