import { merge } from 'lodash';

const cn: any = require('classnames');

import { IStringObject } from '@core/types';

import { colors, defaultColor, posX, sizes, themes } from './enums';

export const paddingXMap = {
  [sizes.xs]: '2',
  [sizes.sm]: '2',
  [sizes.md]: '3',
  [sizes.lg]: '4',
  [sizes.xl]: '6',
};

export const paddingYMap = {
  [sizes.xs]: '1',
  [sizes.sm]: '2',
  [sizes.md]: '2',
  [sizes.lg]: '3',
  [sizes.xl]: '4',
};

export const paddingClassName = (
  {
    size,
    iconPos,
    iconClickable,
  }: {
    size: sizes;
    iconPos?: posX;
    iconClickable?: boolean;
  },
  overrides?: IStringObject<any>
) => {
  return cn(
    merge(
      {
        flex: true,
        'items-center': true,
        [`px-${paddingXMap[size]}`]: true,

        'pl-0': !iconClickable && iconPos === posX.left,
        'pr-0': !iconClickable && iconPos === posX.right,
      },
      overrides || {}
    )
  );
};

export const borderClassName = (
  {
    size,
    color = defaultColor,
    theme,
    enabled,
    active,
    hasBorder,
    transparent,
    error,
  }: {
    size: sizes;
    color?: colors;
    theme?: themes;
    enabled?: boolean;
    active?: boolean;
    hasBorder?: boolean;
    transparent?: boolean;
    error?: string;
  },
  overrides?: IStringObject<any>
) => {
  let classNames = {};

  if (theme) {
    if (theme === themes.dark) {
      classNames = {
        [`border-lighten-200`]: hasBorder && !error && !transparent && !active,
        [`hover:border-lighten-300`]: enabled && hasBorder && !error,
        [`border-lighten-300`]: enabled && hasBorder && !error && active,
        [`focus:border-lighten-300`]: enabled && hasBorder && !error,
      };
    } else if (theme === themes.light) {
      // TODO
    }
  } else {
    const colorVal = colors[color];
    classNames = {
      [`border-${colorVal}`]: hasBorder && !error && !transparent && !active,
      [`hover:border-${colorVal}-dark`]: enabled && hasBorder && !error,
      [`border-${colorVal}-dark`]: enabled && hasBorder && !error && active,
      [`focus:border-${colorVal}-dark`]: enabled && hasBorder && !error,
    };
  }

  return cn(
    merge(
      {
        'z-0': true,
        'hover:z-5': true,
        [`z-1`]: enabled && hasBorder && !error && active,
        border: hasBorder && size <= sizes.md,
        'border-2': hasBorder && size > sizes.md,
        'border-red': hasBorder && error,
        'border-transparent': !hasBorder || transparent,
      },
      classNames,
      overrides || {}
    )
  );
};

export const radiusClassName = (
  {
    rounded,
  }: {
    rounded?: boolean;
  },
  overrides?: IStringObject<any>
) => {
  return cn(
    merge(
      {
        rounded: !rounded,
        'rounded-full': rounded,
      },
      overrides || {}
    )
  );
};

export const iconClassName = (
  {
    color,
    size,
    iconPos,
    enabled,
    hasBorder,
    error,
    clickable,
    rounded,
  }: {
    color: colors;
    size: sizes;
    iconPos: posX;
    enabled?: boolean;
    hasBorder?: boolean;
    error?: string;
    clickable?: boolean;
    rounded?: boolean;
  },
  overrides?: IStringObject<any>
) => {
  const colorVal = colors[color];

  return cn(
    'flex items-center h-full',
    merge(
      {
        [`px-${paddingXMap[size]}`]: !rounded,
        'pl-2': iconPos === posX.right && !clickable && !rounded,
        'pr-2': iconPos === posX.left && !clickable && !rounded,

        'cursor-pointer': enabled && clickable,

        [`hover:bg-${colorVal}-lightest`]: enabled && clickable && hasBorder,
        [`hover:bg-darken-100`]: enabled && clickable && !hasBorder,
        'border-l': hasBorder && clickable && iconPos === posX.right,
        'border-r': hasBorder && clickable && iconPos === posX.left,
        'border-red': hasBorder && error,
        [`border-${colorVal}`]: hasBorder && !error,
        [`hover:border-${colorVal}-dark`]: hasBorder && !error,
        'bg-darken-least': clickable && !hasBorder,
      },
      overrides || {}
    )
  );
};
