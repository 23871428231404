import { observable, reaction, action, computed } from 'mobx';
import _ from 'lodash';
import JsonSchemaGenerator from 'json-schema-generator';
import { safeParse, safeStringify } from '@platform/utils/json';
import { emptySchema, Transformer } from '@platform/utils/schemas';
import { buildExportUrl } from '@platform/utils/entities';

import Base from './base';

export class JsonSchemaContainer {
  id = '';
  transformed = {};
  _onSchemaChange = null;
  editorStore = null;
  editorId = null;
  externalUpdate = false;

  @observable.ref
  definitions = {};

  @observable.ref
  schema = '';

  @observable.ref
  error;

  @observable
  lastUpdated;

  constructor(props) {
    this.id = props.id;
    this.editorStore = props.editorStore;
    this.editorId = props.editorId;
    this.updateSchema(props.schema);
    this._onSchemaChange = props.onSchemaChange;

    this.setupReactions();
  }

  onSchemaChange(schema) {
    if (this._onSchemaChange && !this.externalUpdate) {
      this._onSchemaChange(schema);
    }
  }

  setupReactions() {
    reaction(
      () => ({
        lastUpdated: this.lastUpdated,
      }),
      this.handleUpdate,
      {
        name: 'updated',
        delay: 1000,
      }
    );
  }

  @action.bound
  updateDefinitions(definitions) {
    this.definitions = definitions;
  }

  @computed
  get connectedSpecs() {
    return [];
  }

  @computed
  get definitionDropdownData() {
    const data = [];

    const sortedLocalDefinitions = _.flow(
      _.toPairs,
      _.sortBy(0),
      _.fromPairs
    )(this.definitions || {});
    _.forEach(sortedLocalDefinitions, (d, k) => {
      data.push({
        text: `#/definitions/${k}`,
        description: '@local',
        value: `#/definitions/${k}`,
      });
    });

    const specs = this.connectedSpecs;
    _.forEach(specs, (spec, i) => {
      const sortedDefinitions = _.flow(
        _.toPairs,
        _.sortBy(0),
        _.fromPairs
      )(_.get(spec, 'data.definitions', {}));
      _.forEach(sortedDefinitions, (d, k) => {
        data.push({
          text: `#/definitions/${k}`,
          description: spec.id,
          value: `${buildExportUrl({
            entityType: 'specs',
            entity: spec,
          })}#/definitions/${k}`,
        });
      });
    });

    return data;
  }

  @action.bound
  handleUpdate() {
    const newSchema = Transformer.toJsonSchema(this.transformed);
    this.schema = newSchema;
    this.onSchemaChange(this.schema);
  }

  @action
  updateTransformed(t, p, v) {
    this.externalUpdate = false;

    switch (t) {
      case 'set':
        if (_.isEmpty(p)) {
          this.transformed = v;
        } else {
          _.set(this.transformed, p, v);
        }

        this.lastUpdated = new Date().getTime();
        break;
      case 'unset':
        _.unset(this.transformed, p);
        this.lastUpdated = new Date().getTime();
        break;
      default:
        console.warn(`'${t}' transformation not supported by jsonSchemaStore`);
    }
  }

  @action.bound
  updateSchema(schema, { external, immediate } = {}) {
    try {
      let s = schema || {};
      if (typeof s === 'string') {
        s = JSON.parse(s);
      }

      if (safeStringify(s) !== safeStringify(this.schema)) {
        this.externalUpdate = external;
        this.schema = s;
        this.transformed = Transformer.toStoplightSchema(s || emptySchema);

        if (immediate) {
          this.onSchemaChange(this.schema);
        } else {
          this.lastUpdated = new Date().getTime();
        }
      }

      if (this.error) {
        this.invalidSchema = null;
        this.error = null;
      }
    } catch (e) {
      this.invalidSchema = schema;
      this.error = e;
    }
  }

  @action
  generateFromExample(example) {
    try {
      const schema = JsonSchemaGenerator(safeParse(example));
      this.updateTransformed('set', [], Transformer.toStoplightSchema(schema || emptySchema));
    } catch (e) {
      console.warn('Could not generate schema from example.', e);
    }
  }
}

export default class JsonSchemaStore extends Base {
  @observable
  containers = [];

  constructor() {
    super();
    this.init();
  }

  getContainer(id) {
    return _.find(this.containers, { id });
  }

  @action
  initContainer(props = {}, { force } = {}) {
    const { id } = props;

    const current = this.getContainer(id);
    if (current) {
      return;
    }

    this.containers.push(new JsonSchemaContainer(props));
  }

  @action
  removeContainer({ id } = {}) {
    const index = _.findIndex(this.containers, { id });
    if (index < 0) {
      return;
    }

    _.pullAt(this.containers, index);
  }
}
