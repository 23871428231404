import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { alert } from '@platform/utils/alert';

/**
 * This will check the current browser query string and render a brief alert.
 *
 * Examples:
 * foo.com?flash=Hi There
 * foo.com?flash=Whoops!&flash_type=error|warning|info|success
 */
class Flasher extends React.Component {
  static propTypes = {
    routerStore: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    const { routerStore } = this.props;

    const flash = _.get(routerStore, 'location.query.flash');
    if (!flash) return;

    const flashType = _.get(routerStore, 'location.query.flash_type', 'success');

    _.invoke(alert, flashType || 'success', flash);

    routerStore.setQueryParams(
      { flash: null, flash_type: null },
      { preserve: true, replace: true }
    );
  };

  render() {
    return this.props.render();
  }
}

export default Flasher;
