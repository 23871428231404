import * as React from 'react';

const cn: any = require('classnames');

import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';

import { borderClassName, iconClassName, paddingClassName, radiusClassName } from './classNames';
import { colors, defaultColor, defaultSize, posX, sizes, themes } from './enums';
import { divClickHandler } from './events';
import { Icon, IIcon } from './Icon';

export interface IButtonGroupProps {
  children?: any;
  className?: string;
  shadow?: boolean;
}

export const ButtonGroup = ({ children, className, shadow }: IButtonGroupProps) => {
  return (
    <span className={cn('ButtonGroup flex items-center', className)}>
      <span className={cn('flex', { 'shadow-sm': shadow })}>{children}</span>
    </span>
  );
};

export interface IButtonProps {
  id?: string;
  children?: any;
  className?: string;
  title?: string;
  theme?: themes;
  customTheme?: string;
  color?: colors;
  size?: sizes;
  transparent?: boolean;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  hovering?: boolean;
  outlined?: boolean;
  fluid?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  iconColor?: colors;
  icon?: IIcon;
  rightIcon?: IIcon;
  iconPos?: posX;
  onClick?: divClickHandler;
  onClickIcon?: divClickHandler;
  onMouseDown?: divClickHandler;
  attributes?: object;
}

export const Button = ({
  id,
  children,
  className,
  title,
  theme,
  customTheme,
  color = defaultColor,
  size = defaultSize,
  transparent,
  active,
  hovering,
  loading,
  disabled,
  outlined,
  fluid,
  rounded,
  shadow,
  iconColor,
  icon,
  rightIcon,
  iconPos = posX.left,
  onClick,
  onClickIcon,
  onMouseDown,
  attributes = {},
}: IButtonProps) => {
  const colorVal = colors[color];
  const sizeVal = sizes[size];
  const enabled = active || (!disabled && !loading);
  const hasBorder = outlined;

  const paddingClasses = paddingClassName({
    size,
    iconClickable: onClickIcon ? true : false,
    iconPos: icon ? iconPos : undefined,
  });

  const borderClasses =
    !customTheme &&
    borderClassName({
      theme,
      color,
      size,
      enabled,
      active,
      hasBorder,
      transparent,
    });

  const radiusClasses = radiusClassName({ rounded });

  let iconElem;
  if (icon) {
    iconElem = (
      <div
        className={cn(
          'Button-icon',
          iconClassName({
            color,
            size,
            iconPos,
            hasBorder,
            clickable: onClickIcon ? true : false,
            rounded,
          }),
          {
            [`text-${iconColor ? colors[iconColor] : ''}`]: iconColor,
          }
        )}
      >
        <Icon icon={loading ? faSpinner : icon} pulse={loading} />
      </div>
    );
  }

  let rightIconElem;
  if (rightIcon) {
    rightIconElem = (
      <div
        className={cn(
          'Button-icon',
          iconClassName({
            color,
            size,
            iconPos: posX.right,
            hasBorder,
            clickable: false,
          })
        )}
      >
        <Icon icon={rightIcon} />
      </div>
    );
  }

  /**
   * If the icon has a click handler, we attach this to the inner button div.
   * Else we attach to the wrapping div.
   */
  const clickHandler =
    enabled && onClick
      ? (e: any) => {
          onClick(e);
        }
      : undefined;

  const mouseDownHandler =
    enabled && onMouseDown
      ? (e: any) => {
          onMouseDown(e);
        }
      : undefined;

  let bgClasses = {};
  if (!customTheme) {
    if (theme) {
      if (theme === themes.dark) {
        bgClasses = {
          [`bg-lighten-300`]: !outlined && !transparent && !active,
          [`bg-lighten-700`]: !outlined && active,
          [`hover:bg-lighten-100`]: outlined && enabled && !active,
          [`hover:bg-lighten-700`]: !outlined && enabled && !active,
          [`bg-lighten-100`]: outlined && active,
        };
      } else if (theme === themes.light) {
        // TODO
      }
    } else {
      bgClasses = {
        [`bg-${colorVal}`]: !outlined && !transparent && !active,
        [`bg-${colorVal}-dark`]: !outlined && active,
        [`hover:bg-${colorVal}-lightest`]: outlined && enabled && !active,
        [`bg-${colorVal}-lightest`]: outlined && enabled && !active && hovering,
        [`hover:bg-${colorVal}-light`]: !transparent && !outlined && enabled && !active,
        [`hover:bg-${colorVal}-lighter`]: transparent && !outlined && enabled && !active,
        [`bg-${colorVal}-lighter`]: enabled && (hovering || active),
        ['opacity-75']: !enabled,
      };
    }
  }

  let textClasses = {};
  if (!customTheme) {
    if (theme && theme === themes.dark) {
      textClasses = {
        [`text-muted-light`]: (outlined || transparent) && !active,
        [`hover:text-white`]: outlined && enabled,
        'text-white': outlined && active,
        'text-black': !outlined && (!transparent || active),
        'hover:text-black': !outlined && !active && enabled,
      };
    } else if (theme && theme === themes.light) {
      textClasses = {
        [`text-muted`]: (outlined || transparent) && !active,
        [`text-black`]: outlined && active,
        'text-white': !outlined && (!transparent || active),
        'hover:text-white': !outlined && !active && enabled,
      };
    } else {
      textClasses = {
        [`text-${colorVal}-dark`]: (outlined || transparent) && !active,
        [`text-${colorVal}-darker`]: outlined && active,
        'text-white': !outlined && (!transparent || active),
        'hover:text-white': !transparent && !outlined && !active && enabled,
        [`hover:text-${colorVal}-darker`]: transparent && !active && enabled,
        [`text-${colorVal}-darker`]: transparent && !active && enabled && hovering,
      };
    }
  }

  return (
    <div
      id={id}
      title={title}
      onClick={!onClickIcon ? clickHandler : undefined}
      onMouseDown={!onClickIcon ? mouseDownHandler : undefined}
      className={cn(
        'Button',
        className,
        customTheme,
        'flex select-none inline-flex justify-center font-bold leading-reset',
        `h-${sizes[size]}`,
        `text-${sizeVal}`,
        radiusClasses,
        borderClasses,
        textClasses,
        bgClasses,
        {
          // cursor
          'cursor-pointer': enabled && !active,
          'cursor-not-allowed': disabled,
          'cursor-wait': loading,

          // structure
          'w-full': fluid,
          solid: !outlined,

          // shadows
          shadow,
        }
      )}
      {...attributes}
    >
      {iconPos === posX.left && iconElem}

      {children && (
        <div
          onClick={onClickIcon ? clickHandler : undefined}
          onMouseDown={onClickIcon ? mouseDownHandler : undefined}
          className={paddingClasses}
        >
          {loading && !iconElem ? 'loading...' : children}
        </div>
      )}

      {iconPos === posX.right && iconElem}
      {rightIconElem}
    </div>
  );
};
