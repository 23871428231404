import React from 'react';
import Routes from 'react-static-routes';
import { inject, observer } from 'mobx-react';
import { SiteData, withRouteData, Head } from '@stoplight/react-static';

import HubHeader from '@platform/format-hubs/components/HubHeader/viewer';
import ErrorBoundary from '@platform/components/ErrorBoundary';
import * as Bugsnag from '@platform/utils/bugsnag';
import Intercom from '@platform/utils/intercom';
import GoogleAnalytics from '@platform/utils/googleAnalytics';
import { cleanSlashes } from '@platform/utils/url';
import { replaceVariables } from '@platform/utils/variables';

class Hub extends React.Component {
  componentDidMount() {
    const { googleAnalytics, intercom } = this.props;

    Bugsnag.init();

    // Init Stoplight GA
    GoogleAnalytics.init();

    if (googleAnalytics) {
      // Init Hub GA
      GoogleAnalytics.init(googleAnalytics, 'hubTracker');
    }

    if (intercom) {
      Intercom.init(intercom, { hideLauncher: false });
    }
  }

  render() {
    const {
      id,
      nav,
      page = {},
      theme,
      version,
      versions,
      metaTitle,
      sidebarTree = [],
      includeSearch = true,
      rootStore,
    } = this.props;

    let head = null;

    // <Head> causes a memory leak when run in a node env
    if (typeof window !== 'undefined') {
      const meta = page.meta || {};

      let titleTag = metaTitle;
      if (meta.title) {
        titleTag = `${meta.title} - ${metaTitle}`;
      }

      head = (
        <Head>
          <title>{titleTag}</title>
        </Head>
      );
    }

    return (
      <div className="Hub flex flex-col h-full">
        {head}

        <HubHeader
          id={id}
          nav={replaceVariables(nav, { env: rootStore.variables })}
          theme={theme}
          hasHeader={!!nav}
          versions={versions}
          buildPath={cleanSlashes}
          currentVersion={version}
          hasSidebar={sidebarTree.length}
          activePagePath={page.rootPagePath || page.path}
          includeSearch={includeSearch}
        />

        <ErrorBoundary
          className="flex flex-1 relative"
          message="Click the reload button below to continue."
        >
          <Routes />
        </ErrorBoundary>

        <div className="pin absolute flex z--1" role="presentation">
          <div className="h-full flex-1 bg-grey-lightest" />
          <div className="h-full flex-1 bg-white" />
        </div>
      </div>
    );
  }
}

const InjectedHub = inject('routerStore')(observer(Hub));

export default withRouteData(routeData => {
  return <SiteData {...routeData} component={InjectedHub} />;
});
