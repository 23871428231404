import _ from 'lodash';

import { isExternalLink, cleanSlashes } from '@platform/utils/url';

/**
 * openByDefaultToDepth - open if we're at this depth
 * depth - current depth in file tree
 * page - the current page
 * basePath - the full nested path
 * activePath - the current active path
 * buildPath - function that builds the path
 */
const computeFolder = props => {
  const { openByDefaultToDepth = 0, depth = 0, basePath, activePath, buildPath } = props;
  const page = props.page || {};

  const { title, route = {}, data = {}, config } = page;
  const path = route.path;

  const hasChildren = !_.isEmpty(data.children);
  const hasContent = !_.isEmpty(data.blocks);

  const folder = {
    name: title || 'Un-named',
  };

  if (depth === 0) {
    folder.noToggle = true;
  }

  if (depth < openByDefaultToDepth) {
    folder.openByDefault = true;
  }

  if (hasContent) {
    folder.hasContent = true;
  }

  const tokenName = _.get(config, ['sidebar', 'token']);
  const tokenIcon = _.get(config, ['sidebar', 'icon']);

  if (tokenName || tokenIcon) {
    folder.token = {
      name: tokenName,
      icon: tokenIcon,
    };
  }

  const meta = _.get(config, ['sidebar', 'meta']);

  if (meta) {
    folder.meta = meta;
  }

  if (_.isEmpty(path)) {
    folder.isDivider = true;
  } else if (isExternalLink(path)) {
    folder.icon = 'external';
    folder.href = path;
  } else {
    folder.path = cleanSlashes(`/${basePath}/${path}`);

    if (hasContent || !hasChildren) {
      folder.href = buildPath ? cleanSlashes(buildPath(folder.path)) : folder.path;
    }

    if (hasChildren) {
      folder.children = _.map(data.children, child => {
        return computeFolder({
          openByDefaultToDepth,
          depth: depth + 1,
          page: child,
          basePath: folder.path,
          activePath,
          buildPath,
        });
      });
    }
  }

  return folder;
};

export const computeTree = (notUsed, props = {}) => {
  const { buildPath, children, basePath = '', activePath = '' } = props;

  if (_.isEmpty(children)) return null;

  const section = {};

  const treeData = _.map(children, child =>
    computeFolder({
      openByDefaultToDepth: 1,
      page: child,
      basePath,
      activePath,
      buildPath,
    })
  );

  if (treeData.length) {
    section.treeData = treeData;
  }

  return [section];
};
