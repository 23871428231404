import React from 'react';
import cn from 'classnames';
import { Button, Icon } from 'semantic-ui-react';
import { formatComponentStack } from 'bugsnag-react';

import { sdk } from '@platform/utils/bugsnag';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  // adapted from https://github.com/bugsnag/bugsnag-react/blob/master/src/index.js
  componentDidCatch(error, info) {
    this.setState({ error, info });

    const bugsnag = sdk();
    if (!bugsnag || !bugsnag.BugsnagReport) {
      console.error('Uncaught client error, and bugsnag is not initialized!', { error, info });
      return;
    }

    const BugsnagReport = bugsnag.BugsnagReport;
    const handledState = {
      severity: 'error',
      unhandled: true,
      severityReason: { type: 'unhandledException' },
    };

    const report = new BugsnagReport(
      error.name,
      error.message,
      BugsnagReport.getStacktrace(error),
      handledState
    );

    if (info && info.componentStack) {
      info.componentStack = formatComponentStack(info.componentStack);
    }

    report.updateMetaData('react', info);
    bugsnag.notify(report);
  }

  render() {
    const { className, message } = this.props;
    const { error } = this.state;

    const errorMessage =
      message ||
      `If you are on an editor and the save button is still visible, click it before
    reloading!`;

    if (error) {
      return (
        <div
          className={cn('ErrorBoundary flex items-center justify-center h-full w-full', className)}
        >
          <div className="ErrorBoundary-inner">
            <h1>
              Darn! Something went wrong <Icon name="frown" />
            </h1>

            <div className="mt-2">Our team has automatically been notified about this issue.</div>

            <div className="mt-2 font-bold">{errorMessage}</div>

            <div className="mt-2">
              <Button
                icon="refresh"
                content="Reload"
                primary
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
