import _ from 'lodash';

import { route } from '../objectRouter';
import { pathToHash } from '../history';
import { createURL } from '../url';
import { defaultHub } from '../hubs';
import { cleanCollection, defaultCollection } from '../collections';
import { cleanSpec, defaultSpec } from '../specs';
import { cleanInstance, defaultInstance } from '../instances';
import { findFileType } from '../projects';

export const getEntityTypes = () => {
  return ['specs', 'collections', 'hubs', 'instances'];
};

export const getEntityKeys = () => {
  return ['spec', 'collection', 'hub', 'instance'];
};

export const getEntityKey = (entity = 'specs') => {
  return _.trimEnd(entity, 's');
};

export const getEntityServiceKey = (entity = 'specs', serviceType) => {
  const entityKey = getEntityKey(entity);

  return `${entityKey}${serviceType ? _.capitalize(serviceType) : ''}Service`;
};

export const getEntityEditorStoreKey = (entity = 'specs') => {
  if (entity === 'hubs') {
    return 'fileStore';
  }

  const entityKey = getEntityKey(entity);

  return `${entityKey}EditorStore`;
};

export const getEntityTeamServiceKey = (entity = 'specs') => {
  const entityKey = getEntityKey(entity);

  return `team${_.capitalize(entityKey)}Service`;
};

export const getEntityName = (entity = 'specs') => {
  switch (entity) {
    case 'orgs':
      return 'Organization';
    case 'teams':
      return 'Team';
    case 'specs':
      return 'API Spec';
    case 'collections':
      return 'Collection';
    case 'instances':
      return 'Instance';
    case 'hubs':
      return 'Hub';
    default:
      return 'Entity';
  }
};

export const getEntityModule = (entity = 'specs') => {
  switch (entity) {
    case 'specs':
      return 'modeling';
    case 'collections':
      return 'scenarios';
    case 'instances':
      return 'prism';
    case 'hubs':
      return 'docs';
    default:
      return 'Entity';
  }
};

export const getEntityTabName = (entity = 'specs') => {
  switch (entity) {
    case 'orgs':
      return 'Organizations';
    case 'teams':
      return 'Teams';
    case 'specs':
      return 'Modeling';
    case 'collections':
      return 'Testing';
    case 'instances':
      return 'Prism';
    case 'hubs':
      return 'Docs';
    default:
      return 'UNKNOWN';
  }
};

export const getEntityDescription = (entity = 'specs') => {
  switch (entity) {
    case 'orgs':
      return 'Organizations group together people, data, and billing.';
    case 'teams':
      return 'Teams make it easier to control access for groups of people.';
    case 'specs':
      return 'Design APIs with our visual OAS (Swagger 2) editor.';
    case 'collections':
      return 'Debug, test, and orchestrate APIs.';
    case 'instances':
      return 'Spin up mock APIs, validate HTTP traffic, and more.';
    case 'hubs':
      return 'Beautiful technical documentation.';
    default:
      return 'Please select a valid type below.';
  }
};

export const getEntityIcon = (entity = 'specs') => {
  switch (entity) {
    case 'orgs':
      return 'sitemap';
    case 'teams':
      return 'users';
    case 'specs':
      return 'puzzle';
    case 'collections':
      return 'flask';
    case 'instances':
      return 'database';
    case 'hubs':
      return 'book';
    default:
      return 'question';
  }
};

export const getEntityPropId = (entity = 'specs') => {
  return `${getEntityKey(entity)}Id`;
};

export const getEntityFileName = entity => {
  switch (entity) {
    case 'specs':
      return 'oas2.yml';
    case 'collections':
      return 'scenarios.yml';
    case 'instances':
      return 'prism.yml';
    case 'hubs':
      return 'hub.yml';
  }
};

export const getEntityDefaultContent = entity => {
  switch (entity) {
    case 'specs':
      return defaultSpec;
    case 'collections':
      return defaultCollection;
    case 'instances':
      return defaultInstance;
    case 'hubs':
      return defaultHub;
  }
};

export const hideCrumbs = (entity = 'specs') => {
  return entity === 'hubs';
};

export const supportsViewer = (entity = 'specs') => {
  return _.includes(['specs', 'hubs'], entity);
};

export const supportsConnections = (entity = 'specs') => {
  return entity !== 'hubs';
};

export const getEntityData = (entity = 'specs') => {
  return {
    type: entity,
    tabName: getEntityTabName(entity),
    name: getEntityName(entity),
    module: getEntityModule(entity),
    icon: getEntityIcon(entity),
    key: getEntityKey(entity),
    description: getEntityDescription(entity),
    idProp: getEntityPropId(entity),
    hideCrumbs: hideCrumbs(entity),
    supportsViewer: supportsViewer(entity),
    supportsConnections: supportsConnections(entity),

    editorStoreKey: getEntityEditorStoreKey(entity),
    serviceKey: getEntityServiceKey(entity),
    connectionServiceKey: getEntityServiceKey(entity, 'connection'),
    memberServiceKey: getEntityServiceKey(entity, 'member'),
    teamServiceKey: getEntityTeamServiceKey(entity),
    entityTeamServiceKey: getEntityServiceKey(entity, 'team'),
    entityEnvironmentServiceKey: getEntityServiceKey(entity, 'environment'),

    fileName: getEntityFileName(entity),
    defaultContent: getEntityDefaultContent(entity),

    buildExportUrl: e => buildExportUrl({ entityType: entity, entity: e }),
  };
};

export const getEntityTypesWithData = () => {
  const entityTypes = getEntityTypes();
  return _.map(entityTypes, getEntityData);
};

// turn
// http://api.stoplight.io/orgs/stoplight/specs/common/export.json?token=123#/definitions/User
// OR
// http://exporter.stoplight.io/stoplight/specs/common.json?token=123#/definitions/User
// into {path}#/definitions/User
export const _exportUrlToSrn = _.memoize(url => {
  if (!url) {
    return url;
  }

  const parsed = createURL(url);
  if (!parsed || !parsed.pathname) {
    return url;
  }

  return `${_.trim(parsed.pathname, '/')}${parsed.hash}`;
});
export const exportUrlToSrn = ({ url }) => {
  return _exportUrlToSrn(url);
};

export const computeSrnData = srn => {
  const parts = _.take(_.compact(_.split(srn, '/')), 3);
  let computedSrn = parts.join('/');

  // srn must have two slashes to be valid (org/specs/foo for example)
  if (parts.length < 3) {
    return undefined;
  }

  return {
    orgId: parts[0],
    type: parts[1],
    entityId: parts[2],
    srn: computedSrn,
  };
};

export const extractSrn = data => {
  if (_.isEmpty(data)) {
    return undefined;
  }

  // swagger is stored in extension
  // scenario is stored in root @ id
  const srn = _.get(data, 'info.x-stoplight.id') || _.get(data, 'id');

  return computeSrnData(srn);
};

export const cleanEntity = (entity = 'specs', data) => {
  let cleanedData = data;

  switch (entity) {
    case 'collections':
      cleanedData = cleanCollection({ collection: data });
      break;
    case 'specs':
      cleanedData = cleanSpec({ spec: data });
      break;
    case 'instances':
      cleanedData = cleanInstance({ instance: data });
      break;
    default:
      break;
  }

  return cleanedData;
};

export const buildExportUrl = ({ entity = {}, deref, path }) => {
  let url = entity.exportUrl;

  const query = [];

  if (deref) {
    query.push(`deref=${deref}`);
  }

  if (!_.isEmpty(query)) {
    url += `?${query.join('&')}`;
  }

  if (path) {
    url += `#/${_.trim(path, '#/')}`;
  }

  return url;
};

// computes an object that can be fed into the SortableTreeList component
export const computeTreeData = (sortedData, opts) => {
  const {
    data,
    currentPath,
    depth = 0,
    treePath,
    location,
    editor,
    meta = {},
    hideItemActions,
    pathRouting,
    openByDefaultToDepth = 0,
    routeFunc, // used by hubs (new base fileStore routing system)
  } = opts;

  const folders = [];

  for (const f in sortedData) {
    if (!Object.prototype.hasOwnProperty.call(sortedData, f)) continue;

    let currentTreePath = treePath.concat([f]);
    const stringifiedCurrentTreePath = currentTreePath.join('/');

    let configOpts;
    if (routeFunc) {
      configOpts = routeFunc(currentTreePath) || {};
    } else if (editor.contentRouter) {
      configOpts = _.get(route(editor.contentRouter, currentTreePath), 'sidebarOptions', {});
    }

    const {
      childLinkFactory,
      parseChildrenBasePath,
      parseChildren,
      parseChildrenOnly,
      noDefaultActions,
      actionsFactory,
      iconFactory,
      tokenFactory,
      nameFactory,
      classNameFactory,
      metaFactory,
      icon,
      activeIcon,
      openByDefault,
      linkable,
    } = configOpts;

    const isEmpty =
      _.isEmpty(sortedData[f]) || _.isNull(sortedData[f]) || _.isUndefined(sortedData[f]);

    // are we looking at this folder specifically?
    const isActive = !_.isEmpty(currentPath) && _.isEqual(currentPath, currentTreePath);

    // are this folders children active?
    // find the intersection, going in order, ie:
    // currentPath: [a, b, c, d]
    // path: [a, d]
    // = [a]
    const intersection = [];
    for (const i in currentPath) {
      if (currentTreePath[i] === currentPath[i]) {
        intersection.push(currentPath[i]);
      }
    }
    const childActive = currentPath.length > depth + 1 && _.isEqual(intersection, currentTreePath);

    const removePath = editor.removePath
      ? e => {
          e.preventDefault();
          e.stopPropagation();
          editor.removePath(currentTreePath);
          return false;
        }
      : undefined;

    let actions =
      _.has(data, currentTreePath) && !noDefaultActions && !hideItemActions && removePath
        ? [
            {
              id: 'remove',
              icon: 'trash',
              type: 'negative',
              onClick: removePath,
            },
          ]
        : [];

    if (!hideItemActions && actionsFactory) {
      actions = [
        ...actionsFactory(f, sortedData[f], { ...opts, parsedPath: currentTreePath }),
        ...actions,
      ];
    }
    const isMuted = isEmpty && depth < 1;

    let folderIcon;
    const iconIsActive = isActive || childActive;
    if (iconFactory) {
      folderIcon = iconFactory(f, sortedData[f], iconIsActive, opts);
    } else {
      folderIcon = iconIsActive && activeIcon ? activeIcon : icon;
    }

    const folder = {
      name: nameFactory ? nameFactory(f, sortedData[f], opts) : f,
      icon: folderIcon,
      token: tokenFactory ? tokenFactory(f, sortedData[f], opts) : undefined,
      className: classNameFactory ? classNameFactory(f, sortedData[f], opts) : undefined,
      meta: metaFactory ? metaFactory(f, sortedData[f], opts) : meta[stringifiedCurrentTreePath],
      isActive: childLinkFactory ? false : isActive,
      isMuted,
      childActive,
      openByDefault: openByDefault || depth < openByDefaultToDepth,
      actions,
      pathRouting,
      parsedPath: currentTreePath,
    };

    if (parseChildren) {
      let targetChildrenBasePath = [f];
      if (parseChildrenBasePath) {
        targetChildrenBasePath = targetChildrenBasePath.concat(parseChildrenBasePath);
      }

      let targetChildren = _.get(sortedData, targetChildrenBasePath);
      if (_.isArray(parseChildren)) {
        targetChildren = _.pick(targetChildren, parseChildren);
      }

      let nextTreePath = currentTreePath;
      if (parseChildrenOnly && _.get(targetChildren, 'children')) {
        targetChildren = targetChildren.children;
        nextTreePath = currentTreePath;
        if (!_.isEmpty(parseChildrenBasePath)) {
          nextTreePath = nextTreePath.concat(parseChildrenBasePath);
        }
        nextTreePath = nextTreePath.concat('children');
      }

      if (!_.isEmpty(targetChildren)) {
        folder.children = computeTreeData(targetChildren, {
          data,
          currentPath,
          depth: depth + 1,
          treePath: nextTreePath,
          location,
          editor,
          meta,
          openByDefault,
          pathRouting,
          hideItemActions,
          noDefaultActions,
          routeFunc,
          actions:
            !isMuted && removePath
              ? [
                  {
                    id: 'remove',
                    icon: 'trash',
                    type: 'negative',
                    onClick: removePath,
                  },
                ]
              : [],
        });
      }
    }

    let href = {};

    if (pathRouting) {
      href = {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          view: pathToHash({ path: currentTreePath }),
        },
      };
    } else {
      href = {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          edit: pathToHash({ path: currentTreePath }),
        },
      };
    }

    if (linkable || !parseChildren) {
      folder.href = href;
    }

    if (childLinkFactory) {
      folder.children = folder.children || [];
      folder.children.unshift({
        ...childLinkFactory(f, sortedData[f], opts),
        href,
        isActive,
      });
    }

    if (folder.isMuted && folder.items) {
      // don't show muted empty folders
    } else {
      folders.push(folder);
    }
  }

  return folders;
};

/*
 * Connecting a spec to an instance or collection does nothing to enhance
 * the spec. Connecting spec1 to spec2 does enhance spec1, because you can
 * now add $refs in spec1 that point to spec2.
 */
export const getAllowedConnections = (direction, currentType) => {
  const allowedConnections = {
    specs: {
      outbound: ['specs', 'instances'],
      inbound: ['specs', 'collections', 'instances'],
    },
    collections: {
      outbound: ['specs'],
      inbound: ['specs', 'instances'],
    },
    instances: {
      outbound: ['specs', 'collections'],
      inbound: ['specs'],
    },
    hubs: {
      outbound: [],
      inbound: [],
    },
  };

  return _.get(allowedConnections, [currentType, direction], []);
};

export const fileTypeToEntityMap = {
  documentation: 'hubs',
  modeling: 'specs',
  testing: 'collections',
  servers: 'instances',
};

export const getEntityDataForFile = ({ file = {} } = {}) => {
  const fileType = findFileType({ filePath: file.path });

  const entity = fileTypeToEntityMap[fileType];

  if (entity) {
    return getEntityData(entity);
  }

  return {};
};
