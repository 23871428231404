import { reaction, toJS, autorun } from 'mobx';
import { types, flow } from 'mobx-state-tree';
import _ from 'lodash';

import shortid from '@platform/utils/shortid';
import { registerLogger } from '@platform/utils/logging';

const log = registerLogger('@platform/stores', 'BaseStore');

let localforage;
if (typeof window !== 'undefined' && window.localStorage) {
  localforage = require('localforage');
}

/**
 * All stores should extend this model. This model defines universal helpers like local storage persistence.
 */
export const BaseStore = types
  .model({
    uid: types.optional(types.identifier, shortid()),

    persistLoaded: false,

    error: types.optional(types.frozen()),
  })
  .views(self => {
    return {};
  })
  .actions(self => {
    self.persist = [];
    self.persistScope = '';

    return {
      clearError() {
        self.error = undefined;
      },

      setupPersist: flow(function*() {
        yield self.rehydrateFromLocalStorage();
        self.startWatchingPersist();
      }),

      computePersistKey(key) {
        return [self.persistScope, key].join(':');
      },

      rehydrateFromLocalStorage() {
        return new Promise((resolve, reject) => {
          if (localforage && !_.isEmpty(self.persist)) {
            let persistLoadCount = 0;
            const persistCount = _.size(self.persist);

            _.forOwn(self.persist, p => {
              const computedKey = self.computePersistKey(p.key);
              localforage.getItem(computedKey, (err, val) => {
                persistLoadCount += 1;

                if (err) {
                  console.error(`error getting ${computedKey} from localStorage`, err);
                  reject();
                } else if (!_.isUndefined(val) && val !== null) {
                  self.handleRehydrateValue({ key: p.key, options: p, val });
                }

                if (persistLoadCount >= persistCount) {
                  self.completeRehydrate();
                  resolve();
                }
              });
            });
          }
        });
      },

      handleRehydrateValue({ key, options, val } = {}) {
        try {
          _.set(self, key, options.deserialize ? options.deserialize(val) : val);
        } catch (e) {
          log.warn('Could not rehydrate persisted value', { key, options, val });
        }
      },

      completeRehydrate() {
        self.persistLoaded = true;
      },

      startWatchingPersist() {
        autorun(() => {
          reaction(
            () => {
              const vals = {};
              for (const options of self.persist) {
                const key = options.watch || options.key;
                const computedKey = self.computePersistKey(key);
                vals[computedKey] = _.get(self, key);
              }

              return vals;
            },
            self.doPersist,
            {
              name: 'localPersist',
              delay: 1000,
            }
          );
        });
      },

      doPersist() {
        if (localforage) {
          const promises = _.map(self.persist, options => {
            const key = options.key;
            const computedKey = self.computePersistKey(key);

            let val = _.get(self, key);
            val = options.serialize ? options.serialize(val) : val;

            return new Promise(resolve => {
              localforage.setItem(computedKey, toJS(val), err => {
                if (err) {
                  console.error(`error persisting ${computedKey} to localStorage`, err);
                }

                resolve();
              });
            });
          });

          return Promise.all(promises);
        }
      },
    };
  });
