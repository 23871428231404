import * as React from 'react';

const cn: any = require('classnames');

export interface IHeader {
  className?: string;
  title: string | React.ReactElement<any>;
  subtitle?: string | React.ReactElement<any>;
  bgClassName?: string;
}

export class Header extends React.Component<IHeader> {
  public render() {
    const { className, title, subtitle, bgClassName } = this.props;

    return (
      <div
        className={cn(className, bgClassName, 'p-8 rounded-t-lg', {
          'text-white': bgClassName,
          'bg-grey-lighter border-b border-grey-light': !bgClassName,
        })}
      >
        {typeof title === 'string' ? <div className="text-2xl font-extrabold">{title}</div> : title}

        {typeof subtitle === 'string' ? (
          <div
            className={cn({
              'text-lighten-600': bgClassName,
              'text-muted': !bgClassName,
              'mt-2': !!title,
            })}
          >
            {subtitle}
          </div>
        ) : (
          subtitle
        )}
      </div>
    );
  }
}
