import _ from 'lodash';

import shortid from '../shortid';
import { createURL } from '../url';
import { getConfigVar } from '../config';
import { encodeBase32 } from '../strings';
import { stringifyQuery } from '../query';

export const apiName = ({ api = {} }) => {
  return api.id || 'no-id';
};

export const newApi = ({ id, steps } = {}) => {
  return {
    id: id || shortid({ length: 5, lowercase: true }),
  };
};

export const apiTip = (api = {}) => {
  const tipData = [];

  if (!_.isEmpty(api.methods) || !_.isEmpty(api.hosts) || !_.isEmpty(api.paths)) {
    tipData.push({
      text: 'Matches all',
    });

    if (!_.isEmpty(api.methods)) {
      tipData.push({
        text: api.methods.map(a => _.toUpper(a)).join(', '),
        className: 'font-bold underline sl-get-color',
      });
    }

    tipData.push({
      text: 'requests',
    });

    if (!_.isEmpty(api.hosts)) {
      tipData.push({
        text: 'to',
      });

      tipData.push({
        text: api.hosts.join(', '),
        className: 'font-bold underline sl-post-color',
      });
    }

    if (!_.isEmpty(api.paths)) {
      tipData.push({
        text: 'that start with',
      });

      tipData.push({
        text: api.paths.join(', '),
        className: 'font-bold underline sl-patch-color',
      });
    }
  } else {
    tipData.push({
      text: 'Matches all requests',
    });
  }

  if (api.upstream_url) {
    tipData.push({
      text: 'and forwards them to',
    });

    tipData.push({
      text: api.upstream_url,
      className: 'font-bold underline sl-copy-color',
    });
  }

  return tipData;
};

export const ruleName = ({ rule = {} }) => {
  return rule.id || 'no-id';
};

export const newRule = ({ id, steps } = {}) => {
  return {
    id: id || shortid({ length: 5, lowercase: true }),
    before: {},
    after: {},
    done: {},
  };
};

export const ruleTip = (rule = {}) => {
  const tipData = [];

  if (!_.isEmpty(rule.methods) || !_.isEmpty(rule.paths)) {
    tipData.push({
      text: 'Matches all',
    });

    if (!_.isEmpty(rule.methods)) {
      tipData.push({
        text: rule.methods.map(a => _.toUpper(a)).join(', '),
        className: 'font-bold underline sl-get-color',
      });
    }

    tipData.push({
      text: 'requests',
    });

    if (!_.isEmpty(rule.paths)) {
      tipData.push({
        text: 'that start with',
      });

      tipData.push({
        text: rule.paths.join(', '),
        className: 'font-bold underline sl-patch-color',
      });
    }
  } else {
    tipData.push({
      text: 'Matches all requests',
    });
  }

  if (!_.isEmpty(rule.apis)) {
    tipData.push({
      text: 'that also match the',
    });

    tipData.push({
      text: rule.apis.join(', '),
      className: 'font-bold underline sl-copy-color',
    });

    tipData.push({
      text: `${rule.apis.length === 1 ? 'api block' : 'api blocks'}`,
    });
  }

  return tipData;
};

export const cleanRule = ({ rule = {} }) => {
  rule = _.pick(rule, ['id', 'apis', 'methods', 'paths', 'before', 'after', 'done']);

  return rule;
};

export const cleanApi = ({ api = {} }) => {
  api = _.pick(api, ['id', 'upstream_url', 'methods', 'hosts', 'paths', 'specs']);

  return api;
};

export const cleanInstance = ({ instance = {} }) => {
  instance = _.pick(instance, ['name', 'description', 'apis', 'rules', 'scenarios']);

  instance.apis = instance.apis || {};
  for (const apiId in instance.apis) {
    if (instance.apis[apiId]) {
      const api = instance.apis[apiId];
      _.set(instance, ['apis', apiId], cleanApi({ api }));
    }
  }

  instance.rules = instance.rules || {};
  for (const ruleId in instance.rules) {
    if (instance.rules[ruleId]) {
      const rule = instance.rules[ruleId];
      _.set(instance, ['rules', ruleId], cleanRule({ rule }));
    }
  }

  return instance;
};

export const configBlockScore = ({ block }) => {
  let score = 0;

  if (!_.isEmpty(block.methods)) {
    score += 1;
  }

  if (!_.isEmpty(block.hosts)) {
    score += 1;
  }

  if (!_.isEmpty(block.paths)) {
    score += 1;
  }

  if (!_.isEmpty(block.apis)) {
    score += 1;
  }

  if (block.exclusive) {
    score += 1;
  }

  return score;
};

// sort by api score, preserving ordering in the case of a tie
export const sortConfigBlocks = ({ blocks = [] }) => {
  const data = [];

  for (const i in blocks) {
    data.push({
      score: configBlockScore({ block: blocks[i] }),
      index: i,
      block: blocks[i],
    });
  }

  return _.map(_.orderBy(data, ['score', 'index'], ['desc', 'asc']), 'block');
};

export const isApiMatch = ({ method, urlData = {}, api = {} }) => {
  if (!_.isEmpty(api.methods) && !_.includes(api.methods, method)) {
    return false;
  }

  if (!_.isEmpty(api.hosts) && !_.includes(api.hosts, urlData.host)) {
    return false;
  }

  if (!_.isEmpty(api.paths) && !_.includes(api.paths, urlData.pathname)) {
    return false;
  }

  return true;
};

export const matchApi = ({ method, url, apis = [] }) => {
  let match = null;

  let urlData;
  if (url) {
    urlData = createURL(url);
  }

  const sortedApis = sortConfigBlocks({ blocks: apis });

  for (const api of sortedApis) {
    if (isApiMatch({ method, urlData, api })) {
      match = api;
      break;
    }
  }

  return match;
};

export const isRuleMatch = ({ method, urlData = {}, api = {}, rule = {} }) => {
  if (!_.isEmpty(rule.apis) && !_.includes(rule.apis, _.get(api, 'id'))) {
    return false;
  }

  if (!_.isEmpty(rule.methods) && !_.includes(rule.methods, method)) {
    return false;
  }

  if (!_.isEmpty(rule.hosts) && !_.includes(rule.hosts, urlData.host)) {
    return false;
  }

  if (!_.isEmpty(rule.paths) && !_.find(rule.paths, p => _.startsWith(urlData.pathname, p))) {
    return false;
  }

  return true;
};

export const matchRules = ({ method, url, api, rules = [] }) => {
  let matches = [];

  let urlData;
  if (url) {
    urlData = createURL(url);
  }

  const sortedRules = sortConfigBlocks({ blocks: rules });

  for (const rule of sortedRules) {
    if (!_.isEmpty(rule) && isRuleMatch({ method, urlData, api, rule })) {
      if (rule.exclusive) {
        matches = [rule];
        break;
      } else {
        matches.push(rule);
      }
    }
  }

  return matches;
};

export const routeRequest = ({ method, url, apis = [], rules = [] }) => {
  const api = matchApi({ method, url, apis });

  return {
    api,
    rules: matchRules({ method, url, api, rules }),
  };
};

export const validateInstance = ({ instance = {} }) => {
  // const validationData = {
  //   errors: [],
  //   warnings: [],
  // };
  // return new Promise((resolve) => {
  //   // validate the base json schema
  //   validator(instance)
  //     .then(() => {
  //       const uniqueScenarioIds = []; // keep track of scenario ids, to check for uniqueness
  //       const scenarioIdErrors = []; // keep track of which ids we have added errors for
  //       const scenarios = computeAllScenarios({instance: instance});
  //       for (const scenario of scenarios) {
  //         // check that scenario ids are unique
  //         if (_.includes(uniqueScenarioIds, scenario.id) && !_.includes(scenarioIdErrors, scenario.id)) {
  //           const existing = _.filter(scenarios, {id: scenario.id});
  //           const names = _.map(existing, 'name');
  //           validationData.errors.push({
  //             code: 'SCENARIO_UNIQUE_ID',
  //             message: `The following scenarios have the same id (${scenario.id}): ${names.join(', ')}`,
  //             description: 'This can cause a bunch of issues. To fix it, navigate to the root instance, open up the code editor pane, and manually update the scenario ids to be different.',
  //           });
  //         }
  //         uniqueScenarioIds.push(scenario.id);
  //         // check steps
  //         const uniqueStepIds = []; // keep track of scenario ids, to check for uniqueness
  //         const stepIdErrors = []; // keep track of which ids we have added errors for
  //         const steps = scenario.steps || [];
  //         for (const step of steps) {
  //           // check that step ids are unique
  //           if (_.includes(uniqueStepIds, step.id) && !_.includes(stepIdErrors, step.id)) {
  //             const existing = _.filter(steps, {id: step.id});
  //             const names = _.map(existing, 'name');
  //             validationData.errors.push({
  //               code: 'SCENARIO_STEP_UNIQUE_ID',
  //               message: `${scenario.name} (${scenario.id}): The following steps have the same id (${step.id}): ${names.join(', ')}`,
  //               description: 'This can cause a bunch of issues. To fix it, navigate to the scenario, open up the code editor pane, and manually update the step ids to be different.',
  //             });
  //           }
  //           uniqueStepIds.push(step.id);
  //         }
  //       }
  //       resolve(validationData);
  //     })
  //     .catch((err) => {
  //       if (!(err instanceof Ajv.ValidationError)) {
  //         validationData.errors.push({
  //           code: 'VALIDATION_ERROR',
  //           message: String(err),
  //         });
  //       } else if (err.errors) {
  //         for (const e of err.errors) {
  //           validationData.errors.push({
  //             code: 'VALIDATION_ERROR',
  //             ...e,
  //             inner: e,
  //           });
  //         }
  //       }
  //       resolve(validationData);
  //     });
  // });
};

export const defaultInstance = ({ title = 'My Instance' } = {}) => ({
  name: title,
  description: '',
  apis: [],
  rules: [],
});

export const encodeFileId = value => {
  return encodeBase32({
    value,
    stripPadding: true,
    lower: true,
  });
};

export const disablePrismSubdomains = ({ fileId, fileHash }) => {
  const disableSubdomains = getConfigVar('SL_DISABLE_PRISM_SUBDOMAINS');
  if (disableSubdomains) {
    return true;
  }

  const encodedFileId = fileHash || encodeFileId(fileId);

  // Subdomains cannot be larger than 63 characters
  return encodedFileId.length > 63;
};

export const buildInstanceUrl = ({ fileId, fileHash, docs, idLocation = 'subdomain' } = {}) => {
  const encodedFileId = fileHash || encodeFileId(fileId);

  const [protocol, host] = getConfigVar('SL_PRISM_HOST').split('://');
  let instanceUrl = host;

  let query = {};

  if (docs) {
    query.__docs = 1;
  }

  if (idLocation === 'query' || disablePrismSubdomains({ fileHash: encodedFileId })) {
    query.__id = encodedFileId;
  } else {
    instanceUrl = `${encodedFileId}.${instanceUrl}`;
  }

  instanceUrl = `${protocol}://${instanceUrl}`;

  const queryString = stringifyQuery(query);

  if (queryString) {
    instanceUrl = `${instanceUrl}?${queryString}`;
  }

  return instanceUrl;
};
