import _ from 'lodash';

import { updateInputWithAuth } from '@platform/utils/authorization';
import { safeParse } from '@platform/utils/json';
import { getConfigVar } from '@platform/utils/config';
import { encodeFileId } from '@platform/utils/instances';
import { replaceVariables } from '@platform/utils/variables';
import { getEndpointFromSpecs } from '@platform/utils/specs/swagger';

export const scenarioPathLocations = ['before', 'scenarios', 'after', 'utilities'];

export const splitLogicPath = ({ locations = [], path = '' }) => {
  const result = {
    location: '',
    path,
  };

  if (!locations || !(locations instanceof Array)) {
    return result;
  }

  const match = path.match(new RegExp(`${locations.join('|').replace(/\$/g, '\\$')}`));

  if (match) {
    result.location = match[0];
    result.path = _.trimStart(result.path.replace(result.location, ''), '.');
  }

  return result;
};

export const assertionsPassed = ({ assertions = [] } = {}) => {
  let passed = true;

  for (const a of assertions) {
    if (!_.get(a, 'result.pass')) {
      passed = false;
      break;
    }
  }

  return passed;
};

export const stepResultMeta = ({ stepResult } = {}) => {
  const meta = {
    className: 'is-plain',
    type: 'plain',
    code: 2,
    status: 'not run',
  };

  if (_.isEmpty(stepResult)) {
    return meta;
  }

  meta.status = _.get(stepResult, 'output.status') || 'run';

  if (_.gt(stepResult.failCount, 0)) {
    meta.type = 'negative';
    meta.code = 0;
  } else if (_.gt(stepResult.passCount, 0)) {
    meta.type = 'positive';
    meta.code = 1;
  }

  meta.className = `is-${meta.type}`;

  return meta;
};

export const stepName = (step, { skipToken, mappedScenarioNames } = {}) => {
  const targetStep = step || {};

  let name = targetStep.name;

  if (_.isEmpty(name)) {
    switch (targetStep.type) {
      case 'http':
        if (skipToken) {
          name = _.get(targetStep, 'input.url') || '!emptyUrl!';
        } else {
          name = `${_.get(targetStep, 'input.method', 'GET').toUpperCase()} ${_.get(
            targetStep,
            'input.url'
          ) || '!emptyUrl!'}`;
        }

        break;
      case 'ref':
        const refName = _.get(targetStep, 'input.name', '');
        const ref = _.get(targetStep, 'input.$ref', '');
        const refId = _.last(ref.split('/'));
        name = _.get(mappedScenarioNames, refId);

        if (_.isEmpty(name)) {
          if (_.isEmpty(refName)) {
            name = ref || '!emptyRef!';
          } else {
            name = `${refName} [${ref}]`;
          }
        }

        break;
      default:
        name = targetStep.name || 'Un-named';
    }
  }

  return name;
};

export const stepToken = (step, { maxLength = 999 } = {}) => {
  const targetStep = step || {};

  const data = {
    name: null,
    icon: null,
    className: '',
  };

  switch (targetStep.type) {
    case 'http':
      const method = _.get(targetStep, 'input.method', 'get');
      data.name = method.substring(0, maxLength);
      break;
    case 'ref':
      data.name = 'REF';
      break;
    case 'script':
      data.name = 'SCRIPT';
      break;
    default:
      break;
  }

  return data;
};

export const newStep = ({ type, name, input, before, after } = {}) => {
  const step = {
    type: type || 'http',
    name: name || '',
    input: input || {
      method: 'get',
      url: '',
    },
  };

  if (before) {
    step.before = before;
  }

  if (after) {
    step.after = after;
  }

  return step;
};

export const scenarioName = scenario => {
  if (!scenario) {
    return 'Error: undefined scenario.';
  }

  if (scenario.hasOwnProperty('$ref')) {
    if (scenario.$ref) {
      return scenario.$ref;
    } else {
      return 'Invalid scenario ref.';
    }
  } else {
    return scenario.name || 'No name.';
  }
};

export const newScenario = ({ name, steps } = {}) => {
  return {
    name: name || 'New Scenario',
    steps: steps || [newStep()],
  };
};

export const prepareStepRun = ({ step, specs, variables }) => {
  const afterAssertions = _.get(step, 'after.assertions', []);

  if (_.isEmpty(specs) || _.isEmpty(afterAssertions)) {
    return step;
  }

  return {
    ...step,
  };
};

export const prepareScenarioRun = ({ scenario, specs, variables }) => {
  if (_.isEmpty(specs)) {
    return scenario;
  }

  const newSteps = [];

  for (const step of scenario.steps) {
    newSteps.push(prepareStepRun({ step, specs, variables }));
  }

  return {
    ...scenario,
    steps: newSteps,
  };
};

export const prepareCollectionRun = ({ collection, specs, variables }) => {
  if (_.isEmpty(specs)) {
    return collection;
  }

  const newScenarios = [];

  for (const scenario of collection.data.scenarios) {
    newScenarios.push(prepareScenarioRun({ scenario, specs, variables }));
  }

  return {
    ...collection,
    scenarios: newScenarios,
  };
};

const flattenRefStep = refStep => {
  const scenarioSteps = _.get(refStep, 'input.spec.steps');
  const outputSteps = _.get(refStep, 'output.steps');

  if (_.isEmpty(outputSteps) || _.isEmpty(scenarioSteps)) {
    return [];
  }

  let steps = [];

  for (const step of scenarioSteps) {
    const stepResult = outputSteps[step.id];
    if (step.type === 'ref') {
      steps = steps.concat(flattenRefStep(stepResult));
      continue;
    }

    steps.push(stepResult);
  }

  return steps;
};

export const flattenRefOutput = steps => {
  if (_.isEmpty(steps)) {
    return;
  }

  let newSteps = [];

  for (const stepId in steps) {
    if (!_.has(steps, stepId)) {
      continue;
    }

    const step = steps[stepId];

    if (step.type === 'ref') {
      newSteps = newSteps.concat(flattenRefStep(step));
    } else {
      newSteps.push(step);
    }
  }

  return newSteps;
};

export const computeAllScenarios = ({ collection = {} }) => {
  let scenarios = [];
  for (const path of scenarioPathLocations) {
    scenarios = scenarios.concat(_.values(_.get(collection, path, {})));
  }
  return scenarios;
};

export const cleanStep = ({ step = {} }) => {
  step = _.pick(step, ['id', 'type', 'name', 'description', 'input', 'before', 'after']);

  return step;
};

export const cleanScenario = ({ scenario = {} }) => {
  scenario = _.pick(scenario, ['id', 'name', 'description', 'steps']);

  scenario.steps = scenario.steps || [];
  for (const i in scenario.steps) {
    if (!Object.prototype.hasOwnProperty.call(scenario.steps, i)) {
      continue;
    }

    scenario.steps[i] = cleanStep({ step: scenario.steps[i] });
  }

  return scenario;
};

export const cleanCollection = ({ collection = {} }) => {
  collection = _.cloneDeep(
    _.pick(collection, [
      'scenarioVersion',
      'name',
      'description',
      'settings',
      'before',
      'scenarios', // deprecated
      'main',
      'after',
      'utilities', // deprecated
      'utils',
    ])
  );

  for (const path of scenarioPathLocations) {
    const scenarios = _.get(collection, path, []);
    for (const i in scenarios) {
      if (!Object.prototype.hasOwnProperty.call(scenarios, i)) {
        continue;
      }

      const target = [...path.split('.'), i];
      _.set(collection, target, cleanScenario({ scenario: _.get(collection, target) }));
    }
  }

  return collection;
};

export const githubExample = {
  name: 'GitHub Gists',
  description:
    "This 2 step example scenario demonstrates how to fetch a list of gists (step 1), save the id of the first one, and use that id to fetch the gist details (step 2).\nClick the **Run Scenario** button above to run it, click the steps below or on the left to explore how it's put together.\nThe GitHub API has rate limits, so this scenario will sometimes fail if those limits have been reached!",
  steps: [
    {
      type: 'http',
      name: 'List Gists',
      input: {
        url: 'https://api.github.com/gists',
        method: 'get',
      },
      after: {
        assertions: [
          {
            target: 'output.status',
            op: 'eq',
            expected: 200,
          },
        ],
        transforms: [
          {
            target: '$.ctx.gistId',
            source: 'output.body[0].id',
          },
        ],
      },
    },
    {
      type: 'http',
      name: 'Get Gist',
      input: {
        method: 'get',
        url: 'https://api.github.com/gists/{$.ctx.gistId}',
      },
      after: {
        assertions: [
          {
            target: 'output.body.id',
            op: 'eq',
            expected: '{$.ctx.gistId}',
          },
        ],
      },
    },
  ],
};

export const todosExample = {
  name: 'Todos API Crud',
  description:
    'This scenario sends 4 HTTP requests to the Stoplight Todos demo API, which is hosted at http://todos.stoplight.io.\nBefore running it, you **must** set the todos-apikey environment variable below! Set it to "123" - very secure API, we know :).\nOf note in this scenario is the usage of the $.ctx.todoId variable. This variable is set in the capture section of the first step, "Create Todo".',
  steps: [
    {
      type: 'http',
      name: 'Create Todo',
      input: {
        method: 'post',
        url: 'http://todos.stoplight.io/todos?apikey={$$.env.todos-apikey}',
        body: {
          name: 'demo todo',
          completed: false,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      },
      after: {
        assertions: [
          {
            target: 'output.status',
            op: 'eq',
            expected: 201,
          },
        ],
        transforms: [
          {
            target: '$.ctx.todoId',
            source: 'output.body.id',
          },
        ],
      },
    },
    {
      type: 'http',
      name: 'Get Todo',
      input: {
        method: 'get',
        url: 'http://todos.stoplight.io/todos/{$.ctx.todoId}',
      },
      after: {
        assertions: [
          {
            target: 'output.status',
            op: 'eq',
            expected: 200,
          },
        ],
      },
    },
    {
      type: 'http',
      name: 'Update Todo',
      input: {
        method: 'put',
        url: 'http://todos.stoplight.io/todos/{$.ctx.todoId}?apikey={$$.env.todos-apikey}',
        body: {
          completed: true,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      },
      after: {
        assertions: [
          {
            target: 'output.status',
            op: 'eq',
            expected: 200,
          },
          {
            target: 'output.body.completed',
            op: 'eq',
            expected: true,
          },
        ],
      },
    },
    {
      type: 'http',
      name: 'Delete Todo',
      input: {
        method: 'delete',
        url: 'http://todos.stoplight.io/todos/{$.ctx.todoId}?apikey={$$.env.todos-apikey}',
      },
      after: {
        assertions: [
          {
            target: 'output.status',
            op: 'eq',
            expected: 204,
          },
        ],
      },
    },
  ],
};

export const anonCollection = {
  _id: 'scenario-editor',
  id: 'scenario-editor',
  name: 'Example Scenario Collection',
  description:
    'Welcome! Use scenarios to test, automate, and mashup web APIs. \n\nWe\'ve included some example scenarios on the left, but feel free to explore and add your own scenarios by clicking the "New Scenario" button above.\n\n**If you like what you see, click the "Register" button above to create a free account and unlock all sorts of extra features!**',
  scenarios: [githubExample, todosExample],
};

export const defaultCollection = ({ title = 'My Collection' } = {}) => ({
  scenarioVersion: '1.1',
  name: title,
  description: '',
  before: {},
  scenarios: {},
  after: {},
  utilities: {},
});

export const buildConductorUrl = ({ fileId, docs = false, path, variables = {} } = {}) => {
  const parts = getConfigVar('SL_PRISM_HOST').split('://');
  const encodedFileId = encodeFileId(fileId);

  let target = `${parts[0]}://${parts[1]}`;

  if (path) {
    target += path;
  }

  const query = [];

  query.push(`__id=${encodedFileId}`);

  if (docs) {
    query.push('__docs=1');
  }

  _.forEach(variables, (v, k) => {
    if (v) {
      query.push(`${k}=${v}`);
    }
  });
  if (!_.isEmpty(query)) {
    target += `?${query.join('&')}`;
  }

  return target;
};

export const calculateCoverageReport = ({ endpoints, specs, results, env }) => {
  const filteredResults = _.filter(results, step => {
    return _.get(step, 'after.assertions', []).length;
  });

  const report = {
    endpointCount: endpoints.length,
    endpoints: {},

    responseCodes: [],
    passedCodes: [],
    coveragePercent: {},

    assertionsCount: 0,
    basicAssertionsCount: 0,
  };

  if (_.isEmpty(specs)) {
    return report;
  }

  _.forEach(endpoints, endpoint => {
    const reportEndpoint = {};

    const assertionsByCode = {};
    _.forEach(filteredResults, step => {
      const endpointFromSpec = getEndpointFromSpecs({
        specs,
        method: _.get(step, 'input.method'),
        url: _.get(step, 'input.url'),
        variables: {
          env,
        },
      });

      if (
        endpointFromSpec &&
        endpointFromSpec.specId === endpoint.specId &&
        endpointFromSpec.operationId === endpoint.operationId
      ) {
        const assertions = _.get(step, 'after.assertions', []);
        let statusCode = _.get(step, 'output.status');

        _.forEach(assertions, assertion => {
          if (assertion.target === 'output.status') {
            statusCode = assertion.expected;
          }

          assertionsByCode[statusCode] = (assertionsByCode[statusCode] || []).concat(assertion);
        });
      }
    });

    _.forEach(endpoint.responses, code => {
      const assertions = assertionsByCode[code] || [];

      const codeReport = {
        passed: 0,
        failed: 0,
        count: assertions.length,
        icon: 'minus',
        size: 'large',
        color: 'warning',
      };

      _.forEach(assertions, assertion => {
        if (assertion.pass) {
          codeReport.passed++;
        } else {
          codeReport.failed++;
        }
      });

      if (codeReport.failed > 0) {
        codeReport.color = 'negative';
        codeReport.icon = 'remove';
      } else if (codeReport.passed > 0) {
        codeReport.color = 'positive';
        codeReport.icon = 'checkmark';
      }

      report.assertionsCount += codeReport.count;
      if (code.charAt(0) === '2') {
        report.basicAssertionsCount += codeReport.count;
      }

      report.responseCodes.push(code);

      if (codeReport.failed === 0 && codeReport.passed > 0) {
        report.passedCodes.push(code);
      }

      reportEndpoint[code] = codeReport;
    });

    report.endpoints[endpoint.operationId] = reportEndpoint;
  });

  // count of all codes
  report.codeCount = report.responseCodes.length;
  // count of all codes that is passing
  report.passedCodeCount = report.passedCodes.length;

  report.basicCodeCount = _.filter(report.responseCodes, code => code.charAt(0) === '2').length;
  report.passedBasicCodeCount = _.filter(report.passedCodes, code => code.charAt(0) === '2').length;

  report.coveragePercent = {
    all: Math.round((report.passedCodeCount / report.codeCount) * 100) || 0,
    basic: Math.round((report.passedBasicCodeCount / report.basicCodeCount) * 100) || 0,
  };

  report.responseCodes = _.sortBy(_.uniq(report.responseCodes));
  report.passedCodes = _.uniq(report.passedCodes);

  return report;
};

export const getConductorUrl = () => {
  if (window.Electron) {
    return `http://localhost:${window.Electron.config.get('prism.port')}/v1`;
  }

  return `${getConfigVar('SL_PRISM_HOST')}/v1`;
};

export const createScenarioStepRequest = ({
  withCredentials,
  sessionCookie,
  cancelToken,
  collection,
  skipPrism,
  stepPath,
  runMap,
  specs,
  ctx,
  env,
}) => {
  let request = {};

  if (skipPrism) {
    request = convertScenarioStepToHTTPRequest({
      stepPath,
      step: _.get(collection, stepPath),
      variables: {
        env,
        ctx,
      },
    });
  } else {
    request = {
      method: 'post',
      url: `${getConductorUrl()}/run`,
      headers: {
        'Session-Cookie': sessionCookie,
      },
      data: {
        env,
        ctx,
        specs,
        runMap,
        collection,
      },
      cancelToken,
      withCredentials,
    };
  }

  return request;
};

export const convertScenarioStepToHTTPRequest = ({ step = {}, variables = {}, stepPath }) => {
  let resolvedInput = replaceVariables(step.input, variables);

  // Update the request with correct basic auth headers. Other authorization methods are adding already
  if (resolvedInput && resolvedInput.auth && resolvedInput.auth.type === 'basic') {
    resolvedInput = updateInputWithAuth(resolvedInput, resolvedInput.auth);
  }

  const { method, url, headers, body } = resolvedInput;

  return {
    method,
    url,
    headers,
    data: body,
    transformResponse: [
      output =>
        convertHTTPResponseToScenarioResult({
          output,
          variables,
          input: { method, url, headers, body },
          stepPath,
          step,
        }),
    ],
  };
};

export const convertHTTPResponseToScenarioResult = ({
  output,
  variables,
  input,
  stepPath,
  step,
}) => {
  const result = {};

  _.set(result, stepPath, {
    ...step,
    ...variables,
    input,
    output: {
      body: safeParse(output, output),
    },
    type: 'http',
    status: 'completed',
  });

  return result;
};
