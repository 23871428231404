import React from 'react';
import lunr from 'lunr';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import FormSearch from '@platform/components/FormSearch';

const addHighlighting = (str, positions) => {
  if (!str || !positions.length) {
    return str || '';
  }

  let html = [];
  let previousWordEnd = 0;

  _.forEach(positions, ([wordStart, wordLength], index) => {
    const wordEnd = wordStart + wordLength;

    // Add all characters from the last word up to the start of this word
    html.push(<span key={index}>{str.slice(previousWordEnd, wordStart)}</span>);

    // Highlight the matched word
    html.push(
      <span key={`match-${index}`} className="HubSearch-match">
        {str.slice(wordStart, wordEnd)}
      </span>
    );

    previousWordEnd = wordEnd;
  });

  // Add the remaining characters
  if (previousWordEnd < str.length) {
    html.push(<span key="end">{str.slice(previousWordEnd, str.length)}</span>);
  }

  return <React.Fragment>{html}</React.Fragment>;
};

const searchLunr = query => {
  if (!window.LUNR_DATA) return [];

  const idx = lunr.Index.load(window.LUNR_DATA);
  const results = idx.search(query);

  return results.map((result, key) => {
    const item = window.LUNR_RESULTS && window.LUNR_RESULTS[result.ref];

    const positions = { t: [], d: [] };

    // There won't be anything to match against if we don't have a result item
    if (item) {
      _.forEach(result.matchData.metadata, word => {
        if (word['t']) {
          positions['t'] = positions['t'].concat(word['t'].position);
        }

        if (word['d']) {
          positions['d'] = positions['d'].concat(word['d'].position);
        }
      });
    }

    return {
      link: result.ref,
      title: item ? item['t'] : result.ref,
      description: item && item['d'],
      onClick: () => {
        window.location.pathname = result.ref;
      },
      renderer: ({ title, description }) => {
        return (
          <React.Fragment>
            <div className="title">{addHighlighting(title, positions['t'])}</div>

            {description && (
              <div className="description">{addHighlighting(description, positions['d'])}</div>
            )}
          </React.Fragment>
        );
      },
    };
  });
};

const HubSearch = props => {
  const { ui, updateUi, autoFocus, onBlur, noResultsMessage } = props;

  return (
    <FormSearch
      className="HubSearch"
      placeholder="Search..."
      value={ui.search}
      results={ui.results}
      noResultsMessage={noResultsMessage}
      onResultSelect={(e, data) => {
        window.location.pathname = data.link;
      }}
      onSearchChange={(e, value) => {
        updateUi('set', 'search', value);
        updateUi('set', 'results', searchLunr(value));
      }}
      autoFocus={autoFocus}
      onBlur={onBlur}
      minCharacters={1}
      showNoResults
    />
  );
};

export default inject((stores, props) => {
  const { appStore } = stores;

  return {
    ...appStore.injectUi(`HubSearch-${props.id}`),
  };
})(observer(HubSearch));
