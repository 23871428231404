import React from 'react';
import _ from 'lodash';
import { Router } from '@stoplight/react-static';
import { Provider as MobxProvider, useStaticRendering } from 'mobx-react';

import createBrowserHistory from 'history/createBrowserHistory';
import createMemoryHistory from 'history/createMemoryHistory';

import Alert from '@platform/components/Alert';
import ErrorBoundary from '@platform/components/ErrorBoundary';
import { safeParse } from '@platform/utils/json';
import { parseQuery } from '@platform/utils/query';
import { syncHistoryWithStore } from '@platform/utils/router';
import { getConfigVar } from '@platform/utils/config';

import Hub from './containers/Hub';
import { createRootStore } from './stores';

if (process.env.NODE_ENV !== 'test') {
  require('@core/ui/styles/main.css');
  require('@platform/styles/index.styl');
}

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

if (!canUseDOM) {
  useStaticRendering(true);
}

const App = () => {
  const variables = getConfigVar('variables');
  const redirects = getConfigVar('redirects');

  let rootStoreOptions = {
    defaultVariables: variables ? safeParse(variables) : {},
    redirects: redirects,
  };

  let targetHistory;
  if (canUseDOM) {
    rootStoreOptions.location = window.location;
    rootStoreOptions.globalQueryParams = _.pick(parseQuery(window.location.search), ['__build_id']);

    targetHistory = createBrowserHistory();
  } else {
    targetHistory = createMemoryHistory();
  }

  const rootStore = createRootStore(rootStoreOptions);
  const history = syncHistoryWithStore(targetHistory, rootStore.stores.routerStore);

  if (canUseDOM) {
    _.set(window, '__SL.rootStore', rootStore);
    _.set(window, '__SL.history', targetHistory);
  }

  // Cannot use autoScrollToTop/autoScrollToHash since our scroll target is an inner element instead of the body
  return (
    <MobxProvider {...rootStore.stores}>
      <Router history={history} autoScrollToTop={false} autoScrollToHash={false}>
        <ErrorBoundary message="Click the reload button below to continue.">
          <Hub rootStore={rootStore} />
          <Alert />
        </ErrorBoundary>
      </Router>
    </MobxProvider>
  );
};

export default App;
