import _ from 'lodash';
import minilog from 'minilog';

const hasLocalStorage = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

const levelMap = { debug: 1, info: 2, warn: 3, error: 4 };

// this holds all of our loggers, and is made available in the console in browser environments
const registry = {};

const initializeFilters = ({ level = 'info', clear }) => {
  if (clear) {
    minilog.suggest.clear();
  }

  let filters = [{ n: new RegExp('.*'), l: levelMap[level] }];
  if (hasLocalStorage) {
    const persistedFilters = localStorage.getItem('logFilters');
    if (persistedFilters) {
      try {
        filters = JSON.parse(persistedFilters);
      } catch (e) {
        console.error(
          'Logger: could not parse localStorage.logFilters, consider running "delete localStorage.logFilters" in the console to reset'
        );
      }
    }
  }

  _.forOwn(filters, f => {
    if (_.get(f, 'n')) {
      f.n = new RegExp(f.n);
      minilog.suggest._white.push(f);
    } else {
      console.error(
        'Logger: localStorage.logFilters is corrupted, consider running "delete localStorage.logFilters" in the console to reset'
      );
    }
  });
};

const persistFilters = () => {
  if (hasLocalStorage) {
    const filters = _.map(_.cloneDeep(_.get(minilog, 'suggest._white', [])), f => {
      if (_.isRegExp(f.n)) {
        f.n = f.n.source;
      }

      return f;
    });

    localStorage.setItem('logFilters', JSON.stringify(filters));
  }
};

const updateLogger = props => {
  const { registryObj = registry, level, path = [], recurse = true, reset } = props;

  let parents = props.parents;
  if (!parents) {
    parents = _.isEmpty(path) ? [] : path;
  }

  const targetLogger = registryObj.logger;
  if (targetLogger) {
    const loggerName = `log.${parents.join('.')}` || new RegExp(/.*/);

    // remove existing filter
    minilog.suggest._white = _.reject(minilog.suggest._white, { n: loggerName });

    // add new filter, or reset
    if (reset) {
      console.info(`${loggerName} logLevel reset to global`);
    } else {
      minilog.suggest.allow(loggerName, level);
      console.info(`${loggerName} logLevel changed to ${level}`);
    }
  }

  let children = registryObj;
  if (!_.isEmpty(path)) {
    children = _.get(registry, path, {});
  }
  children = _.omit(children, ['enable', 'reset', 'logger']);

  // recursively update child loggers
  if (recurse) {
    _.forOwn(children, (target, name) => {
      updateLogger({
        registryObj: target,
        parents: parents.concat([name]),
        level,
        name,
        reset,
      });
    });
  }
};

const updateLoggerFactory = ({ path = [], levelOverride = 'debug', recurse, reset }) => {
  return (level = levelOverride) => {
    updateLogger({ path, level, recurse, reset });
    persistFilters();
  };
};

export const registerLogger = (namespace, name, { defaultLevel = 'info' } = {}) => {
  if (!namespace || !name) {
    console.warn('registerLogger requires a namespace and name', { namespace, name });
    return;
  }

  const logger = minilog(`log.${namespace}.${name}`);

  registry[namespace] = registry[namespace] || {
    enable: updateLoggerFactory({ path: [namespace] }),
    reset: updateLoggerFactory({ path: [namespace], reset: true }),
  };

  registry[namespace][name] = {
    enable: updateLoggerFactory({ path: [namespace, name] }),
    reset: updateLoggerFactory({ path: [namespace, name], reset: true }),
    logger,
  };

  return logger;
};

export const initLogging = ({ defaultLevel = 'info' } = {}) => {
  // only enable this logger in the browser
  if (typeof window === 'undefined') {
    return;
  }

  minilog.suggest.defaultResult = false;
  initializeFilters({ level: defaultLevel });

  minilog.enable();

  _.merge(registry, {
    enable: updateLoggerFactory({ path: [], recurse: false }),
    reset: () => {
      minilog.suggest.clear().allow(/.*/, defaultLevel);
      delete localStorage.logFilters;
    },
    logger: minilog,
  });

  if (typeof window !== 'undefined') {
    window.log = registry;
  }
};
