import _ from 'lodash';

const transformer = () => {
  const t = {};

  // TO STOPLIGHT SCHEMA

  t._jsonSchemaIsRequired = (schema = {}, field) => {
    return _.indexOf(schema.required, field) !== -1;
  };

  t._reduceJsonSchema = (schema = {}) => {
    let stoplightSchema = {};

    if (schema.type) {
      stoplightSchema.type = schema.type;
    }

    let combiner;
    if (schema.allOf) {
      combiner = 'allOf';
    } else if (schema.anyOf) {
      combiner = 'anyOf';
    } else if (schema.oneOf) {
      combiner = 'oneOf';
    }

    if (combiner) {
      stoplightSchema = _.clone(schema);
      stoplightSchema[combiner] = [];
      for (let part of schema[combiner]) {
        part = t._reduceJsonSchema(part);
        if (schema.type) {
          part.type = schema.type;
        }
        stoplightSchema[combiner].push(part);
      }

      delete stoplightSchema.type;
    } else if (schema.$ref) {
      // references
      stoplightSchema.$ref = schema.$ref;
    } else if (schema.type === 'object' || schema.properties || schema.patternProperties) {
      // objects
      stoplightSchema = _.clone(schema);
      stoplightSchema.type = stoplightSchema.type || 'object';

      // let stoplight schema children equal schema.children
      stoplightSchema.children = schema.children || [];
      let children = schema.properties || [];
      if (schema.patternProperties) {
        if (children) {
          children = _.merge(schema.patternProperties, children);
        } else {
          children = schema.patternProperties;
        }
      }

      for (const i in children) {
        if (!Object.prototype.hasOwnProperty.call(children, i)) {
          continue;
        }

        const child = t._toStoplightSchema(children[i]);
        child.name = i;
        if (t._jsonSchemaIsRequired(schema, i)) {
          child.required = true;
        }
        stoplightSchema.children.push(child);
      }
    } else if (schema.type === 'array' || schema.items) {
      // arrays
      stoplightSchema = _.clone(schema);
      stoplightSchema.type = stoplightSchema.type || 'array';

      if (Object.keys(schema.items || []).length) {
        stoplightSchema.children = [t._toStoplightSchema(schema.items)];
      } else {
        stoplightSchema.children = [];
      }
    } else {
      // basic types
      stoplightSchema = schema;
    }

    delete stoplightSchema.properties;
    delete stoplightSchema.patternProperties;
    delete stoplightSchema.items;
    delete stoplightSchema.required;

    return stoplightSchema;
  };

  t._toStoplightSchema = jsonSchema => {
    let stoplightSchema = jsonSchema;

    if (_.isEmpty(stoplightSchema)) {
      return {};
    }

    return t._reduceJsonSchema(stoplightSchema);
  };

  t.toStoplightSchema = jsonSchema => {
    if (typeof jsonSchema === 'string') {
      jsonSchema = JSON.parse(jsonSchema);
    }

    return t._toStoplightSchema(_.cloneDeep(jsonSchema));
  };

  // END TO STOPLIGHT SCHEMA

  // TO JSON SCHEMA

  t._isType = (value, type) => {
    if (value instanceof Array) {
      return _.includes(value, type);
    }

    return value === type;
  };

  t._reduceStoplightSchema = (schema = {}) => {
    let jsonSchema = {};

    if (schema.type) {
      jsonSchema.type = schema.type;
    }

    // check for combiner type
    let combiner;
    if (schema.allOf) {
      combiner = 'allOf';
    } else if (schema.anyOf) {
      combiner = 'anyOf';
    } else if (schema.oneOf) {
      combiner = 'oneOf';
    }

    // if combiner recurse through and reduce
    if (combiner) {
      jsonSchema = _.cloneDeep(schema);
      jsonSchema[combiner] = [];
      for (let part of schema[combiner]) {
        jsonSchema[combiner].push(t._reduceStoplightSchema(part));
      }

      // If all the combination parts have the same type, just set it on the combo parent,
      // and remove it from the children
      const types = _.map(jsonSchema[combiner], 'type');
      if (_.uniq(types).length === 1) {
        jsonSchema.type = types[0];
        for (const part of jsonSchema[combiner]) {
          delete part.type;
        }
      }
    } else if (schema.$ref) {
      // references
      jsonSchema.$ref = schema.$ref;
    } else if (t._isType(schema.type, 'object')) {
      // objects
      jsonSchema = schema;
      jsonSchema.properties = {};
      jsonSchema.patternProperties = {};
      jsonSchema.required = [];
      if (schema.children) {
        for (const child of schema.children) {
          if (!child) {
            continue;
          }

          if (child.required) {
            jsonSchema.required.push(child.name);
          }
          const newChild = t._toJsonSchema(_.clone(child));
          delete newChild.name;
          const key = child.name || '';
          let props = jsonSchema.properties;
          if (key.startsWith('^')) {
            props = jsonSchema.patternProperties;
          }
          props[key] = newChild;
        }
      }

      if (!Object.keys(jsonSchema.properties).length) {
        delete jsonSchema.properties;
      }

      if (!Object.keys(jsonSchema.patternProperties).length) {
        delete jsonSchema.patternProperties;
      }

      // objects can't have items
      delete jsonSchema.items;
    } else if (t._isType(schema.type, 'array')) {
      // arrays
      jsonSchema = schema;
      if (schema.children instanceof Array) {
        if (schema.children > 1) {
          jsonSchema.items = [];
          for (const child of schema.children) {
            if (child.required) {
              jsonSchema.required.push(child.name);
            }
            const newChild = t._toJsonSchema(_.clone(child));
            delete newChild.name;
            jsonSchema.items.push(newChild);
          }
        } else {
          jsonSchema.items = t._toJsonSchema(schema.children[0]);
        }
      } else {
        jsonSchema.items = t._toJsonSchema(schema.children);
      }

      // arrays can't have properties
      delete jsonSchema.properties;
      delete jsonSchema.patternProperties;
    } else {
      // basic types
      jsonSchema = schema;
      delete jsonSchema.required;
    }

    if (_.isEmpty(jsonSchema.required)) {
      delete jsonSchema.required;
    }

    delete jsonSchema.children;
    delete jsonSchema.name;
    delete jsonSchema._active;
    delete jsonSchema._isOpen;

    // attempt a primitive conversion (need to make sure we store number representation, not string, for validations) if type is not string!
    // ie maxLength: "5" -> maxLength: 5, "[]" -> []
    for (const prop in jsonSchema) {
      if (
        prop !== 'type' &&
        prop !== 'types' &&
        typeof jsonSchema[prop] === 'string' &&
        prop !== 'description' &&
        jsonSchema.type &&
        !jsonSchema.type.includes('string')
      ) {
        // convert all primitives (booleans, arrays, objects, etc)
        try {
          jsonSchema[prop] = JSON.parse(jsonSchema[prop]);
        } catch (e) {}
      }
    }

    return jsonSchema;
  };

  t._toJsonSchema = stoplightSchema => {
    let jsonSchema = stoplightSchema;

    if (!jsonSchema) {
      return {};
    }

    return t._reduceStoplightSchema(jsonSchema);
  };

  t.toJsonSchema = stoplightSchema => {
    return t._toJsonSchema(_.cloneDeep(stoplightSchema));
  };

  // END TO JSON SCHEMA

  return t;
};

const SchemaTransformer = transformer();

export default SchemaTransformer;
