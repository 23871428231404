import matchPath from 'react-router/matchPath';
import Router from 'react-router/Router';
import qs from 'qs';

// ensure we're using the exact code for default root match
const { computeMatch } = Router.prototype;

/**
 * By default will gather up all routes that match the given pathname, until the first isExact route is found.
 * Set exact = false to stop at the first matched route, no matter if it is exact or not.
 */
export const computeBranch = (routes = [], pathname = '', opts = {}, _internal = {}) => {
  const { exact = true } = opts;
  const {
    branch = [],
    originalPathname = pathname,
    parentPath = '',
    parentParams = {},
  } = _internal;

  routes.some((route = {}) => {
    const isRoot = !route.path || route.path === '/';
    const parent = branch[branch.length - 1];
    const childRoutes = route.routes || [];

    let match;
    if (route.path) {
      match = matchPath(pathname, route);
    } else if (branch.length) {
      match = parent.match; // use parent match
    } else {
      match = computeMatch(pathname); // use default "root" match
    }

    let isExact;
    let nextParentPath = `${parentPath === '/' ? '' : parentPath}`;
    if (match) {
      nextParentPath += match.url;

      const params = Object.assign({}, parentParams, match.params || {});

      if (childRoutes.length) {
        const nextPath = isRoot ? pathname : pathname.replace(match.url, '');
        computeBranch(childRoutes, nextPath, opts, {
          branch,
          originalPathname,
          parentPath: nextParentPath,
          parentParams: params,
        });
      }

      isExact = match.isExact || (branch.length && branch[branch.length - 1].match.isExact);

      if (isExact) {
        branch.unshift({
          route,
          match,
        });
      }
    }

    return match && (!exact || isExact);
  });

  return branch;
};

export const computeBranchRoute = (branch = []) => {
  const leaf = branch[branch.length - 1];

  const route = {
    path: '',
    redirect: leaf && leaf.route ? leaf.route.redirect : null,
  };

  for (const b of branch) {
    const p = b.route.path;
    if (p && p !== '/') {
      route.path += p;
    }
  }

  route.path = route.path || '/';

  return route;
};

export const computeBranchProperty = ({ branch, match, propName }) => {
  return ({ context = {} } = {}) => {
    const computedProps = {};

    for (const b of branch) {
      const { route, match } = b;

      const factory = route[propName];
      if (typeof factory === 'function') {
        const props = factory({ route: b, match, context }) || {};

        // acummulate props from parents, allowing children to overwrite parent props
        Object.assign(computedProps, props);
      }
    }

    return computedProps;
  };
};

export const loadBranchData = ({ branch, match }) => {
  return async (context = {}, { continueOnError } = {}) => {
    const result = {
      data: {},
      error: null,
      asyncError: null,
    };

    // asyncLoaders don't block branches from being processed
    // however, loadBranchData will not return until they have been completed
    const asyncLoaders = [];
    for (const b of branch) {
      const { route } = b;
      if (route.loadDataAsync) {
        asyncLoaders.push(route.loadDataAsync({ route, match, context }));
      }
    }
    let asyncLoader;
    if (asyncLoaders.length) {
      asyncLoader = Promise.all(asyncLoaders);
    }

    for (const b of branch) {
      const { route } = b;

      if (route.loadData) {
        try {
          const data = await route.loadData({ route, match, context, data: result.data });
          Object.assign(result.data, data);
        } catch (e) {
          result.error = result.error || e;

          if (!continueOnError) {
            break;
          }
        }
      }
    }

    if (asyncLoader) {
      try {
        const datas = await asyncLoader;
        for (const data of datas) {
          Object.assign(result.data, data);
        }
      } catch (e) {
        result.asyncError = e;
      }
    }

    return result;
  };
};

export const computeLocationMatch = (routes = [], location = {}, opts = {}) => {
  const { pathname = '', search = '', hash = '' } = location;
  const { qsOptions = { arrayFormat: 'brackets' } } = opts;

  const branch = computeBranch(routes, pathname, opts);

  const match = {
    route: computeBranchRoute(branch),
    location: {
      pathname,
      search,
      hash,
    },
    params: {},
    query: {},
  };

  if (search) {
    match.query = qs.parse(search.slice(1), qsOptions);
  }

  for (const b of branch) {
    Object.assign(match.params, b.match.params || {});
  }

  match.computeProps = computeBranchProperty({ branch, match, propName: 'props' });
  match.computeLayout = computeBranchProperty({ branch, match, propName: 'layout' });
  match.loadData = loadBranchData({ branch, match });

  return match;
};
