import _ from 'lodash';

export const commonMimeTypes = [
  'application/json',
  'application/xml',
  'multipart/form-data',
  'text/html',
  'text/plain',
  'application/EDI-X12',
  'application/EDIFACT',
  'application/atom+xml',
  'application/font-woff',
  'application/gzip',
  'application/javascript',
  'application/octet-stream',
  'application/ogg',
  'application/pdf',
  'application/postscript',
  'application/soap+xml',
  'application/x-bittorrent',
  'application/x-tex',
  'application/xhtml+xml',
  'application/xml-dtd',
  'application/xop+xml',
  'application/zip',
  'application/x-www-form-urlencoded',
];

export const httpMethods = {
  get: { name: 'GET' },
  post: { name: 'POST' },
  put: { name: 'PUT' },
  patch: { name: 'PATCH' },
  delete: { name: 'DELETE' },
  copy: { name: 'COPY' },
  head: { name: 'HEAD' },
  link: { name: 'LINK' },
  unlink: { name: 'UNLINK' },
  purge: { name: 'PURGE' },
  lock: { name: 'LOCK' },
  unlock: { name: 'UNLOCK' },
  options: { name: 'OPTIONS' },
  trace: { name: 'TRACE' },
};

export const httpMethodRegex = new RegExp(Object.keys(httpMethods).join('|'), 'i');

export const httpCodes = {
  100: 'Continue',
  101: 'Switching Protocols',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  422: 'Unprocessable Entity',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: '(Unused)',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect (experiemental)',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot (RFC 2324)",
  420: 'Enhance Your Calm (Twitter)',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response (Nginx)',
  449: 'Retry With (Microsoft)',
  450: 'Blocked by Windows Parental Controls (Microsoft)',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request (Nginx)',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates (Experimental)',
  507: 'Insufficient Storage (WebDAV)',
  508: 'Loop Detected (WebDAV)',
  509: 'Bandwidth Limit Exceeded (Apache)',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  598: 'Network read timeout error',
  599: 'Network connect timeout error',
};

export const httpCodeOptions = filter => {
  let codes = {};

  if (filter) {
    for (const code of filter) {
      const found = httpCodes[Number(code)];
      if (found) {
        codes[Number(code)] = found;
      }
    }
  } else {
    codes = httpCodes;
  }

  return _.map(codes, (v, k) => ({
    text: `${k} ${v}`,
    value: Number(k),
  }));
};
