import _ from 'lodash';
import ClientOAuth2 from 'client-oauth2';
import { types, flow } from 'mobx-state-tree';

import { openAuthPopup } from '@platform/utils/popups';
import { createURL, getAllParams, getHashParts, cleanSlashes } from '@platform/utils/url';
import { deepFind } from '@platform/utils/objects';

import { BaseStore } from './_base';
import { BaseManager, BaseInstance } from './_manager';
import { BaseService } from './services/_base';

const AuthorizationMethods = {
  accessCode: 'body',
  application: 'header',
  implicit: 'header',
  password: 'header',

  // accessCode and password were renamed in OpenAPI 3
  authorizationCode: 'body',
  clientCredentials: 'header',
};

export const create = ({ data, env, options = {} }) => {
  // this is unique on the project level
  const Oauth = types
    .model({
      id: types.string,
      tokens: types.optional(types.frozen()),
      credentials: types.optional(types.frozen()),
      isCreatingToken: false,

      error: types.optional(types.frozen()),
    })
    .views(self => {
      return {
        get tokenList() {
          return _.sortBy(self.tokens, 'created_at');
        },

        get isValid() {
          const credentials = self.credentials || {};
          const {
            access_token_url,
            authorize_url,
            client_secret,
            client_id,
            username,
            password,
            flow,
          } = credentials;

          let valid = client_id && client_secret;

          switch (flow) {
            case 'password':
              valid = valid && username && password && access_token_url;
              break;

            case 'application':
            case 'clientCredentials':
              valid = valid && access_token_url;
              break;

            case 'implicit':
              valid = valid && authorize_url;
              break;

            default:
              valid = valid && authorize_url && access_token_url;
              break;
          }

          return valid;
        },

        get authConfig() {
          const credentials = self.credentials || {};
          let basePath = '';

          if (typeof window !== 'undefined') {
            basePath = window.location.origin;
            // Add a base path to redirectUri to be able to make a request to authorization
            // callback URL. It works in a context of a compiled static hub
            const customBasePath = _.get(env.rootStore.stores, 'pageStore.current.basePath');

            if (_.isString(customBasePath)) {
              basePath += customBasePath;
            }
          }

          return {
            scopes: _.compact(_.map(_.split(credentials.scope, ','), _.trim)),
            clientId: credentials.client_id,
            clientSecret: credentials.client_secret,
            authorizationUri: credentials.authorize_url,
            accessTokenUri: credentials.access_token_url,
            redirectUri: cleanSlashes(`${basePath}/oauth/callback/success`),
          };
        },

        get authorizationMethod() {
          const { flow = 'accessCode', authorization_method } = self.credentials || {};

          return authorization_method || AuthorizationMethods[flow];
        },
      };
    })
    .actions(self => {
      self.persist = [
        {
          key: 'credentials',
        },
        {
          key: 'tokens',
        },
      ];
      self.persistScope = `oauth2TesterCredentials:${self.id}`;

      return {
        afterCreate: flow(function*() {
          yield self.setupPersist();
        }),

        updateCredentials: (t, p, v) => {
          let credentials;

          if (_.isEmpty(p)) {
            credentials = v || {};
          } else {
            credentials = _.cloneDeep(self.credentials || {});
            _.set(credentials, p, v);
          }

          self.credentials = credentials;
        },

        getToken: flow(function*({ onSuccess, onError }) {
          self.isCreatingToken = true;

          const authConfig = self.authConfig;
          const { flow = 'accessCode' } = self.credentials;

          const authOpts = {};

          if (self.authorizationMethod) {
            authOpts.body = {
              client_id: authConfig.clientId,
              client_secret: authConfig.clientSecret,
            };
          }

          const auth = new ClientOAuth2({ ...authConfig, ...authOpts });

          let user;
          let uri;

          try {
            switch (flow) {
              case 'accessCode':
              case 'authorizationCode':
                uri = yield self.openOAuthPopup(auth.code.getUri());
                if (uri) {
                  user = yield env.rootStore.api.request({
                    method: 'POST',
                    url: '/oauth_token_capture',
                    data: {
                      client_id: authConfig.clientId,
                      client_secret: authConfig.clientSecret,
                      authorize_url: authConfig.authorizationUri,
                      access_token_url: authConfig.accessTokenUri,
                      redirect_uri: authConfig.redirectUri,
                      scopes: authConfig.scopes,
                      authorization_method: self.authorizationMethod,
                      uri,
                    },
                  });
                }
                break;

              case 'implicit':
                uri = yield self.openOAuthPopup(auth.token.getUri());
                if (uri) {
                  user = yield auth.token.getToken(uri);
                }
                break;

              case 'password':
                user = yield auth.owner.getToken(
                  self.credentials.username,
                  self.credentials.password
                );
                break;

              case 'application':
              case 'clientCredentials':
                user = yield auth.credentials.getToken();
                break;

              default:
            }
          } catch (err) {
            self.error = JSON.stringify(err, null, 4);
            self.isCreatingToken = false;
            return;
          }

          // Client oauth2 getToken returns a ClientOauthInstance nested inside of itself repeatedly
          // So we extract the data property to avoid infinite loop while searching for the accessToken
          // user on success full requests will always have a data property in all four cases
          if (user && typeof user === 'object' && user.data) {
            user = user.data;
          }

          const accessToken = user && deepFind(user, /\b(accessToken|access_token)\b/);

          if (accessToken) {
            self.addToken(accessToken);

            if (onSuccess) {
              onSuccess(accessToken);
            }
          }

          self.isCreatingToken = false;
        }),

        addToken(token) {
          const tokens = self.tokens || [];
          const credentials = self.credentials || {};
          const existing = _.find(tokens, { id: token });

          if (existing || !token) return;

          const t = {
            id: token,
            access_token: token,
            name: credentials.name,
            type: 'access_token',
            scope: credentials.scope,
            created_at: new Date(),
          };

          const { projectStore } = env.rootStore.stores;

          if (projectStore && projectStore.current) {
            projectStore.current.updateActiveEnv(
              { oauth_access_token: token },
              { preserveShared: true }
            );
          } else if (env.rootStore.updateVariables) {
            const envVars = _.get(env.rootStore, '_variables', {});
            env.rootStore.updateVariables({ ...envVars, oauth_access_token: token });
          }

          self.tokens = tokens.concat(t);
        },

        remove(tokenId) {
          const tokens = _.cloneDeep(self.tokens || []);
          _.remove(tokens, { id: tokenId });
          self.tokens = tokens;
        },

        openOAuthPopup: flow(function*(authUrl, onSuccess) {
          const popup = openAuthPopup(authUrl);

          try {
            return yield self.setListener(popup);
          } catch (err) {
            //
          }
        }),

        setListener(popup) {
          return new Promise((resolve, reject) => {
            window.addEventListener(
              'message',
              ({ orgin, data }) => {
                let res;
                const { flow = 'accessCode' } = self.credentials;

                const location = createURL(data);
                try {
                  switch (flow) {
                    case 'accessCode':
                    case 'authorizationCode':
                      res = getAllParams(location);
                      break;

                    case 'implicit':
                      res = getHashParts(location);
                      break;
                    default:
                  }
                } catch (err) {
                  // swallow err
                }

                if (!_.isEmpty(res)) {
                  popup.close();

                  if (res.access_token || res.code) {
                    return resolve(location.href);
                  }

                  if (res.reason) {
                    return reject(new Error(res.reason));
                  }
                }
              },
              false
            );
          });
        },

        updateIsCreatingToken(v) {
          self.isCreatingToken = v;
        },

        clearError() {
          self.error = undefined;
        },
      };
    });

  const OauthInstance = types
    .compose(
      BaseStore,
      BaseInstance,
      BaseService,
      Oauth
    )
    .named('OauthInstance');

  const Base = types
    .model({
      instances: types.optional(types.array(OauthInstance), []),
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      self.instanceModel = OauthInstance;

      return {
        getOrRegisterInstance(userId, projectId) {
          const id = `${userId}-${projectId}`;
          const instance = self.getInstance(id);

          if (instance) return instance;

          return self.register(id, { userId, projectId });
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseManager,
      Base
    )
    .named('OauthTokenStore');

  return Service.create(data, env);
};
