import React from 'react';
import SAlert from 'react-s-alert';
import { inject } from 'mobx-react';

import Flasher from '../Flasher';

const defaultAlertProps = {
  effect: 'stackslide',
  position: 'bottom',
  timeout: 10000,
  html: false,
  onRouteClose: false,
  stack: {
    spacing: 15, // in px
    limit: 3,
  },
  offset: 25,
};

const Alert = props => {
  const { routerStore, ...alertProps } = props;

  return (
    <Flasher
      routerStore={routerStore}
      render={() => <SAlert {...Object.assign({}, defaultAlertProps, alertProps)} />}
    />
  );
};

export default inject('routerStore')(Alert);
