import React from 'react';
import { inject, observer } from 'mobx-react';

class RouterLayoutSlot extends React.Component {
  constructor(props) {
    super(props);
    const { slot = {} } = props;
    this.state = { id: slot.id, displayName: slot.component.displayName };
  }

  shouldComponentUpdate(nextProps) {
    const { slot = {} } = nextProps;
    return (
      typeof slot.id === 'undefined' ||
      slot.id !== this.state.id ||
      slot.component.displayName !== this.state.displayName
    );
  }

  componentWillUpdate(nextProps, nextState) {
    const { slot = {} } = nextProps;
    nextState.id = slot.id;
    nextState.displayName = slot.component.displayName;
  }

  render() {
    const { slot = {}, ...slotProps } = this.props;

    const Component = slot.component;

    return <Component {...slotProps} {...slot.props} />;
  }
}

const RouterLayoutSlotWrapper = props => {
  const { id, routerStore, ...slotProps } = props;

  const { layout = {} } = routerStore;
  const slot = layout[id] || {};

  const Component = slot.component;
  if (!Component) {
    return null;
  }

  return <RouterLayoutSlot id={id} {...slotProps} slot={slot} />;
};

export default inject('routerStore')(observer(RouterLayoutSlotWrapper));
