import * as React from 'react';
import { AutoSizer, List } from 'react-virtualized';

const cn: any = require('classnames');

import { IStringObject } from '@core/types';

/**
 * Wraps [react-virtualized](https://github.com/bvaughn/react-virtualized).
 */

export type rowHeightFunc = (args: { index: number }) => number;

export type scrollAlignmentOpts = 'auto' | 'start' | 'end' | 'center';

/*
  Responsible for rendering a single row, given its index. Example:

  ```js
  const user = list[index]

  // If row content is complex, consider rendering a light-weight placeholder while scrolling.
  const content = isScrolling
    ? '...'
    : <User user={user} />

  // Style is required since it specifies how the row is to be sized and positioned.
  // React Virtualized depends on this sizing/positioning for proper scrolling behavior.
  // By default, the List component provides following style properties:
  //    position
  //    left
  //    top
  //    height
  //    width
  // You can add additional class names or style properties as you would like.
  // Key is also required by React to more efficiently manage the array of rows.
  return (
    <div
      key={key}
      style={style}
    >
      {content}
    </div>
  )
  ```
 */
export type rowRendererFunc = (
  args: {
    // Unique key within array of rendered rows
    key: string;

    // Index of row
    index: number;

    // The List is currently being scrolled
    isScrolling: boolean;

    // This row is visible within the List (eg it is not an overscanned row)
    isVisible: boolean;

    // Reference to the parent List (instance)
    parent: any;

    // Style object to be applied to row (to position it).
    // This must be passed through to the rendered row element.
    style: IStringObject<any>;
  }
) => React.ReactElement<any>;

export interface IListScrollerProps {
  // Number of rows in list.
  rowCount: number;

  // Either a fixed row height (number) or a function that returns the height of a row given its index.
  rowHeight: number | rowHeightFunc;

  // Responsible for rendering a row
  rowRenderer: rowRendererFunc;

  className?: string;
  noRowsRenderer?: () => JSX.Element;
  onScroll?: () => void;

  // Controls the alignment scrolled-to-rows.
  scrollToAlignment?: scrollAlignmentOpts;

  // Row index to ensure visible (by forcefully scrolling if necessary)
  scrollToIndex?: number;

  // Forced vertical scroll offset; can be used to synchronize scrolling between components
  scrollTop?: number;
}

export class ListScroller extends React.Component<IListScrollerProps> {
  private list: any;

  public render() {
    const {
      rowCount,
      rowHeight,
      rowRenderer,
      className,
      noRowsRenderer,
      onScroll,
      scrollToAlignment,
      scrollToIndex,
      scrollTop,
    } = this.props;

    return (
      <AutoSizer className={cn(className)}>
        {({ width, height }: { width: any; height: any }) => (
          <List
            ref={(elem: any) => {
              this.list = elem;
            }}
            width={width}
            height={height}
            rowCount={rowCount}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer}
            noRowsRenderer={noRowsRenderer}
            onScroll={onScroll}
            scrollToAlignment={scrollToAlignment}
            scrollToIndex={scrollToIndex}
            scrollTop={scrollTop}
          />
        )}
      </AutoSizer>
    );
  }

  /**
   * Scroll to the specified offset. Useful for animating position changes.
   */
  protected scrollToPosition = (scrollTop: number) => {
    if (this.list) {
      this.list.scrollToPosition(scrollTop);
    }
  };

  /**
   * Ensure row is visible. This method can be used to safely scroll back to a cell that a user has
   * scrolled away from even if it was previously scrolled to.
   */
  protected scrollToRow = (index: number) => {
    if (this.list) {
      this.list.scrollToRow(index);
    }
  };
}
