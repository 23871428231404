import React from 'react';
import { inject, observer } from 'mobx-react';
import Redirect from 'react-router/Redirect';

const RouterRedirect = props => {
  const { routerStore } = props;

  const { match = {} } = routerStore;
  const { route = {} } = match;

  if (!route.redirect) return null;

  return (
    <Redirect
      key={'red'}
      from={route.path}
      to={typeof route.redirect === 'function' ? route.redirect({ match }) : route.redirect}
    />
  );
};

export default inject('routerStore')(observer(RouterRedirect));
