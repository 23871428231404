import _ from 'lodash';
import { observable, action } from 'mobx';

import { URI } from '@core/uri';

import { buildParams } from '@platform/utils/history';
import { stringifyQuery, parseQuery } from '@platform/utils/query';

export default class RouterStore {
  history = null;
  rootStore = null;
  routes = [];
  globalQueryParams = {};
  redirects = [];

  @observable
  location = null;

  constructor({ rootStore, routes, location, redirects, ...extraProps }) {
    this.rootStore = rootStore;
    this.routes = routes;
    this.redirects = redirects || [];

    Object.assign(this, extraProps);

    // Setup the initial location. Useful when rendered on the server.
    if (!_.isEmpty(location)) {
      this.handleLocationChange(location);
    }
  }

  /**
   * When the location changes, we need to calculate the current route data, params, and query.
   * Then, we merge that into the normal browser location object that is passed in.
   */

  @action
  handleLocationChange = location => {
    this.location = {
      host: this.rootStore.isClient ? _.get(window, 'location.host', '') : location.host,
      ...this.parseLocation(location),
    };
  };

  _triggerRouteInterceptors = ({ location }) => {
    const parsedLocation = this.parseLocation(location);

    const redirect = _.find(this.redirects, redirect => {
      if (!redirect.from || !redirect.to) return;

      return redirect.from.replace(/^\//, '') === parsedLocation.pathname.replace(/^\//, '');
    });

    if (redirect && redirect.to) {
      if (/^http/.test(redirect.to)) {
        window.location.href = redirect.to;
      } else {
        window.location.pathname = redirect.to;
      }

      return;
    }

    return location;
  };

  /*
   * History methods
   */

  parseLocation = location => {
    if (typeof location === 'string') {
      location = URI.parse(location).location;
    }

    if (location.search && !location.query) {
      location.query = parseQuery(location.search);
    }

    return location;
  };

  /*
  History allows passing strings or objects to its respective functions,
  interceptors requires location to be an object so check for string and parse
  */
  push = location => {
    if (!this.history) return;

    location = this._triggerRouteInterceptors({ type: 'push', location });
    if (!location) return;

    return this.history.push(location);
  };

  replace = location => {
    if (!this.history) return;

    location = this._triggerRouteInterceptors({ type: 'replace', location });
    if (!location) return;

    return this.history.replace(location);
  };

  go = location => {
    if (!this.history) return;

    location = this._triggerRouteInterceptors({ type: 'go', location });
    if (!location) return;

    return this.history.go(location);
  };

  goBack = () => {
    if (!this.history) return;

    return this.history.goBack();
  };

  goForward = () => {
    if (!this.history) return;

    return this.history.goForward();
  };

  shouldPreventRedirect = () => {
    return false;
  };

  buildQueryParams = (params, options = {}) => {
    if (!this.rootStore.isClient) return;

    const location = this.location;
    const query = buildParams(location, params, {
      preserve: true,
      ...options,
    });

    return {
      ...location,
      query,
      search: _.isEmpty(query) ? '' : `?${stringifyQuery(query, { encode: false })}`,
    };
  };

  setQueryParams = (params, options = {}) => {
    const location = this.buildQueryParams(params, options) || {};

    if (_.get(this.location, 'search') === location.search) return;

    if (options.replace) {
      return this.replace(location);
    }

    return this.push(location);
  };

  buildHash = (hash, options = {}) => {
    const location = {
      ...this.location,
      hash,
    };

    return location;
  };

  setHash = (hash, options = {}) => {
    if (!this.rootStore.isClient) {
      return;
    }

    const location = this.buildHash(hash, options);

    if (options.replace) {
      return this.replace(location);
    }

    return this.push(location);
  };

  scrollToHash = hash => {
    setTimeout(() => {
      const elementId = (hash || this.location.hash).replace('#', '');
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 200);
  };
}
