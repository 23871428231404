import * as React from 'react';

const cn: any = require('classnames');

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';

import { Icon, IIcon } from './Icon';
import { Portal } from './Portal';

export interface IModal {
  show?: boolean;
  width?: string | number;
  className?: string;
  closeIcon?: IIcon;
  onDidShow?: () => void;
  onDidHide?: () => void;
  pinX?: boolean;
  offsetX?: number;
  pinY?: boolean;
  offsetY?: number;
  fullscreen?: boolean;
  renderContent: () => any;
}

export class Modal extends React.Component<IModal> {
  private _escapeListener: any;

  public componentWillMount() {
    const { show } = this.props;

    if (show) this._show(this.props);
  }

  public componentWillReceiveProps(nextProps: IModal) {
    const { show, onDidHide } = this.props;

    if (nextProps.show !== show) {
      if (nextProps.show) {
        this._show(nextProps);
      } else if (!nextProps.show && onDidHide) {
        this._hide(nextProps);
      }
    }
  }

  public componentWillUnmount() {
    const { onDidHide } = this.props;

    if (onDidHide) this._hide(this.props);
  }

  public render() {
    const {
      show,
      width = 900,
      className,
      renderContent,
      onDidHide,
      closeIcon,
      pinX,
      pinY,
      offsetX,
      offsetY,
      fullscreen,
    } = this.props;

    if (!show) return null;

    const containerStyle: any = { width, maxWidth: '90%' };

    if (pinX || pinY || fullscreen) {
      containerStyle.position = 'fixed';

      if (pinX) {
        containerStyle.left = containerStyle.right = offsetX || 30;
      }

      if (pinY) {
        containerStyle.top = containerStyle.bottom = offsetY || 30;
      }

      if (fullscreen) {
        containerStyle.top = containerStyle.bottom = containerStyle.left = containerStyle.right = 0;
        containerStyle.width = containerStyle.maxWidth = '100%';
      }
    }

    return (
      <Portal
        className={cn('z-50 fixed pin flex items-center justify-center w-full h-full py-12', {
          'overflow-auto': !fullscreen,
        })}
      >
        <div
          className={cn('z-1 fixed pin select-none', {
            'bg-black opacity-85': !fullscreen,
            'bg-grey-lightest': fullscreen,
          })}
          onClick={() => onDidHide && onDidHide()}
        />

        <div
          className={cn(
            'z-50 select-text relative',
            {
              'm-a bg-white shadow-dark rounded-lg': !fullscreen,
              'overflow-auto': fullscreen,
            },
            className
          )}
          style={containerStyle}
        >
          {onDidHide && (
            <div
              className={cn(
                'pin-t pin-r z-20 cursor-pointer flex items-center justify-center text-darken-300 hover:text-darken hover:bg-darken-50',
                {
                  'absolute w-12 h-12 text-xl': !fullscreen,
                  'fixed w-24 h-24 text-5xl': fullscreen,
                }
              )}
              onClick={onDidHide}
            >
              <Icon icon={closeIcon || faTimes} />
            </div>
          )}

          {renderContent()}
        </div>
      </Portal>
    );
  }

  private _show = (props: any = {}) => {
    if (!this._escapeListener && typeof document !== 'undefined') {
      this._escapeListener = document.addEventListener('keydown', this._handleEscape);
    }

    // if we are already showing, do nothing
    if (props.show) return;

    if (props.onDidShow) props.onDidShow();
  };

  private _hide = (props: any = {}) => {
    if (this._escapeListener && typeof document !== 'undefined') {
      document.removeEventListener('keydown', this._handleEscape);
      this._escapeListener = undefined;
    }

    // if we are not showing, do nothing
    if (!props.show) return;

    if (props.onDidHide) props.onDidHide();
  };

  private _handleEscape = (evt: any) => {
    evt = evt || window.event;

    let isEscape = evt.keyCode === 27;

    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    }

    if (isEscape) {
      this._hide(this.props);
    }
  };
}
