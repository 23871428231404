export const openAuthPopup = url => {
  const size = {
    width: Math.floor(window.outerWidth * 0.8),
    height: Math.floor(window.outerHeight * 0.5),
  };

  if (size.width < 1000) {
    size.width = 1000;
  }
  if (size.height < 644) {
    size.height = 644;
  }
  size.left = Math.floor(window.screenX + (window.outerWidth - size.width) / 2);
  size.top = Math.floor(window.screenY + (window.outerHeight - size.height) / 8);

  const options = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
      width=${size.width},height=${size.height},
      left=${size.left},top=${size.top}`;

  return window.open(url, 'Authorization', options);
};
