import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Search } from 'semantic-ui-react';

import FormInputLabel from '../FormInputLabel';

class SearchComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      results: props.results || [],
    };
  }

  componentWillMount() {
    this.filterResults(this.props.value);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.results, nextProps.results)) {
      this.setState({ results: nextProps.results });
    }
  }

  isCategory = () => {
    const { results } = this.props;

    return _.isArray(results) ? false : true;
  };

  filterResults = value => {
    const { filterResults, results } = this.props;

    if (filterResults) {
      if (_.isEmpty(value)) {
        this.setState({ results });
      } else {
        const re = new RegExp(_.escapeRegExp(value), 'i');

        if (this.isCategory()) {
          const newResults = {};

          _.forEach(results, (category, key) => {
            const categoryResults = _.filter(category.results, result => {
              return re.test(result.title) || re.test(result.value);
            });

            if (categoryResults.length) {
              newResults[key] = {
                ...category,
                results: categoryResults,
              };
            }
          });

          this.setState({ results: newResults });
        } else {
          this.setState({
            results: _.filter(results, result => {
              return re.test(result.title) || re.test(result.value);
            }),
          });
        }
      }
    }
  };

  onSearchChange = (e, { value }) => {
    const { onSearchChange } = this.props;

    if (onSearchChange) {
      onSearchChange(e, value);
    }

    this.filterResults(value);
  };

  onResultSelect = (e, data = {}) => {
    const { onResultSelect } = this.props;
    const { result } = data;

    if (onResultSelect) {
      onResultSelect(e, result);
    }

    this.filterResults();
  };

  render = () => {
    const {
      className,
      label,
      tip,
      required,
      filterResults,
      transparent,
      showResultsOnClick,
      inputProps = {},
      ...searchProps
    } = this.props;

    let results = this.props.results || [];

    if (filterResults) {
      results = this.state.results;
    }

    return (
      <div
        className={cn('FormSearch', {
          [className]: className,
          transparent,
        })}
      >
        <FormInputLabel label={label} tip={tip} required={required} />

        <Search
          label={label && typeof label !== 'string' ? label : null}
          minCharacters={0}
          showNoResults={false}
          category={this.isCategory(results)}
          onClick={() => {
            if (showResultsOnClick) {
              this.filterResults();
            }
          }}
          fluid
          {...searchProps}
          results={results}
          onSearchChange={this.onSearchChange}
          onResultSelect={this.onResultSelect}
          input={{
            type: 'text',
            className: 'fluid',
            ...inputProps,
          }}
        />
      </div>
    );
  };
}

export default SearchComponent;
