import _ from 'lodash';
import URL from 'url-parse';

import {
  buildParameters as buildParametersSwagger,
  buildHttpOperation as buildHttpOperationSwagger,
} from './http';

import {
  computePathParamsFromUri as computePathParamsFromUriSwagger,
  computeNewPath as computeNewPathSwagger,
  computeNewResource as computeNewResourceSwagger,
  getEndpointsFromSpec as getEndpointsFromSpecSwagger,
  computeUpdatesFromCollectionResult as computeUpdatesFromCollectionResultSwagger,
  getEndpointFromSpec as getEndpointFromSpecSwagger,
  createStepFromOperation as createStepFromOperationSwagger,
} from './swagger';

import { exportUrlToSrn } from '../entities';
import { hashToPath } from '../history';

export const parseDynamicParams = ({ url }) => {
  let parsed = url;
  let pathname;
  try {
    parsed = new URL(url);
    pathname = parsed.pathname;
  } catch (e) {
    pathname = url;
    return url;
  }

  if (!pathname) {
    return url;
  }

  let counter = 1;
  const parts = pathname.split('/');
  const newParts = [];
  for (const i in parts) {
    if (!Object.prototype.hasOwnProperty.call(parts, i)) {
      continue;
    }

    const isLast = !parts[i + 1];
    let part = parts[i];
    let newPart = part;
    let extension;

    const id = counter > 1 ? `{id${counter}}` : '{id}';

    if (!part.match(/@/) && isLast) {
      part = part.split('.');

      if (part.length > 1) {
        extension = _.last(part);
        part = part.slice(0, -1).join('.');
      } else {
        part = part[0];
      }
    }

    // all numbers
    if (part.match(/^[0-9]+$/)) {
      newPart = id;
    }

    // emails
    if (part.match(/@/)) {
      newPart = '{email}';
    }

    if (extension) {
      newPart = `${newPart}.{extension}`;
    }

    if (newPart !== part) {
      counter += 1;
    }

    newParts.push(newPart);
  }

  // put it back together

  if (!url) return null;

  return url.replace(pathname, newParts.join('/'));
};

// given something like '/users/{userId}', extract path params and build
// the parameters structure
export const computePathParamsFromUri = (uri, { type = 'swagger', existingParams } = {}) => {
  switch (type) {
    default:
      return computePathParamsFromUriSwagger(uri, { existingParams });
  }
};

// build the list of updates needed to
// patch a spec with the given path and methods
export const computeNewPath = (props, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return computeNewPathSwagger(props);
  }
};

// build the list of updates needed to
// patch a spec with a new resource (crud builder)
export const computeNewResource = (props, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return computeNewResourceSwagger(props);
  }
};

// given something like '/users/{userId}', extract path params and build
// the parameters structure
export const getEndpointsFromSpec = (spec, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return getEndpointsFromSpecSwagger(spec);
  }
};

export const computeUpdatesFromCollectionResult = ({ spec, result }, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return computeUpdatesFromCollectionResultSwagger({ spec, result });
  }
};

export const getEndpointFromSpec = (props, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return getEndpointFromSpecSwagger(props);
  }
};

export const createStepFromOperation = (props, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return createStepFromOperationSwagger(props);
  }
};

export const buildParameters = (props, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return buildParametersSwagger(props);
  }
};

export const buildHttpOperation = (props, { type = 'swagger' } = {}) => {
  switch (type) {
    default:
      return buildHttpOperationSwagger(props);
  }
};

export const cleanSpec = ({ spec = {} }) => {
  return spec;
};

export const defaultSpec = ({ title = 'My API' } = {}) =>
  `---
swagger: '2.0'
info:
  title: '${title}'
  version: '1.0'
host: 'example.com'
paths: {}
`;

export const resolveRef = ({ ref, parsed, specs = [] }) => {
  if (!ref) return null;

  if (_.startsWith(ref, '#')) {
    const path = hashToPath({ hash: ref });
    return _.get(parsed, path);
  }

  const srnWithHash = exportUrlToSrn({ url: ref });
  if (typeof srnWithHash !== 'string') return null;

  const parts = srnWithHash.split('#');
  const srn = _.replace(parts[0], /orgs\/|\/export.json|.json/g, '');
  const path = hashToPath({ hash: parts[1] });

  for (const spec of specs) {
    if (spec.id === srn) {
      return _.get(spec.data, path);
    }
  }

  return null;
};
