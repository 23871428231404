import JsonSchemaGenerator from 'json-schema-generator';
import _ from 'lodash';

import { safeStringify, safeParse } from '../json';

// Extends the base JSON schema with the extend. If extend is a plain object,
// it will turn it into a JSON schema before merging.
export const extendSchema = ({ base, extend, extendIsSchema } = {}) => {
  const stringSource = safeStringify(base);

  // do not extend if it is using $refs
  if (stringSource.match(/"\$ref"/g)) {
    return source;
  }

  const source = safeParse(base);
  let target = safeParse(extend);

  if (!extendIsSchema) {
    try {
      target = JsonSchemaGenerator(target);
    } catch (e) {
      return source;
    }
  }

  _.merge(source, target);

  return source;
};
