import _ from 'lodash';
import { routeData } from './routeData';
import { omitByRecursively } from '../general';

// takes in an object and recursively removes falsey values from arrays
export const compactDeep = ({ data } = {}) => {
  return omitByRecursively(data, (val, k, { isArray }) => {
    return (isArray && !val) || (_.isArray(val) && _.isEmpty(val));
  });
};

// expects results from routeData { ruleName: matched: [{ path: [], data: {} }] }
export const compose = ({ data } = {}) => {
  if (!data) {
    return {};
  }

  let matched = _.flatMap(data, rule => {
    return rule.matched;
  });
  matched = _.compact(matched);

  let paths = [];
  let values = [];

  _.forEach(matched, object => {
    paths.push(_.get(object, 'path', []));
    values.push(_.get(object, 'data', []));
  });

  const object = _.zipObjectDeep(paths, values);

  return compactDeep({ data: object });
};

// filters spec based on rules and then composes the results into a new json
export const filter = ({ spec = {}, rules = {} }, opts = {}) => {
  const { search = {} } = opts;

  return compose({
    data: routeData(
      { data: spec, rules },
      {
        exclusive: true,
        ...opts,
        search: {
          options: {
            shouldSort: false,
          },
          ...search,
        },
      }
    ),
  });
};
