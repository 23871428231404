import _ from 'lodash';

import { checkIsWhite } from '../colors';
import { routeData } from '../json/routeData';

export const findActivePage = (pages = [], path = '/') => {
  const page = _.find(pages, { path });

  if (!page) {
    const newPath = _.dropRight(_.split(path, '/'), 1).join('/');

    if (_.isEmpty(newPath)) {
      return;
    }

    return findActivePage(pages, newPath);
  }

  return page;
};

export const flattenChildren = (children = [], path = '') => {
  let flattenedChildren = [];

  for (const item of children) {
    if (!_.isEmpty(item.children)) {
      flattenedChildren = flattenedChildren.concat(flattenChildren(item.children, item.path));
    } else if (!_.isEmpty(item.path)) {
      flattenedChildren.push({
        ...item,
        path: `${path}${item.path}`,
      });
    }
  }

  return flattenedChildren;
};

export const findActiveSubpage = (children = [], path = '') => {
  return _.find(flattenChildren(children), { path }) || {};
};

export const findFirstSubpage = (children = []) => {
  const child = _.first(children) || {};

  if (child.children) {
    return findFirstSubpage(child.children);
  }

  return child;
};

export const getUrlPathFromJsonPath = (parsed = {}, path = [], urlPath = '') => {
  const newPath = `${_.get(parsed, path.concat(['route', 'path']), '')}${urlPath}`;

  if (path.length) {
    return getUrlPathFromJsonPath(parsed, _.dropRight(path, 3), newPath);
  }

  return newPath;
};

export const blockTypes = [
  {
    id: 'text',
    type: 'text',
    icon: 'align center',
    name: 'Text Block',
    description: 'Just a plain ole text block',
    data: {},
  },
  {
    id: 'jsonSchema',
    type: 'jsonSchema',
    src: '/images/bracket.png',
    name: 'JSON Schema Viewer',
    description: 'A good way to view a JSON schema',
    data: {},
  },
  {
    id: 'tryItOut',
    type: 'tryItOut',
    icon: 'send',
    name: 'Try It Out',
    description: 'Send an HTTP request',
    data: {},
  },
  {
    id: 'code',
    type: 'code',
    icon: 'code',
    name: 'Code',
    description: 'Code with syntax highlighting',
    data: {
      mode: 'json',
    },
  },
  {
    id: 'image',
    type: 'image',
    icon: 'image',
    name: 'Image',
    description: 'Not another cat gif',
    data: {},
  },
  {
    id: 'tabs',
    type: 'tabs',
    icon: 'columns',
    name: 'Tabs',
    description: 'More information in one spot',
    data: {
      children: [
        { id: 'tab-1', title: 'Tab 1', blocks: [] },
        { id: 'tab-2', title: 'Tab 2', blocks: [] },
      ],
    },
  },
  {
    id: 'operation',
    type: 'operation',
    icon: 'inbox',
    name: 'API Operation',
    description: 'Connect one of your API operations',
    data: {},
  },
  {
    id: 'ref',
    type: 'ref',
    icon: 'usb',
    name: 'Reference',
    description: 'Use one of your saved blocks',
    data: {},
  },
  {
    id: 'callout',
    type: 'callout',
    icon: 'announcement',
    name: 'Callout',
    description: 'A large header with title, description, and action',
  },
  {
    id: 'barList',
    type: 'barList',
    icon: 'list',
    name: 'Bar list',
    data: {
      items: [{}],
    },
  },
  {
    id: 'cardList',
    type: 'cardList',
    icon: 'block layout',
    name: 'Card list',
    data: {
      items: [{}],
    },
  },
  {
    id: 'splitText',
    type: 'splitText',
    icon: 'columns',
    name: 'Split text',
    description: 'A section with two text blocks that are side-by-side',
  },
  {
    id: 'custom',
    type: 'custom',
    icon: 'html5',
    name: 'Custom HTML',
    description: 'Use your own HTML',
  },
];

export const pageOptions = [
  {
    name: 'Entry',
    description: 'Most hubs will have at least one page with these layouts.',
    options: [
      {
        id: 'landing',
        name: 'Landing Page',
        src: '//cdn.stoplight.io/app/stoplight-help-landing-layout.png',
        unsetProps: ['html', 'children'],
      },
      {
        id: 'custom',
        name: 'Custom HTML',
        icon: 'html5',
        unsetProps: ['callout', 'children', 'sections'],
      },
    ],
  },
  {
    name: 'Documentation',
    description:
      'Build your page from scratch by defining many subpages. You can power parts of your page with your OAS specifications.',
    options: [
      {
        id: 'two-column-centered',
        name: 'Two Column Centered',
        src: '//cdn.stoplight.io/app/stoplight-help-small-layout.png',
        allowChildren: true,
        unsetProps: ['callout', 'html', 'sections'],
      },
      {
        id: 'two-column-full',
        name: 'Two Column Full',
        src: '//cdn.stoplight.io/app/stoplight-help-large-layout.png',
        allowChildren: true,
        disabled: 'Coming Soon',
        unsetProps: ['callout', 'html', 'sections'],
      },
      {
        id: 'three-column-full',
        name: 'Three Column Full',
        src: '//cdn.stoplight.io/app/stoplight-help-3-column-layout.png',
        allowChildren: true,
        disabled: 'Coming Soon',
        unsetProps: ['callout', 'html', 'sections'],
      },
    ],
  },
  {
    name: 'API Reference',
    description:
      'Render the page from your OAS specification. This option is less flexbile, but faster to implement.',
    options: [
      {
        id: 'two-column-centered-ref',
        name: 'Two Column Centered',
        src: '//cdn.stoplight.io/app/stoplight-help-small-layout.png',
        // allowApiSpec: true,
        allowRef: true,
        unsetProps: ['callout', 'html', 'sections', 'children'],
      },
    ],
  },
];
let _flatPageOptions = [];
_.forEach(pageOptions, po => {
  _flatPageOptions = _flatPageOptions.concat(po.options);
});
export const flatPageOptions = _flatPageOptions;

export const subpageOptions = [
  {
    options: [
      {
        id: 'page',
        name: 'Custom Page',
        icon: 'file text',
        description: 'A page contains blocks.',
      },
      // { id: 'http', name: 'HTTP Operation', icon: 'inbox' },
      {
        id: 'group',
        name: 'Group',
        icon: 'folder open',
        description: 'A group has pages nested under it.',
      },
      {
        id: 'divider',
        name: 'Divider',
        icon: 'minus',
        description: 'A divider does not have a link, blocks, or nested pages.',
      },
      {
        id: 'link',
        name: 'External Link',
        icon: 'linkify',
        description: 'A link points to another page or an external website.',
      },
    ],
  },
];
let _flatSubpageOptions = [];
_.forEach(subpageOptions, po => {
  _flatSubpageOptions = _flatSubpageOptions.concat(po.options);
});
export const flatSubpageOptions = _flatSubpageOptions;

export const defaultHub = ({ title = 'My Hub' } = {}) => ({});

const hubRefRouterRules = {
  pages: {
    recursive: true,
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'pages' && key === 'data';
    },
    dataFactory({ spec, parentPath }) {
      let viewPath = '?view=' + `/${_.drop(parentPath)[0]}`;
      return {
        name: _.get(spec, parentPath.concat(['title'])),
        // remove 'pages'
        viewPath,
      };
    },
  },
  subpages: {
    recursive: true,
    circular: true,
    matcher({ parentPath = [], key }) {
      return (
        _.last(_.dropRight(parentPath)) === 'children' &&
        key === 'data' &&
        _.countBy(parentPath, o => o === 'children').true <= 3
      );
    },

    dataFactory({ spec, parentPath }) {
      let viewPath = [parentPath[1]];
      _.forEach(parentPath, (currentPath, index) => {
        if (_.isFinite(currentPath)) {
          const route = _.get(spec, _.slice(parentPath, 0, index + 1).concat(['route', 'path']));
          viewPath = _.concat(viewPath, route);
        }
      });

      viewPath = '?view=' + `/${_.join(viewPath, '')}`;

      return { name: _.get(spec, parentPath.concat(['title'])), viewPath };
    },
  },
};

export const hubRefRouter = ({ spec = {}, targets = [] } = {}) => {
  const rules = _.isEmpty(targets) ? hubRefRouterRules : _.pick(hubRefRouterRules, targets);
  return routeData({ data: spec, rules });
};

export const defaultHubTheme = {
  primary: '#3B99FC',
  secondary: '#38c172',
  background: '#ffffff',
  texture: '',
};

export const computeHubThemeStyles = theme => {
  // (CL) TODO: Should we include css?
  // (CL) TODO: Clean up the theme classes
  const { primary, secondary, background, texture } = theme || {};

  let computedTheme = '';

  if (texture) {
    computedTheme += `
      .HubHeader {
        background-image: url(${texture}) !important;
      }
    `;
  }

  if (background) {
    computedTheme += `
      .HubHeader {
        background-color: ${background} !important;
      }
    `;
  }

  const isBackgroundDark = !checkIsWhite(background || '#ffffff');
  const headerTextColor = isBackgroundDark ? '#ffffff' : undefined;

  if (headerTextColor) {
    computedTheme += `
    .HubHeader .HubHeaderItem {
      color: ${headerTextColor};
    }
    `;
  }

  if (primary) {
    if (!isBackgroundDark) {
      computedTheme += `
        .HubHeaderItem:hover,
        .HubHeaderItem.is-active {
          color: ${primary};
        }
      `;
    }

    computedTheme += `
      .HubBlock-tab.active {
        border-color: ${primary} !important;
      }

      .markdown-body a {
        color: ${primary};
      }

      .HubMain .text-active,
      .HubMain .hover\\:text-active:hover,
      .HubMain .HubBlock-tab:hover {
        color: ${primary} !important;
      }
    `;
  }

  if (secondary) {
    computedTheme += `
      .HubMain .ui.secondary.button {
        background-color: ${secondary};
      }
    `;
  }

  return computedTheme;
};

// TODO: Download these and store somewhere
export const textures = [
  '',
  'https://www.transparenttextures.com/patterns/black-lozenge.png',
  'https://www.transparenttextures.com/patterns/45-degree-fabric-dark.png',
  'https://www.transparenttextures.com/patterns/inspiration-geometry.png',
  'https://www.transparenttextures.com/patterns/batthern.png',
  'https://www.transparenttextures.com/patterns/cubes.png',
  'https://www.transparenttextures.com/patterns/60-lines.png',
  'https://www.transparenttextures.com/patterns/gplay.png',
  'https://www.transparenttextures.com/patterns/noise-pattern-with-subtle-cross-lines.png',
];

export const textColors = [
  '#3B99FC', // primary && active - blue
  '#38c172', // secondary - green
  '#BB27DD', // accent - purple
  '#e3342f', // red
  '#f6993f', // orange
  '#6574cd', // indigo
  '#651fff', // purple/blue
  '#673AB7', // purple
];

export const backgroundColors = [
  '#ffffff', // white
  '#2d3436', // dracula orchard (dark grey)
  '#130F21', // vulcan (stoplight black)
  '#0984e3', // electron blue (blue)
  '#6c5ce7', // exodus fruit (purple)
  '#00b894', // mint leaf (green)
  '#F97F51', // rich gardenia (organce)
  '#6D214F', // magenta purple
];
