import * as React from 'react';

import { themes } from './enums';
import { ListScroller } from './ListScroller';
import { IPanelListItem, PanelListItem } from './PanelListItem';
import { IPopupPosition } from './Popup';

export interface IPanelList {
  items: IPanelListItem[];
  theme?: themes;
  className?: string;
  emptyMsg?: string;
  scrollToIndex?: number;
  popup?: IPopupPosition;
  onClick?: (item: IPanelListItem) => void;
}

export class PanelList extends React.Component<IPanelList, {}> {
  private _rowHeight = 45;

  public render() {
    const { items, className, scrollToIndex } = this.props;

    return (
      <ListScroller
        className={className}
        rowCount={items.length}
        rowHeight={this._rowHeight}
        noRowsRenderer={this._renderEmptyMsg}
        rowRenderer={this._renderItem}
        scrollToIndex={scrollToIndex}
      />
    );
  }

  private _renderEmptyMsg = () => {
    const { emptyMsg = 'Nothing to show here.' } = this.props;
    return <div className="ta-c py-4 px-4 text-muted-light">{emptyMsg}</div>;
  };

  private _renderItem = ({ key, index, style }: { key: string; index: number; style: any }) => {
    const { items, onClick, popup = {}, theme = themes.dark } = this.props;

    const item = items[index] || {};

    return (
      <div key={key} style={style}>
        <PanelListItem onClick={onClick} popup={popup} theme={theme} {...item} />
      </div>
    );
  };
}
