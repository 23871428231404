import * as React from 'react';

import { themes } from './enums';
import { IPopupPosition, Popup } from './Popup';

const cn: any = require('classnames');

export interface IPanelListItem {
  name: any;
  className?: string;
  badge?: string;
  isActive?: boolean;
  meta?: string[];
  details?: any;
  theme?: themes;
  popup?: IPopupPosition;
  data?: any; // arbitrary data to store and pass through to onClick
  onClick?: (item: IPanelListItem) => void;
}

export interface IPanelListItemState {
  isContextOpen?: boolean;
}
export class PanelListItem extends React.Component<IPanelListItem, IPanelListItemState> {
  private _rowHeight = 45;

  constructor(props: IPanelListItem) {
    super(props);

    this.state = { isContextOpen: false };
  }

  public render() {
    const { isContextOpen } = this.state;

    const {
      name,
      badge,
      details,
      meta,
      popup = {},
      isActive,
      onClick,
      theme = themes.dark,
    } = this.props;

    return (
      <Popup
        posX={popup.posX || 'right'}
        posY={popup.posY || 'center'}
        hideDelay={0}
        renderTrigger={(attributes, props) => (
          <div
            {...attributes}
            style={{ height: this._rowHeight }}
            className={cn('PanelList-trigger flex items-center text-sm px-4 opacity-85', {
              'text-white': theme === themes.dark,
              'bg-dark-active': isActive && !isContextOpen,
              'hover:bg-darken-50': onClick && !isActive && theme === themes.light,
              'hover:bg-lighten-50': onClick && !isActive && theme === themes.dark,
              'bg-darken-50': isContextOpen && theme === themes.light,
              'bg-lighten-50': isContextOpen && theme === themes.dark,
              'cursor-pointer': onClick && !isActive,
            })}
            title={typeof name === 'string' ? name : ''}
            onClick={
              onClick
                ? () => {
                    props.hidePopup();
                    onClick(this.props);
                  }
                : undefined
            }
          >
            <div className="flex flex-col flex-1 w-100">
              <div className="truncate">
                {badge && <span className={cn('font-extrabold')}>{badge}</span>} {name}
              </div>
              {meta && (
                <div
                  className={cn('text-xs truncate', {
                    'text-darken-600': theme === themes.light,
                    'text-lighten-600': theme === themes.dark,
                  })}
                >
                  {meta.join(' · ')}
                </div>
              )}
            </div>
          </div>
        )}
        renderContent={() => {
          // TODO: support item.details being markdown
          return (
            details && (
              <div
                className="bg-white rounded shadow py-3 px-6 overflow-auto"
                style={{ maxHeight: 400, maxWidth: 500 }}
              >
                {details}
              </div>
            )
          );
        }}
      />
    );
  }
}
