import _ from 'lodash';
import { action, computed, observable, reaction } from 'mobx';

import { isExternalLink } from '@platform/utils/url';
import { buildViewPath, replaceBlockVariables } from '@platform/format-hubs/utils/viewer';

class Page {
  page = {};
  crumbs = [];
  sidebarTree = [];
  basePath = '';
  version = '';
  namespace = {};

  @observable
  isActive = false;

  constructor(props) {
    const {
      id,
      page,
      crumbs,
      config,
      version,
      versions,
      basePath,
      sidebarTree,
      routerStore,
      whitelabel = false,
      rootStore,
      namespace,
    } = props;

    this.id = id;
    this.page = page;
    this.crumbs = crumbs || [];
    this.config = config || {};
    this.sidebarTree = sidebarTree || [];
    this.routerStore = routerStore;
    this.whitelabel = whitelabel;
    this.version = version;
    this.versions = versions || [];
    this.rootStore = rootStore;
    this.namespace = namespace;

    if (basePath) {
      this.basePath = basePath;
    }
  }

  @computed
  get currentEnv() {
    return this.rootStore.debouncedVariables || {};
  }

  get hasSidebar() {
    return !!this.sidebarTree.length;
  }

  get activePage() {
    return {
      parsedPath: this.currentParsedPath,
      treePath: this.currentParsedPath,
      data: this.page,
    };
  }

  get activeSubpage() {
    return this.activePage;
  }

  @computed
  get activeBlocks() {
    const page = this.activeSubpage.data || this.activePage.data || {};
    const { blocks } = page.data ? _.cloneDeep(page.data) : {};

    return _.map(blocks, block => replaceBlockVariables({ block, env: this.currentEnv }));
  }

  get currentPath() {
    return _.compact(_.split(this.page.path, '/'));
  }

  get currentRelativePath() {
    return _.trimStart(this.page.path, this.page.rootPagePath);
  }

  get currentParsedPath() {
    return this.page.parsedPath;
  }

  buildViewPath = (path = '') => {
    if (isExternalLink(path) || _.first(path) === '#') {
      return path;
    }

    let version = this.version;
    const isDefaultVersion = _.find(this.versions, { name: version, isDefault: true });
    if (!this.versions || this.versions.length < 2 || isDefaultVersion) {
      version = '';
    }

    return buildViewPath({ path, version, basePath: this.basePath });
  };

  checkSidebarItemIsActive = item => {
    return this.page.path === item.path;
  };

  checkSidebarChildIsActive = folder => {
    if (!folder) return;

    // Check to see if our current path starts with the folder's path
    let reg = new RegExp(`^${folder.path}`);

    return reg.test(this.page.path);
  };

  goToNestedPath(path) {
    const location = _.get(this.routerStore, 'location') || {};

    if (path !== location.pathname) {
      this.routerStore.replace(path);
    }
  }

  @action
  activate = () => {
    this.isActive = true;
  };

  @action
  deactivate = () => {
    this.isActive = false;
  };
}

export default class PageStore {
  rootStore = {};

  @observable.ref
  _instances = {};

  constructor({ rootStore }) {
    this.rootStore = rootStore;
  }

  @computed
  get instances() {
    return _.values(this._instances);
  }

  @computed
  get current() {
    return _.find(this.instances, { isActive: true });
  }

  @action
  add = opts => {
    const page = new Page(opts);
    return (this._instances[page.id] = page);
  };

  deactivateAll = () => {
    for (const path in this._instances) {
      if (this._instances[path].isActive) {
        this._instances[path].deactivate();
      }
    }
  };

  lookup = (id, opts) => {
    const page = this._instances[id];
    return page || !opts ? page : this.add({ ...opts, id, rootStore: this.rootStore });
  };
}
