import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { Link } from '@platform/utils/router';
import { isExternalLink } from '@platform/utils/url';

const HubHeaderItem = props => {
  const { item = {}, to, isActive, className, style = {}, isEditor, isDark } = props;
  let { title, path, image } = item;

  // Don't render empty items
  if (!title && !image) return null;

  const componentProps = {
    className: cn(
      className,
      'HubHeaderItem flex items-center text-lg mx-1 px-4 py-2 rounded font-bold',
      {
        'with-image': image,
        'without-image': !image,
        'is-active': isActive,
        'is-dark text-white': isDark,
        'is-light hover:text-primary': !isDark,
        'text-black': !isActive && !isDark,
        'text-primary': isActive && !isDark,
        'bg-lighten-100': isActive && isDark && !image,
      }
    ),
    style,
  };

  let contentElem = [];

  if (image) {
    contentElem.push(
      <div key="i" className="HubHeaderItem-image flex items-center">
        <img src={image} alt={isEditor ? '<IMAGE NOT FOUND>' : title || 'logo'} />{' '}
        {title && <div className="ml-4">{title}</div>}
      </div>
    );
  } else {
    contentElem.push(
      <div key="t" className="HubHeaderItem-name">
        {title}
      </div>
    );
  }

  if (_.isEmpty(path)) {
    return <div {...componentProps}>{contentElem}</div>;
  }

  componentProps.to = to || path;
  const isExternal = isExternalLink(componentProps.to);

  if (isExternal && isEditor) {
    componentProps.onClick = e => e.preventDefault();
  }

  return <Link {...componentProps}>{contentElem}</Link>;
};

export default HubHeaderItem;
