if (typeof window !== 'undefined') {
  require('autotrack/autotrack');
}

import { getConfigVar } from '../config';

const track = (
  { name = 'slTracker', eventCategory, eventAction, eventLabel, eventValue },
  opts = {}
) => {
  const key = opts.key || getConfigVar('GA_KEY');
  const GoogleAnalytics = sdk(key);

  if (key && GoogleAnalytics) {
    GoogleAnalytics(`${name}.send`, {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    });
  }
};

const init = (_key, name = 'slTracker') => {
  const key = _key || getConfigVar('GA_KEY');
  const GoogleAnalytics = sdk(key);

  if (key && GoogleAnalytics) {
    GoogleAnalytics('create', key, 'auto', name);
    GoogleAnalytics(`${name}.require`, 'cleanUrlTracker');
    GoogleAnalytics(`${name}.require`, 'eventTracker');
    GoogleAnalytics(`${name}.require`, 'outboundLinkTracker');
    GoogleAnalytics(`${name}.require`, 'pageVisibilityTracker');
    GoogleAnalytics(`${name}.require`, 'urlChangeTracker');
    GoogleAnalytics(`${name}.send`, 'pageview');
  }
};

const script = _key => {
  let script = '';

  const key = _key || getConfigVar('GA_KEY');

  if (key) {
    script = `
      var script = document.createElement('script');
      script.src = 'https://www.google-analytics.com/analytics.js';
      document.head.appendChild(script);
      window.ga = window.ga || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date;
    `;
  }

  return script;
};

const sdk = _key => {
  const key = _key || getConfigVar('GA_KEY');

  if (key && typeof window !== 'undefined' && window.ga) {
    return window.ga;
  }
};

export default {
  track,
  init,
  script,
  sdk,
};
