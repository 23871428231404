import _ from 'lodash';

import shortid from '../shortid';
import { getConfigVar } from '../config';

export const createUniqueId = (ids = [], { length = 5 } = {}) => {
  let id = '';

  do {
    id = shortid({ length, lowercase: true });
  } while (_.includes(ids, id));

  return id;
};

export const simpleSlugify = input => {
  let str = input;

  if (!str || typeof str !== 'string') {
    return '';
  }

  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const fromAccents = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const toAccents = 'aaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = fromAccents.length; i < l; i++) {
    str = str.replace(new RegExp(fromAccents.charAt(i), 'g'), toAccents.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-'); // remove invalid chars // collapse whitespace and replace by - // collapse dashes

  return _.trim(str, '-/');
};

export const uniqueSlugify = ({ input = '', existing = [] }) => {
  let sluggified = input;

  if (_.isEmpty(sluggified)) {
    sluggified = createUniqueId(sluggified);
  } else {
    sluggified = simpleSlugify(sluggified);
  }

  if (_.isEmpty(existing)) {
    return sluggified;
  }

  let count = 1;
  let output = sluggified;

  while (_.includes(existing, output)) {
    output = `${sluggified}-${count}`;
    count++;
  }

  return output;
};

export const combinePathSelectors = (parts = []) => {
  let path = '';
  for (const p of parts) {
    const part = p.trim();
    if (part) {
      if (path === '') {
        path = part;
      } else if (part.charAt(0) === '[') {
        path = `${path}${part}`;
      } else {
        path = `${path}.${part}`;
      }
    }
  }
  return path;
};

export const isValidJSON = target => {
  try {
    let t;
    if (typeof target === 'object') {
      t = JSON.stringify(target);
    }
    JSON.parse(t);
    return true;
  } catch (e) {
    return false;
  }
};

// This reduces arrays in the body to one element (their first element).
// Primarily useful for trimming down large responses, and generating
// examples.
export const pruneObject = (body, level = 0) => {
  if (body instanceof Array) {
    if (body.length) {
      return [pruneObject(body[0], level + 1)];
    }

    return [];
  } else if (body instanceof Object) {
    for (const p in body) {
      if (!Object.prototype.hasOwnProperty.call(body, p)) {
        continue;
      }

      body[p] = pruneObject(body[p], level + 1);
    }
  } else if (level === 0) {
    try {
      body = JSON.parse(body);
      body = JSON.stringify(pruneObject(body, level + 1));
    } catch (e) {}
  }

  return body;
};

export const sortObjectKeys = (obj, keys) => {
  const targetObj = obj || {};
  const newObj = {};

  let targetKeys = keys;
  if (!targetKeys) {
    targetKeys = Object.keys(obj).sort();
  }

  for (const k of targetKeys) {
    newObj[k] = targetObj[k];
  }

  const remainingKeys = _.without(Object.keys(targetObj), ...targetKeys);
  for (const k of remainingKeys) {
    newObj[k] = targetObj[k];
  }

  return newObj;
};

// renames a key while trying to preserve key ordering
export const renameObjectKey = (obj, oldKey, newKey) => {
  if (!obj || !obj[oldKey] || oldKey === newKey) {
    return obj;
  }

  const vals = [];
  for (const k in obj) {
    if (k !== newKey) {
      vals.push({
        name: k === oldKey ? newKey : k,
        value: obj[k],
      });
    }
  }

  const newObj = {};
  for (const k of vals) {
    newObj[k.name] = k.value;
  }

  return newObj;
};

// TODO: this should not mutate. either clone deep or do something else. When make this change, need to test all the places we call this.
// omit should be a function that takes a value and key, and returns true if value should be omitted
// omitByRecursively({}, (val, key) => {
//   return _.isNil(val) || key === 'id';
// })
export const omitByRecursively = (obj, omit) => {
  const isArray = obj instanceof Array;

  let cleanObj = isArray ? [] : {};

  for (const k in obj) {
    let val = obj[k];
    if (!omit(val, k, { isArray })) {
      if (typeof val === 'object') {
        val = omitByRecursively(val, omit);
      }

      if (isArray) {
        cleanObj.push(val);
      } else {
        cleanObj[k] = val;
      }
    }
  }

  return cleanObj;
};

// Given array like ['foo', 'bar'], will return a sorter function that will order object
// keys with foo before bar
export const keyArrangeSortByArray = valueArray => {
  return (a, b) => {
    const aIndex = _.indexOf(valueArray, a);
    const bIndex = _.indexOf(valueArray, b);
    let s;

    if (aIndex === bIndex) {
      if (a > b) {
        s = 1;
      } else if (a < b) {
        s = -1;
      } else {
        s = 0;
      }
    } else if (bIndex === -1 || (aIndex !== -1 && aIndex < bIndex)) {
      s = -1;
    } else {
      s = 1;
    }

    return s;
  };
};

export const keyArrange = (obj, sorterFunc, currentPath) => {
  const out = {};

  Object.keys(obj)
    .sort(
      sorterFunc
        ? (a, b) => {
            return sorterFunc(a, b, currentPath);
          }
        : undefined
    )
    .forEach(k => {
      out[k] = obj[k];
    });

  return out;
};
export const keyArrangeDeep = (obj, sorterFunc, maxDepth, currentPath, currentDepth) => {
  if (maxDepth && currentDepth && currentDepth >= maxDepth) {
    return obj;
  }

  if (_.isArray(obj)) {
    return _.map(obj, item => {
      return keyArrangeDeep(item, sorterFunc, currentPath);
    });
  } else if (_.isObject(obj)) {
    const newObj = keyArrange(obj, sorterFunc, currentPath);

    _.forOwn(newObj, (val, key) => {
      newObj[key] = keyArrangeDeep(val, sorterFunc, currentPath, currentDepth + 1);
    });

    return newObj;
  }

  return obj;
};

/**
 * Combines two paths using lodash's toPath function, concat, and compact
 * For example combinePaths([1], '2.3.') = [1, 2, 3]
 *
 * @param  {Array | String}  initial   The beginning of the path
 * @param  {Array | String}  extension The end of the path
 * @return {Array}
 */
export const combinePaths = (initial = [], extension = []) => {
  return _.compact(_.concat(_.toPath(initial), _.toPath(extension)));
};

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Missing arguments error class.
 * @param {string} message - error message.
 * @constructor
 */
function MissingArgumentError(message) {
  this.name = 'MissingArgumentError';
  this.message = message || 'Argument is missing.';
  this.stack = new Error().stack;
}

/**
 * Checks if all arguments present in a method call.
 * @throws {MissingArgumentError} - will throw an error if some arguments are missing.
 * @param {string} methodName - name of method to check.
 * @param {object} args - method arguments.
 * @param {bool} throwError - if true, will throw the error instead of logging.
 */
export const checkArgs = (methodName, args = {}, throwError) => {
  if (getConfigVar('ENV') === 'production') {
    return;
  }

  for (const argName in args) {
    if (args.hasOwnProperty(argName) && _.isUndefined(args[argName])) {
      const error = new MissingArgumentError(
        `You must specify <${argName}> when calling the ${methodName} method.`
      );

      if (throwError) {
        throw error;
      } else {
        console.error(error);
      }
    }
  }
};

/**
 * Method for escaping strings to pass them in regex constructor.
 * Source: https://github.com/benjamingr/RegExp.escape
 * @param {string} s - string to escape.
 * @return {string} - escaped string.
 */
export const escapeRegExp = s => String(s).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
