import React from 'react';
import cn from 'classnames';
import FormInputTip from '../FormInputTip';

const FormInputLabel = props => {
  const { className, label, required, tip, inverted, inline, style } = props;

  if (!label || typeof label !== 'string') {
    return null;
  }

  return (
    <div
      className={cn('FormInputLabel flex', className, {
        'is-inverted': inverted,
        'is-inline': inline,
      })}
      style={style}
    >
      {required && <div className="FormInputLabel--required">*</div>}
      {label && <div className="FormInputLabel--text">{label}</div>}
      {tip && <FormInputTip tip={tip} />}
    </div>
  );
};

export default FormInputLabel;
