import * as React from 'react';

const cn: any = require('classnames');

import { IStringObject } from '@core/types';

import { paddingXMap, paddingYMap } from './classNames';
import { colors, defaultColor, defaultSize, sizes } from './enums';
import { divClickHandler } from './events';
import { Icon, IIcon } from './Icon';

export interface ICard {
  className?: string;
  title?: string;
  titleMeta?: string;
  subtitleLeft?: string;
  subtitleRight?: string;
  items?: any[];
  color?: colors;
  size?: sizes;
  loading?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  fluid?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  icon?: IIcon;
  iconProps?: IStringObject<any>;
  onClick?: divClickHandler;
}

export const Card = ({
  className,
  icon,
  iconProps = {},
  title,
  titleMeta,
  subtitleLeft,
  subtitleRight,
  items = [],
  color = defaultColor,
  size = defaultSize,
  loading,
  disabled,
  fluid,
  rounded,
  shadow,
  onClick,
}: ICard) => {
  const colorVal = colors[color];
  const enabled = !disabled && !loading;
  // const sizeVal = sizes[size];

  const textSize = cn({
    'text-md': size < sizes.md,
    'text-lg': size === sizes.md,
    'text-xl': size > sizes.md,
  });

  const smallerTextSize = cn({
    'text-xs': size < sizes.md,
    'text-sm': size === sizes.md,
    'text-md': size > sizes.md,
  });

  let contentElem;
  if (items.length === 1) {
    contentElem = <div className={`pt-${paddingYMap[size]} ${smallerTextSize}`}>{items[0]}</div>;
  } else if (items.length) {
    contentElem = (
      <ul className={`ml-1 pl-4 pt-${paddingYMap[size]} ${smallerTextSize}`}>
        {items.map((i, k) => (
          <li key={k}>{i}</li>
        ))}
      </ul>
    );
  }

  const clickHandler =
    enabled && onClick
      ? (e: any) => {
          onClick(e);
        }
      : undefined;

  return (
    <div
      className={cn(
        className,
        'rounded border',
        `px-${paddingXMap[size]}`,
        `py-${paddingYMap[size]}`,
        `border-${colorVal}-light`,
        {
          'w-100': fluid,
          'rounded-full': rounded,
          shadow,
          'cursor-pointer': clickHandler,
          [`bg-${colorVal}-lightest`]: color !== defaultColor,
          [`hover:border-${colorVal}`]: clickHandler,
        }
      )}
      onClick={clickHandler}
    >
      <div className={cn('flex font-bold items-center', textSize)}>
        {icon && (
          <div className="mr-2">
            <Icon icon={icon} className="text-grey-dark" {...iconProps} />
          </div>
        )}

        <div className="flex-1 mr-4">{title}</div>

        {titleMeta && <div>{titleMeta}</div>}
      </div>

      <div className={cn('flex text-muted', smallerTextSize)}>
        <div className="flex-1 mr-6">{subtitleLeft}</div>
        <div>{subtitleRight}</div>
      </div>

      {contentElem}
    </div>
  );
};
