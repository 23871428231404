import _ from 'lodash';
import pluralize from 'pluralize';

import { generateOperationId } from './http';

import { omitByRecursively } from '../general';
import { safeParse } from '../json';
import { filter } from '../json/compose';
import { replaceVariables } from '../variables';
import { collectionResultToHar, harToSwagger2 } from '../har';
import { buildExampleFromSchema, dereferenceSchema } from '../schemas';
import { createURL, cleanSlashes, buildPathParts } from '../url';
import { newStep } from '../collections';
import { pathToHash } from '../history';

export const parameterTypes = ['path', 'query', 'header', 'body', 'formData'];
export const rootParameterTypes = ['path', 'query', 'header', 'body', 'formData'];

export const buildEndpointFromOperation = props => {
  const {
    swagger,
    method,
    path,
    operation = {},
    specId,
    specName,
    $ref,
    specUrl,
    hashPath,
  } = props;

  const { summary, operationId, responses } = operation;

  const scheme = _.get(swagger, 'schemes[0]', 'http');
  const schemeReg = new RegExp(`^${scheme}://`);
  let host = swagger.host || '{$$.env.host}';
  if (!schemeReg.test(host)) {
    host = `${scheme}://${host}`;
  }

  return {
    $ref,
    specUrl,
    hashPath,
    specName,
    specId,
    method,
    path,
    url: cleanSlashes(`${host}/${swagger.basePath || ''}/${path}`),
    summary,
    operationId: operationId || generateOperationId({ method, path }),
    responses: Object.keys(responses || {}),
  };
};

export const getResponseSchemaFromOperation = ({ spec, method, path, code }) => {
  const swagger = safeParse(_.get(spec, 'data'));

  if (_.isEmpty(swagger)) {
    return;
  }

  const response = _.get(swagger, ['paths', path, _.toLower(method), 'responses', code], {});

  return dereferenceSchema(response.schema || response, swagger);
};

export const createStepFromOperation = props => {
  const {
    spec,
    url,
    host,
    basePath,
    method,
    path,
    code,
    pathParamsToCtx = false,
    paramsToCtx = false,
  } = props;

  const swagger = safeParse(_.get(spec, 'data', spec));

  if (_.isEmpty(swagger)) {
    return;
  }

  const operation = _.get(swagger, ['paths', path, _.toLower(method)]);
  if (!operation) {
    return;
  }

  const step = {
    input: {
      method: _.toLower(method),
      url: cleanSlashes(url || `${host || `{$$.env.host}`}/${basePath || ''}/${path || ''}`),
    },
  };

  const name = operation.summary || operation.operationId || generateOperationId({ method, path });

  if (!_.isEmpty(name)) {
    step.name = name;
  }

  let body;
  const headers = {};
  const query = {};
  const pathParams = {};

  const parameters = _.concat(
    _.get(swagger, ['paths', path, 'parameters'], []),
    _.get(operation, 'parameters', [])
  );

  for (const param of parameters) {
    switch (param.in) {
      case 'body':
        body = _.get(param, ['x-examples', _.first(_.keys(param['x-examples']))]);

        if (_.isEmpty(body)) {
          body = buildExampleFromSchema(param.schema, {
            definitions: swagger.definitions,
          });
        }
        break;
      case 'query':
        query[param.name] = param.default || (paramsToCtx && `{$.ctx.${param.name}}`) || '';
        break;
      case 'header':
        headers[param.name] = param.default || (paramsToCtx && `{$.ctx.${param.name}}`) || '';
        break;
      case 'path':
        pathParams[param.name] = param.default || (paramsToCtx && `{$.ctx.${param.name}}`) || '';
        break;
      default:
        break;
    }
  }

  if (body) {
    step.input.body = body;
    headers['Content-Type'] =
      _.get(operation, 'produces[0]') || _.get(swagger, 'produces[0]') || 'application/json';
  }

  // we want to cycle through operation security before swagger security
  const securityArray = _.concat(operation.security || [], swagger.security || []);

  if (!_.isEmpty(securityArray)) {
    let securityDefinition;

    for (const security of securityArray) {
      if (securityDefinition) {
        continue;
      }

      for (const key in security) {
        if (securityDefinition) {
          continue;
        }
        securityDefinition = _.get(swagger, ['securityDefinitions', key]);
      }
    }

    let specVarPrefix = _.trimStart(_.camelCase(spec.path), '-');
    if (specVarPrefix) {
      specVarPrefix = `${specVarPrefix}-`;
    }

    if (_.get(securityDefinition, 'type') === 'apiKey') {
      const securityDefinitionName =
        securityDefinition.default || `{$$.env.${specVarPrefix}${securityDefinition.name}}`;

      switch (securityDefinition.in) {
        case 'header':
          headers[securityDefinition.name] = securityDefinitionName;
          break;
        case 'query':
          query[securityDefinition.name] = securityDefinitionName;
          break;
        default:
          break;
      }
    } else if (_.get(securityDefinition, 'type') === 'basic') {
      step.input.auth = {
        type: 'basic',
        username: `{$$.env.${specVarPrefix}username}`,
        password: `{$$.env.${specVarPrefix}password}`,
      };
    } else if (_.get(securityDefinition, 'type') === 'oauth2') {
      step.input.auth = {
        type: 'oauth2',
      };
    }
  }

  if (!_.isEmpty(headers)) {
    step.input.headers = headers;
  }

  if (!_.isEmpty(query)) {
    step.input.query = query;
  }

  if (!_.isEmpty(pathParams)) {
    step.input.params = pathParams;
  }

  if (code) {
    step.after = {
      assertions: [
        {
          target: 'output.status',
          op: 'eq',
          expected: Number(code),
        },
      ],
    };
  }

  if (pathParamsToCtx) {
    step.input.url = step.input.url.replace(/{([a-zA-Z0-9-_]*)}/g, '{$.ctx.$1}');
  }

  return newStep(step);
};

// method is optional, if no method, will just find path
export const getEndpointFromSpec = ({ spec, method, url, variables }) => {
  const swagger = safeParse(_.get(spec, 'data'));

  if (_.isEmpty(swagger)) {
    return;
  }

  // ignore any host variables at the beginning of the url
  let parsedPath = _.replace(url, new RegExp('^{[^/]+}/'), '/');

  // replace any path variables
  parsedPath = replaceVariables(parsedPath, variables);

  // ignore query string
  parsedPath = _.split(parsedPath, '?')[0] || '';
  parsedPath = createURL(parsedPath).pathname || parsedPath;

  parsedPath = cleanSlashes(parsedPath);
  if (swagger.basePath) {
    parsedPath = parsedPath.replace(swagger.basePath, '');
  }

  if (_.isEmpty(parsedPath)) {
    parsedPath = '/';
  } else {
    // replace these characters - they are the {dynamicParam} ones
    parsedPath = parsedPath.replace(/\%7B/gi, '{').replace(/\%7D/gi, '}');
  }

  const requestPathParts = buildPathParts(parsedPath);
  let endpoint;
  let endpointExactScore = -1;

  for (const path in swagger.paths) {
    if (!Object.prototype.hasOwnProperty.call(swagger.paths, path)) {
      continue;
    }

    const specPath = ['paths', path, _.toLower(method)];

    const operation = method ? _.get(swagger, specPath) : {};
    if (!operation) {
      continue;
    }

    if (parsedPath === path) {
      const specUrl = _.get(spec, 'urls.export', '');
      const hashPath = pathToHash({ path });

      return buildEndpointFromOperation({
        swagger,
        method,
        path,
        operation,
        specId: spec.id,
        specName: spec.name || _.get(swagger, 'info.title'),
        specUrl,
        hashPath,
        $ref: `${specUrl}${hashPath}`,
      });
    }

    const pathParts = buildPathParts(path);
    if (pathParts.length !== requestPathParts.length) {
      continue;
    }

    let match = true;
    let epExactScore = 0;

    for (const i in pathParts) {
      // If it's a dynamic path part, auto match, and continue
      if (pathParts[i].match(/\{.*?\}/)) {
        continue;
      }

      if (pathParts[i] !== requestPathParts[i]) {
        match = false;
        epExactScore = -1;
        break;
      } else {
        epExactScore++;
      }
    }

    if (match && epExactScore > endpointExactScore) {
      const specPath = ['paths', path, _.toLower(method)];
      const specUrl = _.get(spec, 'urls.export', '');
      const hashPath = pathToHash({ path: specPath });

      endpointExactScore = epExactScore;
      endpoint = buildEndpointFromOperation({
        swagger,
        method,
        path,
        operation,
        specId: spec.id,
        specName: spec.name || _.get(swagger, 'info.title'),
        specUrl,
        hashPath,
        $ref: `${specUrl}${hashPath}`,
      });
    }
  }

  return endpoint;
};

export const getEndpointFromSpecs = ({ specs, ...extraProps }) => {
  if (_.isEmpty(specs)) {
    return null;
  }

  if (!(specs instanceof Array)) {
    return getEndpointFromSpec({ spec: specs, ...extraProps });
  }

  for (const spec of specs) {
    const endpoint = getEndpointFromSpec({ spec, ...extraProps });

    if (endpoint) {
      return endpoint;
    }
  }

  return null;
};

export const getResponseSchemaFromSpecs = ({ specs, method, url, code, variables }) => {
  const endpoint = getEndpointFromSpecs({ specs, method, url, variables });
  let schema;

  if (endpoint) {
    const spec = _.find(specs, { id: endpoint.specId });
    schema = getResponseSchemaFromOperation({
      spec,
      method,
      path: endpoint.path,
      code,
    });
  }

  return schema;
};

export const getEndpointsFromSpec = spec => {
  const swagger = safeParse(_.get(spec, 'data'));

  if (_.isEmpty(swagger)) {
    return [];
  }

  const endpoints = [];

  for (const path in swagger.paths) {
    if (!Object.prototype.hasOwnProperty.call(swagger.paths, path)) {
      continue;
    }

    for (const method in swagger.paths[path]) {
      if (method === 'parameters') {
        continue;
      }

      const specPath = ['paths', path, method];
      const specUrl = _.get(spec, 'urls.export', '');
      const operation = _.get(swagger, specPath);
      const hashPath = pathToHash({ path: specPath });

      endpoints.push(
        buildEndpointFromOperation({
          swagger,
          method,
          path,
          operation,
          specName: spec.name || _.get(swagger, 'info.title'),
          specId: spec.id,
          specUrl,
          hashPath,
          $ref: `${specUrl}${hashPath}`,
        })
      );
    }
  }

  return endpoints;
};

export const getEndpointsFromSpecs = (specs = []) => {
  if (_.isEmpty(specs)) {
    return [];
  }

  if (!(specs instanceof Array)) {
    return getEndpointsFromSpec(specs);
  }

  let endpoints = [];

  for (const spec of specs) {
    endpoints = endpoints.concat(getEndpointsFromSpec(spec));
  }

  return endpoints;
};

export const computePathParamsFromUri = (uri = '', { existingParams } = {}) => {
  const parameters = _.reject(existingParams || [], { in: 'path' });

  const matches = uri.match(/{([\w]*)}/g);
  if (matches) {
    for (const m of matches) {
      parameters.push({
        name: m.replace(/{|}/g, ''),
        in: 'path',
        type: 'string',
        required: true,
      });
    }
  }

  return parameters;
};

export const computeNewPath = ({ paths, path = '', methods = [], skipConfirm }) => {
  const updates = [];

  if (_.isEmpty(path)) {
    window.alert('You must specify a path.');
    return;
  }

  if (_.isEmpty(methods)) {
    window.alert('You must specify at least one method.');
    return;
  }

  if (path.charAt(0) !== '/') {
    path = `/${path}`;
  }

  let r = true;
  const existing = _.get(paths, path);
  if (existing) {
    r = skipConfirm || window.confirm(`The ${path} path already exists in this spec. Extend it?`);
  }

  if (!r) {
    return;
  }

  const newPath = _.cloneDeep(existing) || {};
  const parameters = computePathParamsFromUri(path, { existingParams: newPath.parameters });
  if (newPath && !_.isEmpty(parameters)) {
    newPath.parameters = parameters;
  }

  for (const m of methods) {
    newPath[m] = {
      responses: {
        200: {
          description: '',
          schema: {
            type: 'object',
            properties: {},
          },
        },
      },
    };
  }

  updates.push({
    transformation: 'set',
    path: ['paths', path],
    value: newPath,
  });

  return updates;
};

export const computeNewResource = ({ path = '', paths = {}, name, schema }) => {
  const updates = [];

  // check basic requirements

  if (_.isEmpty(name)) {
    window.alert('You must specify a model name.');
    return;
  }

  if (_.isEmpty(path)) {
    window.alert('You must specify a base resource path.');
    return;
  }

  if (path.charAt(0) !== '/') {
    path = `/${path}`;
  }

  const capitalName = _.upperFirst(name);
  const capitalNameTrimmed = capitalName.trim();
  const kebabName = _.kebabCase(name).toLowerCase();
  const resourcePath = `${path}/{${_.camelCase(name)}Id}`;

  // are we extending? if so, double check w user

  let r = true;

  const existingPath = paths[path];
  const existingResourcePath = paths[resourcePath];
  if (existingPath) {
    r = window.confirm(`The ${path} path already exists in this spec. Extend it?`);
  } else if (existingResourcePath) {
    r = window.confirm(`The ${resourcePath} path already exists in this spec. Extend it?`);
  }
  if (!r) {
    return;
  }

  let readSchema = {};
  let writeSchema = {};
  let readWriteSchema = {};
  if (!_.isEmpty(schema)) {
    const ruleFactory = perm => {
      return {
        r: {
          recursive: true,
          circular: true,
          matcher({ key, value }) {
            return (
              !_.includes(['readOnly', 'writeOnly'], key) &&
              (_.isObject(value) && value.type && _.get(value, perm))
            );
          },
          dataFactory: ({ value }) => _.omit(value, [perm]),
        },
        required: {
          recursive: true,
          circular: true,
          matcher: ({ key, value }) => key === 'required',
        },
      };
    };
    const requiredFilterRules = {
      r: {
        recursive: true,
        circular: true,
        matcher: ({ key, value }) => true,
      },
      required: {
        recursive: true,
        circular: true,
        matcher: ({ key, value }) => key === 'required',
        dataFactory: ({ spec, parentPath, value }) => {
          const properties = _.keys(_.get(spec, parentPath.concat('properties')));
          const required = _.intersection(value, properties);
          return required.length ? required : undefined;
        },
      },
    };

    readSchema = filter(
      {
        spec: schema,
        rules: ruleFactory('readOnly'),
      },
      {
        exclusive: false,
      }
    );
    readSchema = filter(
      {
        spec: readSchema,
        rules: requiredFilterRules,
      },
      {
        exclusive: false,
      }
    );

    writeSchema = filter({
      spec: schema,
      rules: ruleFactory('writeOnly'),
    });
    writeSchema = filter(
      {
        spec: writeSchema,
        rules: requiredFilterRules,
      },
      {
        exclusive: false,
      }
    );

    readWriteSchema = omitByRecursively(schema, (v, k) => {
      return (
        _.includes(['readOnly', 'writeOnly'], k) ||
        (_.isObject(v) && v.type && (_.get(v, 'readOnly') || _.get(v, 'writeOnly')))
      );
    });
    readWriteSchema = filter(
      {
        spec: readWriteSchema,
        rules: requiredFilterRules,
      },
      {
        exclusive: false,
      }
    );
  }

  // build common schema
  let commonSchemaTitle = `${capitalName} Common`;
  let commonSchema = {
    title: commonSchemaTitle,
    description: `The properties that are shared amongst all versions of the ${capitalName} model.`,
    type: 'object',
    properties: {},
    ...readWriteSchema,
  };
  let commonSchemaKey = `${kebabName}-common`;
  let commonNamespace = `#/definitions/${commonSchemaKey}`;
  updates.push({
    transformation: 'set',
    path: ['definitions', commonSchemaKey],
    value: commonSchema,
  });

  // build input schema
  let inputSchemaTitle = `${capitalName} Input`;
  let inputSchema = {
    title: inputSchemaTitle,
    description: `The properties that are allowed when creating or updating a ${capitalName}.`,
    allOf: [
      {
        $ref: commonNamespace,
      },
      {
        type: 'object',
        properties: {},
        ...writeSchema,
      },
    ],
  };
  let inputSchemaKey = `${kebabName}-input`;
  let inputNamespace = `#/definitions/${inputSchemaKey}`;
  updates.push({
    transformation: 'set',
    path: ['definitions', inputSchemaKey],
    value: inputSchema,
  });

  // build output schema
  let outputSchemaTitle = `${capitalName} Output`;
  let outputSchema = {
    title: outputSchemaTitle,
    description: `The properties that are included when fetching a list of ${pluralize(
      capitalName
    )}.`,
    allOf: [
      {
        type: 'object',
        properties: {},
        ...readSchema,
      },
      {
        $ref: commonNamespace,
      },
    ],
  };
  let outputSchemaKey = `${kebabName}-output`;
  let outputNamespace = `#/definitions/${outputSchemaKey}`;
  updates.push({
    transformation: 'set',
    path: ['definitions', outputSchemaKey],
    value: outputSchema,
  });

  // build outputDetailed detailed schema
  let outputDetailedSchemaTitle = `${capitalName} Output Detailed`;
  let outputDetailedSchema = {
    title: outputDetailedSchemaTitle,
    description: `The properties that are included when fetching a single ${capitalName}.`,
    allOf: [
      {
        $ref: outputNamespace,
      },
      {
        type: 'object',
        properties: {},
      },
    ],
  };
  let outputDetailedSchemaKey = `${kebabName}-output-detailed`;
  let outputDetailedNamespace = `#/definitions/${outputDetailedSchemaKey}`;
  updates.push({
    transformation: 'set',
    path: ['definitions', outputDetailedSchemaKey],
    value: outputDetailedSchema,
  });

  // create the path operations

  const newPath = _.cloneDeep(existingPath) || {};
  const parameters = computePathParamsFromUri(path);
  if (!existingPath && !_.isEmpty(parameters)) {
    newPath.parameters = parameters;
  }

  // list
  newPath.get = {
    summary: `List ${capitalName}`,
    operationId: `find${pluralize(capitalNameTrimmed)}`,
    responses: {
      200: {
        description: '',
        schema: {
          type: 'array',
          items: {
            $ref: outputNamespace,
          },
        },
      },
    },
  };

  // if (false && example) {
  //   _.set(newPath, ['get', 'responses', 200, 'examples', 'application/json'], example);
  // }

  // create
  newPath.post = {
    summary: `Create ${capitalName}`,
    operationId: `create${capitalNameTrimmed}`,
    parameters: [
      {
        in: 'body',
        name: 'body',
        schema: {
          $ref: inputNamespace,
        },
      },
    ],
    responses: {
      201: {
        description: '',
        schema: {
          $ref: outputDetailedNamespace,
        },
      },
    },
  };

  // if (false && example) {
  //   _.set(newPath, ['post', 'parameters', 0, 'schema', 'example'], example);
  //   _.set(newPath, ['post', 'responses', 201, 'examples', 'application/json'], example);
  // }

  // create the resourcePath operations

  const newResourcePath = _.cloneDeep(existingResourcePath) || {};
  const resourceParameters = computePathParamsFromUri(resourcePath);
  if (!existingResourcePath && !_.isEmpty(resourceParameters)) {
    newResourcePath.parameters = resourceParameters;
  }

  // get
  newResourcePath.get = {
    summary: `Get ${capitalName}`,
    operationId: `get${capitalNameTrimmed}`,
    responses: {
      200: {
        description: '',
        schema: {
          $ref: outputDetailedNamespace,
        },
      },
    },
  };

  // if (false && example) {
  //   _.set(newResourcePath, ['get', 'responses', 200, 'examples', 'application/json'], example);
  // }

  // update
  newResourcePath.put = {
    summary: `Update ${capitalName}`,
    operationId: `update${capitalNameTrimmed}`,
    parameters: [
      {
        in: 'body',
        name: 'body',
        schema: {
          $ref: inputNamespace,
        },
      },
    ],
    responses: {
      200: {
        description: '',
        schema: {
          $ref: outputDetailedNamespace,
        },
      },
    },
  };

  // if (false && example) {
  //   _.set(newResourcePath, ['put', 'parameters', 0, 'schema', 'example'], example);
  //   _.set(newResourcePath, ['put', 'responses', 200, 'examples', 'application/json'], example);
  // }

  // delete
  newResourcePath.delete = {
    summary: `Delete ${capitalName}`,
    operationId: `delete${capitalNameTrimmed}`,
    responses: {
      204: {
        description: '',
      },
    },
  };

  // set the new paths

  updates.push({
    transformation: 'set',
    path: ['paths', path],
    value: newPath,
  });
  updates.push({
    transformation: 'set',
    path: ['paths', resourcePath],
    value: newResourcePath,
  });

  return updates;
};

export const computeUpdatesFromCollectionResult = ({ spec, result = {} }) => {
  const har = collectionResultToHar({ result });
  const newSpec = harToSwagger2({ existingSpec: _.cloneDeep(spec), har }) || {};

  const updates = [];

  if (newSpec.consumes) {
    updates.push({
      transformation: 'set',
      path: ['consumes'],
      value: _.uniq((spec.consumes || []).concat(newSpec.consumes)),
    });
  }

  if (newSpec.produces) {
    updates.push({
      transformation: 'set',
      path: ['produces'],
      value: _.uniq((spec.produces || []).concat(newSpec.produces)),
    });
  }

  if (spec.paths) {
    _.forEach(newSpec.paths, (path, k) => {
      // are we extending? if so, double check w user
      let r = true;
      const existingPath = _.get(spec, `paths.${k}`);
      if (existingPath) {
        r = window.confirm(`The ${k} path already exists in this spec. Extend it?`);
      }

      if (!r) {
        return;
      }

      updates.push({
        transformation: 'set',
        path: ['paths', k],
        value: _.merge(spec.paths[k], path),
      });
    });
  }

  return updates;
};
