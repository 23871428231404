import _ from 'lodash';
import { mergeSchemas } from '../schemas/helpers';
import { httpMethods } from '../http';
import { safeParse } from '../json';

// used to turn swagger response.headers into json schema
const convertObjectToSchema = source => {
  const target = {
    type: 'object',
    properties: {},
  };

  for (const prop in source) {
    target.properties[prop] = source[prop];
  }

  return target;
};

export const generateOperationId = ({ method, path }) => {
  return _.camelCase(`${method}${path}`);
};

export const buildParameters = ({
  rootParameters = [],
  parameters = [],
  dereferencedParameters = [],
}) => {
  const data = {
    path: [],
    query: [],
    header: [],
    formData: [],
    body: {},
  };

  for (const i in parameters) {
    if (!parameters[i]) continue;
    let param = parameters[i];
    let dereferencedParam = dereferencedParameters[i];

    let $ref;
    if (param.$ref) {
      $ref = param.$ref;
      param = rootParameters[_.last($ref.split('/'))] || dereferencedParam || param;
    }

    if (data[param.in]) {
      if (data[param.in] instanceof Array) {
        data[param.in].push({
          index: i,
          $ref,
          param,
          dereferencedParam,
        });
      } else {
        data[param.in] = {
          index: i,
          $ref,
          param,
          dereferencedParam,
        };
      }
    }
  }

  return data;
};

const paramFields = [
  'type',
  'description',
  'default',
  'maximum',
  'exclusiveMaximum',
  'minimum',
  'exclusiveMinimum',
  'maxLength',
  'minLength',
  'pattern',
  'maxItems',
  'minItems',
  'uniqueItems',
  'enum',
  'multipleOf',
  'items',
  'format',
  'allowEmptyValue',
];

export const convertParameterToSchema = source => {
  const target = {};

  for (const prop in source) {
    if (!_.includes(paramFields, prop)) continue;

    target[prop] = source[prop];

    if (typeof target[prop] === 'string') {
      if (prop === 'enum') {
        target[prop] = safeParse(_.replace(target[prop], /\'/g, '"'));
      }
    } else if (prop === 'description') {
      target[prop] = String(target[prop]);
    }
  }

  return target;
};

// turns an item from buildParameters into json schema
// used to turn swagger query, headers, etc into json schema
const convertParametersToSchema = parameters => {
  const target = {
    type: 'object',
    properties: {},
    required: [],
  };

  for (const item of parameters) {
    target.properties[item.param.name] = convertParameterToSchema(item.param);
    if (item.param.required) {
      target.required.push(item.param.name);
    }
  }

  return target;
};

/**
 * Generate schema from params and set it to a specific operation path.
 * @param operation
 * @param params
 * @param path
 * @return {*}
 */

const setOperationFieldSchema = (operation, params, path) => {
  const schema = convertParametersToSchema(params);
  const existingSchema = _.get(operation, path);
  let mergedSchema;

  // If operation has this schema already merge it with new
  if (_.isObject(existingSchema)) {
    mergedSchema = mergeSchemas([existingSchema, schema]);
  }

  _.set(operation, path, mergedSchema || schema);

  return operation;
};

const setOperationBodySchema = (operation, params, consumes) => {
  const bodyMimeTypes = [
    'application/json',
    'application/x-www-form-urlencoded',
    'multipart/form-data',
  ];

  let description;
  let schema;
  let paramExamples;

  if (params.param) {
    description = params.param.description;
    schema = params.param.schema;
    paramExamples = params.param['x-examples'];
  } else {
    schema = convertParametersToSchema(params);
  }

  let availableMimeTypes = _.intersection(bodyMimeTypes, consumes);

  // Set default body mime type to application/json if no consumes mime types available
  if (_.isEmpty(availableMimeTypes)) {
    availableMimeTypes = ['application/json'];
  }

  _.map(availableMimeTypes, mimeType => {
    _.set(operation, ['body', 'content', mimeType, 'schema'], schema);

    if (paramExamples) {
      const examples = _.mapValues(paramExamples, (value, key) => ({ value }));
      _.set(operation, ['body', 'content', mimeType, 'examples'], examples);
    }
  });

  if (!_.isUndefined(description)) {
    _.set(operation, ['body', 'description'], description);
  }

  return operation;
};

const mapOperationParameters = (operation, consumes, parameters, rootParameters) => {
  const builtParameters = buildParameters({ rootParameters, parameters });

  _.map(builtParameters, (params, name) => {
    if (!_.isEmpty(params)) {
      switch (name) {
        case 'formData':
          setOperationBodySchema(operation, params, consumes);
          break;
        case 'body':
          setOperationBodySchema(operation, params, consumes);
          break;
        case 'path':
          setOperationFieldSchema(operation, params, ['params', 'schema']);
          break;
        case 'header':
          setOperationFieldSchema(operation, params, ['headers', 'schema']);
          break;
        case 'query':
          setOperationFieldSchema(operation, params, ['query', 'schema']);
          break;
      }
    }
  });
};

const mapOperationResponses = (operation, responses, produces) => {
  if (_.isEmpty(responses)) {
    return;
  }

  _.set(operation, 'responses', {});

  _.map(responses, (response, status) => {
    if (!response) {
      return;
    }

    const { schema, examples, headers, description } = response;
    let availableMimeTypes = produces;

    // Set default body mime type to application/json if no produces mime types available
    if (_.isEmpty(availableMimeTypes)) {
      availableMimeTypes = ['application/json'];
    }

    _.map(availableMimeTypes, mimeType => {
      if (!_.isEmpty(schema)) {
        _.set(operation, ['responses', status, 'content', mimeType, 'schema'], schema);
      }

      if (!_.isEmpty(examples)) {
        _.set(
          operation,
          ['responses', status, 'content', mimeType, 'examples'],
          _.mapValues(examples, (value, key) => ({ value }))
        );
      }
    });

    if (!_.isEmpty(headers)) {
      _.set(operation, ['responses', status, 'headers', 'schema'], convertObjectToSchema(headers));
    }

    if (!_.isUndefined(description)) {
      _.set(operation, ['responses', status, 'description'], description);
    }
  });

  return operation;
};

export const buildHttpOperation = ({ spec = {}, path = [] }) => {
  if (_.isEmpty(path) || _.isEmpty(spec)) {
    return;
  }

  const [root, operationPath, method] = path;
  const pathObj = _.get(spec, path);

  // if we can't even find the path object, we definitely can't build the operation
  if (!pathObj) {
    return {};
  }

  const rootParameters = spec.parameters;
  const pathParams = _.get(spec, [root, operationPath, 'parameters']);

  const { responses = {}, parameters = [], security: operationSecurity, tags = [] } = pathObj;

  const { security: specSecurity = [], securityDefinitions } = spec;

  const operation = _.pick(pathObj, 'summary', 'description', 'deprecated');
  const consumes = _.union(spec.consumes, pathObj.consumes);
  const produces = _.union(spec.produces, pathObj.produces);

  // Check if method exists
  if (httpMethods.hasOwnProperty(method)) {
    operation.method = method;
  }

  if (operationPath) {
    operation.path = operationPath;
  }

  if (tags.length) {
    // Lookup the tags on the operation from the root, and replace with actual info
    operation.tags = _.map(tags, name => _.find(spec.tags, { name }) || { name });
  }

  // At first, map through path parameters
  mapOperationParameters(operation, consumes, pathParams, rootParameters);

  // Then map through local parameters and override path parameters
  mapOperationParameters(operation, consumes, parameters, rootParameters);

  // Create operation responses
  mapOperationResponses(operation, responses, produces);

  if (spec.host) {
    const schemes = !_.isEmpty(_.compact(spec.schemes)) ? spec.schemes : ['http'];
    operation.servers = _.map(schemes, scheme => {
      // Create a proper URL with scheme
      let url = _.trimEnd(`${scheme}://${spec.host}${spec.basePath || ''}`, '/');

      const matches = url.match(/\{([^}]+)\}/g) || [];
      matches.forEach(match => {
        const varName = match.replace(/\{|\}/g, '');
        if (!/^\$/.test(varName)) {
          url = url.replace(varName, `$$$.env.${varName}`);
        }
      });

      return { url };
    });
  } else {
    operation.servers = [
      {
        url: '{$$.env.host}',
      },
    ];
  }

  let secReqObj;

  // only use root security if the user has not specified any
  // they override root security with empty array if want to clear for a particular operation
  if (!operationSecurity) {
    // Use global security
    secReqObj = specSecurity;
  } else if (!_.isEmpty(operationSecurity)) {
    // Operation security replaces global security
    secReqObj = operationSecurity;
  }

  // Set operation security if a Security Requirement Object is present
  if (secReqObj) {
    _.map(secReqObj, secItem => {
      _.map(secItem, (scopes, name) => {
        const def = _.cloneDeep(securityDefinitions[name]);

        if (def) {
          if (!_.isEmpty(scopes) && !_.isEmpty(def.scopes)) {
            // filter out scopes not used by this operation
            def.scopes = _.omitBy(def.scopes, (desc, name) => !_.includes(scopes, name));
          } else if (_.isEmpty(def.scopes)) {
            delete def.scopes;
          }

          if (_.isEmpty(operation.security)) {
            _.set(operation, 'security', [def]);
          } else {
            operation.security.push(def);
          }
        }
      });
    });
  } else {
    _.set(operation, 'security', []);
  }

  return operation;
};
