import * as React from 'react';

const cn: any = require('classnames');

export interface IToggleState {
  focused: boolean;
}

export interface IToggleProps {
  id?: string;
  className?: string;
  checked: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
}

export class Toggle extends React.Component<IToggleProps, IToggleState> {
  constructor(props: IToggleProps) {
    super(props);
    this.state = {
      focused: false,
    };
  }
  public render() {
    const {
      id,
      className,
      checked,
      width = '40px',
      height = '20px',
      disabled,
      onChange,
      style = {
        margin: 0,
        border: 0,
        padding: 0,
      },
    } = this.props;
    // Styles originally based on the Zendesk CSS Toggle component here: https://garden.zendesk.com/css-components/forms/checkbox/
    return (
      <span className={className} style={style}>
        <label>
          <input
            type="checkbox"
            id={id}
            disabled={disabled}
            onChange={onChange}
            style={{
              position: 'absolute',
              clip: 'rect(1px, 1px, 1px, 1px)',
            }}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
          />
          <span
            className={cn(
              disabled ? 'bg-grey-lighter' : checked ? 'bg-primary' : 'bg-grey',
              'inline-block bg-contain bg-no-repeat border-none',
              checked ? 'bg-right' : 'bg-left'
            )}
            style={{
              backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' color='%23FFF'%3E%3Ccircle cx='7' cy='7' r='5' fill='currentColor'/%3E%3C/svg%3E")`,
              margin: 0,
              padding: 0,
              width,
              height,
              transition:
                'box-shadow .1s ease-in-out,background-color .15s ease-in-out,background-position .15s ease-in-out,color .25s ease-in-out',
              borderRadius: '100px',
              boxSizing: 'border-box',
              boxShadow: this.state.focused ? '0 0 0 3px rgba(31, 115, 183, .35)' : undefined,
            }}
          />
        </label>
      </span>
    );
  }
}
