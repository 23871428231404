import _ from 'lodash';
import defaultAxios from 'axios';
import { action, computed, observable, reaction } from 'mobx';

import AppStore from '@platform/stores/appStore';
import JsonSchemaStore from '@platform/stores/jsonSchemaStore';
import JsonPathCache from '@platform/stores/jsonPathCache';

import { create as createErrorStore } from '@platform/stores/error';
import { create as createOauthTokenStore } from '@platform/stores/oauth';

import { getConfigVar } from '@platform/utils/config';
import { stringifyQuery } from '@platform/utils/query';
import { safeParse, safeStringify } from '@platform/utils/json';

import CollectionEditorStore from './collectionEditorStore';
import PageStore from './pageStore';
import RouterStore from './routerStore';

class RootStore {
  @observable
  _variables = {};

  @observable
  debouncedVariables = {};

  version = getConfigVar('APP_VERSION') || '0.0.0';
  releaseStage = getConfigVar('RELEASE_STAGE') || 'development';

  constructor(opts = {}) {
    const {
      axios,
      api,
      location = {}, // initialize with a specific http location
      redirects,
      defaultVariables,
      globalQueryParams,
    } = opts;

    // so we don't have to do this check everywhere, can just check isClient :)
    this.isClient = typeof window !== 'undefined' && window.localStorage;

    // setup the global api requester
    // allow overriding defaults, useful for testing
    this.axios = axios || defaultAxios;
    this.api =
      api ||
      axios ||
      defaultAxios.create({
        baseURL: getConfigVar('SL_API_HOST'),
        paramsSerializer(params) {
          return stringifyQuery(params);
        },
      });

    let localVariables = {};
    if (this.isClient) {
      localVariables = safeParse(localStorage.getItem('__SL.variables'));
    }

    // create the state models
    this._stores = {
      routerStore: new RouterStore({ rootStore: this, location, globalQueryParams, redirects }),
    };

    this._variables = Object.assign(
      {},
      defaultVariables,
      localVariables,
      _.get(this._stores.routerStore, 'location.query')
    );

    /**
     * Create the custom stores second, so that they can use the services if necessary.
     */
    Object.assign(this._stores, {
      appStore: new AppStore({ rootStore: this }),

      collectionEditorStore: new CollectionEditorStore({ rootStore: this }),
      pageStore: new PageStore({ rootStore: this }),
      jsonSchemaStore: new JsonSchemaStore({ rootStore: this }),

      jsonPathCache: new JsonPathCache({ rootStore: this }),

      errorStore: createErrorStore({ env: { rootStore: this } }),
      oauthTokenStore: createOauthTokenStore({ env: { rootStore: this } }),
    });

    this.setupReactions();
  }

  setupReactions() {
    reaction(
      () => this._variables,
      action(variables => {
        this.debouncedVariables = variables;

        if (this.isClient) {
          localStorage.setItem(
            '__SL.variables',
            safeStringify(_.pickBy(variables, v => !_.isNil(v)))
          );
        }
      }),
      {
        name: 'updateVariables',
        delay: 1000,
        fireImmediately: true,
      }
    );
  }

  get stores() {
    return this._stores;
  }

  @computed
  get variables() {
    return this._variables;
  }

  @action
  updateVariables = (variables = {}) => {
    this._variables = { ...this._variables, ...variables };
  };
}

export const createRootStore = opts => {
  return new RootStore(opts);
};
