import { action, reaction, toJS, observable } from 'mobx';
import _ from 'lodash';

import shortid from '@platform/utils/shortid';

let localforage;
if (typeof window !== 'undefined' && window.localStorage) {
  localforage = require('localforage');
}

class Base {
  uid = shortid();
  rootStore = null;
  persist = [];

  @observable
  persistLoaded = false;

  @action
  init(opts) {
    _.merge(this, opts || {});

    if (localforage) {
      let persistLoadCount = 0;
      const persistCount = _.size(this.persist);
      for (const p of this.persist) {
        localforage.getItem(
          p.key,
          action((err, val) => {
            persistLoadCount += 1;

            if (err) {
              console.error(`error getting ${p.key} from localStorage`, err);
            } else if (val) {
              _.set(this, p.key, p.deserialize ? p.deserialize(val) : val);
            }

            if (persistLoadCount >= persistCount) {
              this.persistLoaded = true;
            }
          })
        );
      }
    }

    if (typeof window !== 'undefined') {
      this.setupReactions();
    }
  }

  setupReactions() {
    reaction(
      () => {
        const vals = {};
        for (const p of this.persist) {
          vals[p.key] = this[p.watch || p.key];
        }
        return vals;
      },
      action(vals => {
        for (const p of this.persist) {
          let val = this[p.key];
          val = p.serialize ? p.serialize(val) : val;

          if (localforage) {
            localforage.setItem(p.key, toJS(val), err => {
              if (err) {
                console.error(`error persisting ${p.key} to localStorage`, err);
              }
            });
          }
        }
      }),
      {
        name: 'localPersist',
        delay: 2000,
      }
    );
  }
}

export default Base;
