import _ from 'lodash';
import mingo from 'mingo';
import { computed } from 'mobx';
import { types, flow, getEnv, detach, isStateTreeNode } from 'mobx-state-tree';

import { canUser, getHighestRole } from '@platform/utils/acl';
import { registerLogger } from '@platform/utils/logging';
import { stringifyQuery } from '@platform/utils/query';

const log = registerLogger('@platform/stores', 'BaseService');

export const updateRecord = ({ records, record, getRecordId, identifier = 'id', replace }) => {
  let updatedRecord;
  const existingIndex = _.findIndex(records, { [identifier]: getRecordId(record) });
  if (existingIndex >= 0) {
    /**
     * Note: can't use _.merge, because that will not remove nested fields.
     * For example, original prop is tags: [1, 2], new prop is tags: [1]
     * The 2 needs to be removed. Object.assign will do that, at the expense of not being
     * As fine grained when merging deep properties. That is acceptable, we can blast away and replace
     * properties deeper than level 1.
     *
     * Also, don't merge a property if its value is undefined.
     */

    if (replace) {
      records[existingIndex].data = Object.assign({}, _.omitBy(record, _.isUndefined));
    } else {
      records[existingIndex].data = Object.assign(
        {},
        records[existingIndex].data,
        _.omitBy(record, _.isUndefined)
      );
    }

    updatedRecord = records[existingIndex];
  } else {
    updatedRecord = {
      [identifier]: getRecordId(record),
      data: record,
    };

    records.push(updatedRecord);
  }

  return updatedRecord;
};

/**
 * Note the weird detach thing below :(
 * https://github.com/mobxjs/mobx-state-tree/issues/142
 * https://github.com/mobxjs/mobx-state-tree/issues/416
 *
 * If want to test in future, just do the pullAt normally (no detach).
 * Then, open the file editor and remove a bunch of files in the sidebar. This was triggering the error.
 */
export const removeRecord = ({ records, identifier = 'id', id }) => {
  const existingIndex = _.findIndex(records, { [identifier]: id });

  if (existingIndex >= 0) {
    const oldRecord = records[existingIndex];
    if (isStateTreeNode(oldRecord)) {
      detach(oldRecord);
    }
    records.remove(oldRecord);
    return oldRecord;
  }
};

export const ServiceRecord = types
  .model({
    id: types.union(types.identifierNumber, types.identifier),
    data: types.optional(types.frozen()),

    isGetting: false,
    isUpdating: false,
    isRemoving: false,
  })
  .views(self => {
    return {};
  })
  .actions(self => {
    return {};
  })
  .named('ServiceRecord');

/**
 * All services extend this service model. This model defines all the basic crud
 * operations, methods for manipulating domain state (records), and methods for querying that state.
 *
 * Notes
 * 1. api is an env property passed in at creation in services/index.js.
 * 2. services/user.js sets a common 'private-token' header on the api instance as the user changes their auth.
 */
export const BaseService = types
  .model({
    identifier: 'id',

    basePath: '/',
    singleBasePath: types.maybe(types.string),

    records: types.optional(types.array(ServiceRecord), []),

    isSaving: false,
    isCreating: false,
    isUpdating: false,

    isLoading: false,
    isGetting: false,
    isFinding: false,
    isSearching: false,

    isRemoving: false,

    // should records be blasted away on find?
    replace: false,

    error: types.optional(types.frozen()),

    loadMore: types.maybe(types.boolean),
    perPage: types.optional(types.number, 25),
  })
  .views(self => {
    return {
      // NOTE: getLocal and findLocal return JUST the data prop (not the root record)

      /**
       * By default, search by id, path, and username property.
       * Can override searchProps in child to change behavior.
       *
       * @param {any} id
       * @param {array[string]}
       * @returns {record}
       */
      getLocal(id, searchProps = [self.identifier, 'path', 'username']) {
        return computed(() => {
          // make sure its not a string id
          const cleanid = _.toNumber(id) || id;

          const found = self.records.find(record => {
            for (const p of searchProps) {
              const t = _.get(record, ['data', p]);
              if (t === cleanid || t === id) {
                return true;
              }
            }
          });

          return found ? found.data : undefined;
        });
      },

      findLocal({ query = {}, skip, limit, sort } = {}) {
        // SL-385: Don't explicitly set skip or limit since we are paging with load more button and want to return all records we've loaded

        return computed(() => {
          const cursor = mingo.find(_.map(self.records, 'data'), query);

          if (skip) {
            cursor.skip(skip);
          }

          if (limit) {
            cursor.limit(limit);
          }

          if (sort) {
            // sort example: {student_id: 1, score: -1}
            cursor.sort(sort);
          }

          return cursor.all();
        });
      },

      getCurrentPage() {
        return Math.ceil(self.records.length / self.perPage);
      },
    };
  })
  .actions(self => {
    // children can set this to true to always not manage/add/etc self.records
    self.skipStore = false;

    return {
      // by default, only dehydrate down to the client if records have been loaded
      skipDehydrate() {
        return _.isEmpty(self.records);
      },

      /**
       * NOTE: send returns the entire axios request object (which includes the .data response property).
       * The regular crud methods below (find, get, etc) just return the actual axios response data.
       */
      send: flow(function*(request = {}, options = {}) {
        const { method, path, data = {}, headers = {}, query = {}, ...extra } = request;
        const { params, skipError } = options;

        const rootStore = getEnv(self).rootStore;

        self.error = undefined;

        try {
          const api = rootStore.api;

          let url = self.replacePathParams(path, params);

          /**
           * Add the query string directly to the path, instead of via axios.params,
           * to make testing (with moxios) possible.
           */
          if (!_.isEmpty(query)) {
            const queryString = stringifyQuery(query);
            if (!_.isEmpty(queryString)) {
              url = `${url}?${queryString}`;
            }
          }

          /**
           * TODO: needed until we can use global axios instance defaults:
           * https://github.com/axios/axios/issues/385#issuecomment-339199333
           */
          const userToken = _.get(rootStore.stores.userService, 'token');
          if (userToken) {
            headers['private-token'] = userToken;
          }

          headers['App-Version'] = rootStore.version;
          // Add the websocket connection ID to every request so we can write messages back to this client
          headers.wsconnid = _.get(rootStore.Websocket, 'connection.id');

          const res = yield api.request({
            method,
            url,
            data,
            headers,
            proxy: false,
            ...extra,
            withCredentials: rootStore.isClient,
          });

          const session = _.get(res, 'headers.session-cookie');
          if (session) {
            rootStore.stores.userService.updateSessionCookie(session);
          }

          return res;
        } catch (e) {
          if (!skipError) {
            self.error = _.get(e, 'response.data', String(e));
            throw e;
          }
        }
      }),

      get: flow(function*(id, params, opts = {}) {
        if (!id) {
          log.error('get: id is a required property');
          return;
        }

        const {
          basePath,
          query = {},
          skipStore = self.skipStore,
          skipError,
          eager,
          onlyNew,
        } = opts;

        const existing = self.getLocal(id);

        // eager will return the existing record and initiate a re-fetch in the background, without blocking the caller
        // onlyNew will only initiate a fetch if the record does not already exist locally
        if (eager || onlyNew) {
          if (existing && !onlyNew) {
            self.get(id, params, { ...opts, eager: false });
          }

          return existing;
        }

        self.isLoading = true;
        self.isGetting = true;

        let err;
        let record;
        try {
          const res = yield self.send(
            {
              method: 'get',
              path: `${basePath || self.singleBasePath || self.basePath}/${encodeURIComponent(id)}`,
              query,
            },
            { params, skipError }
          );

          if (skipStore) {
            record = res.data;
          } else {
            record = _.get(
              self.updateRecord(res.data, { id, params, opts, replace: true }),
              'data',
              record
            );
          }
        } catch (e) {
          if (!skipError) {
            err = e;
            self.error = _.get(e, 'response.data', String(e));
          }
        }

        self.isLoading = false;
        self.isGetting = false;

        if (err) {
          throw err;
        }

        return record;
      }),

      find: flow(function*(params, opts = {}) {
        const {
          basePath,
          query = {},
          skipStore = self.skipStore,
          skipError,
          skipPagination,
        } = opts;

        if (query.page > 1) {
          self.isFinding = true;
        } else {
          self.isFinding = true;
          self.isLoading = true;
        }

        if (query.query || query.search) {
          self.isSearching = true;
        }

        // apply max 100 as a default
        const q = query;

        if (!skipPagination) {
          q.page = query.page || 1;
          q.per_page = query.per_page || self.perPage;
        }

        let err;
        let records = [];
        try {
          const res = yield self.send(
            { method: 'get', path: basePath || self.basePath, query: q },
            { params }
          );

          const { data = [], next_page } = _.get(res, 'data', {});

          if (skipStore) {
            records = data;
          } else {
            if (self.replace) {
              records = self.replaceRecords(data, {
                params,
                opts,
              });
            } else {
              records = self.updateRecords(data, {
                params,
                opts,
              });
            }

            records = _.map(records, 'data');

            if (!skipPagination) {
              self.loadMore = !!Number(next_page);
            }
          }
        } catch (e) {
          if (!skipError) {
            err = e;
            self.error = _.get(e, 'response.data', String(e));
          }
        }

        self.isLoading = false;
        self.isFinding = false;
        self.isSearching = false;

        if (err) {
          throw err;
        }

        return records;
      }),

      create: flow(function*(data = {}, params, opts = {}) {
        self.error = undefined;
        self.isSaving = true;
        self.isCreating = true;

        const { basePath, query = {}, skipStore = self.skipStore, skipError } = opts;

        let err;
        let record;
        try {
          const res = yield self.send(
            { method: 'post', path: basePath || self.basePath, data, query },
            { params }
          );

          if (skipStore) {
            record = res.data;
          } else {
            record = _.get(self.updateRecord(res.data, { data, params, opts }), 'data', record);
          }
        } catch (e) {
          if (!skipError) {
            err = _.get(e, 'response.data', String(e));
            self.error = err;
          }
        }

        self.isSaving = false;
        self.isCreating = false;

        if (err) {
          throw err;
        }

        return record;
      }),

      update: flow(function*(id, data = {}, params, opts = {}) {
        if (!id) {
          log.error('update: id is a required property');
          return;
        }

        self.error = undefined;
        self.isSaving = true;
        self.isUpdating = true;

        const { basePath, query = {}, skipStore = self.skipStore, skipError } = opts;

        let err;
        let record;
        try {
          const res = yield self.send(
            {
              method: 'put',
              path: `${basePath || self.singleBasePath || self.basePath}/${encodeURIComponent(id)}`,
              data,
              query,
            },
            { params }
          );

          if (skipStore) {
            record = res.data;
          } else {
            record = _.get(self.updateRecord(res.data, { id, data, params, opts }), 'data', record);
          }
        } catch (e) {
          if (!skipError) {
            err = _.get(e, 'response.data', String(e));
            self.error = err;
          }
        }

        self.isSaving = false;
        self.isUpdating = false;

        if (err) {
          throw err;
        }

        return record;
      }),

      remove: flow(function*(id, params, opts = {}) {
        if (!id) {
          log.error('remove: id is a required property');
          return;
        }

        const { basePath, query = {}, skipStore = self.skipStore, skipError } = opts;

        self.error = undefined;
        self.isRemoving = true;

        let err;
        let record;
        try {
          const res = yield self.send(
            {
              method: 'delete',
              path: `${basePath || self.singleBasePath || self.basePath}/${encodeURIComponent(id)}`,
              query,
            },
            { params }
          );

          if (skipStore) {
            record = res.data;
          } else {
            record = _.get(self.removeRecord(id), 'data', record);
          }
        } catch (e) {
          if (!skipError) {
            err = _.get(e, 'response.data', String(e));
            self.error = err;
          }
        }

        self.isRemoving = false;

        if (err) {
          throw err;
        }

        return record;
      }),

      replacePathParams(path, params) {
        let computed = path;
        _.forEach(params, (param, name) => {
          computed = computed.replace(`:${name}`, encodeURIComponent(param));
        });
        return computed;
      },

      // services can override this to customize behavior (the identifier prop helps too)
      getRecordId(record) {
        return record[self.identifier];
      },

      updateRecord(record, callOpts) {
        return updateRecord({
          ...callOpts,
          records: self.records,
          record,
          identifier: self.identifier,
          getRecordId: self.getRecordId,
        });
      },

      updateRecords(records, callOpts) {
        if (!_.isEmpty(records)) {
          return _.map(records, record => {
            return self.updateRecord(record, callOpts);
          });
        }

        return [];
      },

      removeRecord(id) {
        return removeRecord({ records: self.records, identifier: self.identifier, id });
      },

      replaceRecords(records) {
        const newRecords = _.map(records, r => ({
          [self.identifier]: self.getRecordId(r),
          data: r,
        }));

        self.records = newRecords;

        return newRecords;
      },

      setError(err) {
        self.error = err;
      },

      /**
       * Shortcut helper to check if the user can perform the given action on a model in this service.
       * Defaults to the "current" view property, if defined on the service.
       */
      canUser({ id, action } = {}) {
        const p = self.getLocal(id || _.get(self, 'current.id'));
        if (!p) return false;

        return canUser({
          entity: p.get(),
          action,
          user: _.get(getEnv(self).rootStore.stores.userService, 'authorizedUser'),
        });
      },

      getHighestRole({ id } = {}) {
        const p = self.getLocal(id || _.get(self, 'current.id'));
        if (!p) return false;

        return getHighestRole({ entity: p.get() });
      },
    };
  });
