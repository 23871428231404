import * as React from 'react';

const cn: any = require('classnames');

import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';

import { colors, themes } from './enums';
import { divClickHandler } from './events';
import { Icon, IIcon } from './Icon';
import { IPopupPosition, Popup, PopupTriggerRenderer } from './Popup';

export type DropdownChildRenderer = (renderItem: (opts: IDropdownChild) => any) => void;

export interface IDropdownSection {
  label?: string;
  children: Array<IDropdownChild | DropdownChildRenderer>;
}

export interface IDropdownChild {
  title: string;
  onClick?: divClickHandler;
  onMouseDown?: divClickHandler;
  subtitle?: string;
  icon?: IIcon;
  color?: colors;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  context?: any;
}

export interface IDropdown extends IPopupPosition {
  renderTrigger: PopupTriggerRenderer;
  computeSections: () => IDropdownSection[];
  theme?: themes;
}

export class Dropdown extends React.PureComponent<IDropdown> {
  public render() {
    const { theme, renderTrigger, computeSections, ...popupProps } = this.props;

    return (
      <Popup
        {...popupProps}
        renderTrigger={renderTrigger}
        renderContent={(attributes: any) => {
          const sections = computeSections();
          return (
            <div className="shadow bg-white rounded py-2" {...attributes}>
              {sections.map((s, i) => {
                return this._renderSection(s, i);
              })}
            </div>
          );
        }}
      />
    );
  }

  private _renderSection = (section: IDropdownSection, index: number) => {
    return (
      <div key={index} className="foo">
        {section.children.map((child, i) => {
          if (child instanceof Function) {
            return child(this._renderChild);
          }

          return this._renderChild(child, i);
        })}
      </div>
    );
  };

  private _renderChild = (child: IDropdownChild, index?: number) => {
    const { disabled, loading } = child;

    return (
      <div
        key={index}
        className={cn(
          `flex items-center hover:bg-darken-50 cursor-pointer py-2 pl-4 pr-8 border-r-4 border-${
            child.active ? colors[child.color || colors.active] : 'transparent'
          }`,
          {
            'opacity-75': disabled,
            'cursor-wait': loading,
            'cursor-not-allowed': disabled && !loading,
          }
        )}
        onClick={child.onClick}
        onMouseDown={child.onMouseDown}
      >
        {child.icon && (
          <div
            className={`w-12 text-center -ml-4 text-${colors[child.color || colors.grey]}`}
            style={{ fontSize: '0.9em', marginTop: 2 }}
          >
            <Icon icon={loading ? faSpinner : child.icon} pulse={loading} />
          </div>
        )}

        <div className="text-sm">
          <div className="font-bold">{child.title}</div>
          {child.subtitle && (
            <div className="text-muted whitespace-no-wrap mt-1/2">{child.subtitle}</div>
          )}
        </div>
      </div>
    );
  };
}
