import _ from 'lodash';
import axios from 'axios';
import produce from 'immer';
import { observable, computed, action, flow } from 'mobx';

import GoogleAnalytics from '@platform/utils/googleAnalytics';
import { extractParams, replacePathParams, buildQueryString } from '@platform/utils/url';
import {
  cleanCollection,
  flattenRefOutput,
  scenarioPathLocations,
  createScenarioStepRequest,
} from '@platform/utils/collections';
import { alert } from '@platform/utils/alert';
import { safeStringify, safeParse } from '@platform/utils/json';
import { extractVariables } from '@platform/utils/variables';
import { hashToPath } from '@platform/utils/history';

class CollectionResult {
  id = '';
  type = 'collection';
  createdAt = null;

  @observable.ref
  data = {};
}

class ScenarioResult {
  id = '';
  type = 'scenario';
  createdAt = null;

  @observable.ref
  data = {};
}

class StepResult {
  id = '';
  type = 'http';
  createdAt = null;

  @observable.ref
  data = {};
}

export class CollectionEditor {
  rootStore;
  currentPath;

  @observable.ref
  parsed = {};

  @observable
  spec = '';

  @observable.ref
  error;

  @observable
  collectionRunning = false;

  @observable
  scenariosRunning = observable.map({});

  @observable
  stepsRunning = observable.map({});

  @observable.ref
  collectionResult = {};

  @observable
  scenarioResults = [];

  @observable
  stepResults = [];

  @observable
  scenarioCtx = observable.map({});

  @observable
  stepPathParams = observable.map({});

  @observable
  scenarioTabs = {};

  @observable.ref
  connectedSpecs = [];

  constructor({ entity, stepPathParams, rootStore, editPath }) {
    this.id = entity.id;
    this.entity = entity;
    this.parsed = entity.data;
    this.original = safeStringify(entity.data); // Used to determine if entity data has changed in HTTPBlock
    this.stepPathParams = stepPathParams;
    this.rootStore = rootStore;
    this.currentPath = hashToPath({ hash: editPath });
  }

  @action
  updateParsed = (t, p, v, options = {}) => {
    const { immediate, splice = {} } = options;

    this.updatingFrom = 'parsed';
    this.updatingHistory = false;

    // clean path
    // make sure it's an array, and remove empty/null values
    let path = p;
    if (p instanceof Array) {
      path = _.reject(p, e => e === '' || e === null);
    } else if (!p) {
      path = [];
    } else {
      // TODO: handle more complex path selectors like foo.bar[0].fee or [0] or [0].fee
      path = path.split('.');
    }

    let transformation = t;

    // don't allow setting undefined values
    if (_.isUndefined(v)) {
      transformation = 'unset';
    }

    // clean value
    let value;
    this.parsed = produce(this.parsed, newParsed => {
      switch (transformation) {
        case 'push':
          value = _.get(newParsed, path, []);
          if (value instanceof Array) {
            value = value.concat(v instanceof Array ? v : [v]);
          } else {
            log.error(`updateParsed: can't push value because path is not an array:`, {
              path,
              value,
            });
            return newParsed;
          }

          break;
        case 'pull':
          if ((!_.isNumber(v) && !v) || v < 0) {
            log.error(`updateParsed: can't pull out of bounds index`, { path, index: v });
            return newParsed;
          }

          value = _.get(newParsed, path, []);
          if (value instanceof Array) {
            _.pullAt(value, v);
          }

          break;
        case 'splice':
        case 'move':
          // we assume an array in our splice transformation
          value = _.isArray(v) ? v : [v];

          break;
        default:
          value = v;
      }

      // do the transformation
      let tail = _.last(path);
      let parentVal;
      let targetVal;

      switch (transformation) {
        case 'unset':
          parentVal = _.get(newParsed, _.initial(path), {});

          // handle unsetting when the last element is a numeric index (ie unsetting an array value)
          // and handle unsetting where it's a string tail, but really the target is an array
          if (_.isArray(parentVal)) {
            _.pullAt(parentVal, tail);
          } else {
            _.unset(newParsed, path);
          }
          break;
        case 'set':
        case 'push':
        case 'pull':
          if (_.isUndefined(value)) {
            log.error(
              `updateParsed: the ${transformation} transformation requires a value to be passed in`,
              {
                path,
              }
            );
            return newParsed;
          }

          if (_.isEmpty(path)) {
            return v;
          } else {
            _.set(newParsed, path, value);
          }

          break;
        case 'splice':
          if (_.isEmpty(value)) {
            log.error('updateParsed: you must pass a value to splice', {
              splice,
              path,
              value,
            });

            return newParsed;
          }

          if (!_.isNumber(splice.index)) {
            log.error(
              'updateParsed: the splice transformation requires a numeric splice.index option to be passed in',
              {
                splice,
                path,
                value,
              }
            );
            return newParsed;
          }

          targetVal = _.get(newParsed, path, []);
          if (!targetVal || !_.isArray(targetVal)) {
            log.error('updateParsed invalid newParsed, splice can only be used on arrays', {
              targetVal,
              splice,
              path,
              value,
            });

            return newParsed;
          }

          targetVal.splice(splice.index, splice.howMany || 0, ...value);
          _.set(newParsed, path, targetVal);

          break;
        case 'move':
          try {
            move({
              data: newParsed,
              srcPath: path,
              dstPath: value,
            });
          } catch (e) {
            // noop
          }

          break;
        default:
          log.error('updateParsed transformation not supported:', t);
          return newParsed;
      }
    });

    if (this.jsonPathCache) {
      this.jsonPathCache.triggerUpdate(transformation, path, v, { cacheKey: this.id, options });
    }

    this.handleSpecUpdate({ spec: this.parsed }, { immediate });
  };

  @action
  handleSpecUpdate = ({ spec }) => {
    if (_.isUndefined(spec)) {
      return;
    }

    const target = safeStringify(spec);

    if (this.updatingParsed) {
      this.spec = target;
    } else {
      try {
        const newParsed = JSON.parse(this.spec);
        this.parsed = newParsed;
        if (this.onSpecChanged) {
          this.onSpecChanged(newParsed);
        }
      } catch (e) {
        // noop
      }
    }
  };

  @computed
  get currentParsed() {
    if (this._currentParsed) {
      return this._currentParsed;
    }

    if (_.isEmpty(this.currentPath)) {
      return this.parsed;
    }

    return _.get(this.parsed, this.currentPath);
  }

  updateCurrentParsed(t, p, v) {
    let path = p instanceof String ? [p] : p;

    if (!_.isEmpty(this.currentPath)) {
      path = this.currentPath.concat(path);
    }

    this.updateParsed(t, path, v);
  }

  @computed
  get allCurrentVariables() {
    let vars = {};

    _.merge(
      vars,
      this.currentPopulatedEnvVariables,
      this.currentPopulatedCtxVariables,
      this.currentStepPathParams
    );

    return vars;
  }

  get localParsed() {
    const collection = cleanCollection({
      collection: this.parsed,
    });

    return {
      id: this.id,
      name: _.get(this.entity, 'name'),
      ...collection,
    };
  }

  cleanLocal(data) {
    return cleanCollection({ collection: safeParse(data) });
  }

  handleValidation({ target, env, strTarget, cb }) {
    // return validateCollection({
    //   collection: parsed,
    // }).then(data => {
    //   cb(data);
    // });
  }

  updateVariables(variables) {
    this.rootStore.updateVariables(variables);
  }

  @computed
  get currentEnv() {
    return this.rootStore.variables || {};
  }

  @computed
  get oas2Refs() {
    return _.uniq(_.get(this.parsed, 'settings.testing.oas2', []));
  }

  @computed
  get endpoints() {
    return _.sortBy(this.connectedEndpoints, 'path') || [];
  }

  @computed
  get stepResultsMap() {
    const results = {};
    for (const r of this.stepResults) {
      results[r.id] = r;
    }
    return results;
  }

  @computed
  get currentScenarioPath() {
    if (_.size(this.currentPath) < 2) {
      return null;
    }

    return this.currentPath.slice(0, 2);
  }

  @computed
  get currentScenarioId() {
    // dont want a scenarioID on the coverage page
    if (_.last(this.currentScenarioPath) === 'testing') {
      return;
    }
    return _.last(this.currentScenarioPath);
  }

  @computed
  get currentScenario() {
    const currentScenarioPath = this.currentScenarioPath;
    if (!currentScenarioPath) {
      return {};
    }

    const scenario = _.get(this.parsed, currentScenarioPath, {});

    return {
      stage: currentScenarioPath[0],
      id: currentScenarioPath[1],
      key: currentScenarioPath[1],
      path: currentScenarioPath,
      data: {
        ...scenario,
        id: currentScenarioPath[1],
      },
    };
  }

  @computed
  get currentStepPath() {
    if (_.size(this.currentPath) < 4) {
      return null;
    }

    return this.currentPath.slice(0, 4);
  }

  @computed
  get currentStepId() {
    return _.get(this.currentStep, 'id');
  }

  @computed
  get currentStep() {
    const currentStepPath = this.currentStepPath;
    if (!currentStepPath) {
      return {};
    }

    const step = _.get(this.parsed, currentStepPath, {});

    return {
      index: _.last(currentStepPath),
      id: _.last(currentStepPath),
      data: step,
      path: currentStepPath,
    };
  }

  /*
   * ENV
   */

  @computed
  get currentEnvVariables() {
    const target =
      _.get(this.currentStep, 'data') || _.get(this.currentScenario, 'data') || this.parsed || {};

    // return unique $$.env values, stripped of the $$.env
    return _.compact(
      _.uniq(
        _.map(extractVariables(target), v => {
          if (!_.includes(v, '$$.env')) {
            return null;
          }

          return v.replace(/\$\$\.env[.]*|{|}/g, '');
        })
      )
    );
  }

  @computed
  get currentPopulatedEnvVariables() {
    const variables = this.currentEnvVariables || {};
    const populatedVariables = {};

    _.forEach(variables, v => {
      populatedVariables[v] = _.get(this.currentEnv, v);
    });

    return populatedVariables;
  }

  @computed
  get currentResult() {
    const currentStep = this.currentStep;
    if (currentStep.data) {
      return _.find(this.stepResults, {
        id: `${this.currentScenarioId}-${this.currentStepId}`,
      });
    }

    const currentScenario = this.currentScenario;
    if (currentScenario.data) {
      return _.find(this.scenarioResults, { id: currentScenario.data.id });
    }

    return this.collectionResult;
  }

  @computed
  get flattenedStepResults() {
    let steps = [];

    const scenariosObj = _.get(this.collectionResult, 'data.output.body.scenarios', {});
    _.forEach(scenariosObj, scenario => {
      steps = steps.concat(flattenRefOutput(_.get(scenario, 'steps', [])));
    });

    return steps;
  }

  @computed
  get currentRunning() {
    if (this.collectionRunning) {
      return {
        type: 'collection',
      };
    }

    if (this.currentScenarioId) {
      const runningScenario = this.scenariosRunning.get(this.currentScenarioId);
      if (runningScenario) {
        return {
          type: 'scenario',
        };
      }
    }

    if (this.currentStepId) {
      const runningStep = this.stepsRunning.get(`${this.currentScenarioId}-${this.currentStepId}`);
      if (runningStep) {
        return {
          type: 'step',
        };
      }
    }

    return null;
  }

  @action
  addCollectionResult(collection, result) {
    const newResult = new CollectionResult();
    newResult.id = collection.id;
    newResult.createdAt = new Date();
    newResult.data = {
      output: {
        status: 200,
        body: result,
      },
    };
    this.collectionResult = newResult;

    for (const path of scenarioPathLocations) {
      const target = _.get(collection, path, {});

      _.forEach(target, (scenario, id) => {
        const scenarioResult = _.get(result, [...path.split('.'), id]);
        if (scenarioResult) {
          this.addScenarioResult(
            {
              stage: path,
              id,
              key: id,
              path: [path, id],
              data: {
                id,
                ...scenario,
              },
            },
            scenarioResult
          );
        }
      });
    }
  }

  @action
  addScenarioResult(scenario, result) {
    const newResult = new ScenarioResult();
    newResult.id = scenario.id;
    newResult.createdAt = new Date();
    newResult.path = scenario.path;
    newResult.data = {
      output: {
        ..._.get(result, 'response', {}),
        body: result,
      },
    };

    const existingIndex = _.findIndex(this.scenarioResults, {
      id: scenario.id,
    });
    if (existingIndex >= 0) {
      this.scenarioResults[existingIndex] = newResult;
    } else {
      this.scenarioResults.push(newResult);
    }

    const steps = _.get(scenario, 'data.steps', []);
    _.forEach(steps, (s, stepIndex) => {
      const step = {
        index: stepIndex,
        id: stepIndex,
        data: steps[stepIndex],
        path: scenario.path.concat(['steps', stepIndex]),
      };

      const stepResult = _.get(result, ['steps', stepIndex]);
      if (stepResult) {
        this.addStepResult(scenario, step, stepResult);
      }
    });
  }

  @action
  addStepResult(scenario, step, result) {
    const resultId = `${scenario.id}-${step.id}`;

    const newResult = new StepResult();
    newResult.id = resultId;
    newResult.createdAt = new Date();
    newResult.type = step.data.type;
    newResult.data = result;
    newResult.path = step.path;

    const existingIndex = _.findIndex(this.stepResults, { id: resultId });
    if (existingIndex >= 0) {
      this.stepResults[existingIndex] = newResult;
    } else {
      this.stepResults.push(newResult);
    }
  }

  @action
  handleRunError(err) {
    if (axios.isCancel(err)) {
      alert.warning(err.message);
    } else {
      const data = _.get(err, 'response.data');
      if (data) {
        const collection = this.replaceCollectionPathParams(this.parsed);
        this.addCollectionResult(collection, {
          ...data,
        });
        alert.error('An error occurred while running. Check the collection result tab.');
      } else {
        if (err.response) {
          this.error = err.response;
        } else {
          alert.error(
            'The API did not return a response. Is it running and accessible? If you are sending this request from a web browser, does the API support cors?'
          );
        }
      }
    }
  }

  @action
  runStep = flow(function* runStep(index, { spec, headers } = {}) {
    let collection = this.parsed;
    const scenario = _.get(this.currentScenario, 'data');
    const scenarioPath = _.get(this.currentScenario, 'path');
    if (!scenario) {
      return;
    }

    let step;
    if (typeof index !== 'undefined') {
      step = {
        index,
        id: index,
        data: _.get(this.currentScenario, ['data', 'steps', index]),
        path: [...scenarioPath, 'steps', index],
      };
    } else {
      step = this.currentStep;
    }

    if (!step) {
      return;
    }

    collection = produce(collection, draft => {
      _.set(
        draft,
        step.path,
        this.setQueryString(this.replaceStepPathParams(_.cloneDeep(step), scenario.id))
      );
    });

    // step will always be the first in the result because we're only running one
    let stepResultPath = scenarioPath.concat(['steps', 0]);

    // allow callee to add arbitrary, hidden headers (useful for embedded try it out like on instance editor)
    // instance editor needs to be able to add the auth'd users cookie as a step header
    if (headers) {
      collection = produce(collection, draft => {
        _.set(
          draft,
          [...step.path, 'input', 'headers'],
          Object.assign({}, headers, _.get(step, 'data.input.headers', {}))
        );
      });
    }

    const stepId = `${scenario.id}-${step.id}`;

    const ctx = this.scenarioCtx.get(scenario.id) || {};
    const env = this.currentEnv || {};
    const skipPrism = env.skipPrism;

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'run',
      eventLabel: `Run Step - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    const runMap = {
      [scenarioPath[0]]: {
        [scenarioPath[1]]: {
          [step.id]: true,
        },
      },
    };

    const stepPath = [scenarioPath[0], scenarioPath[1], 'steps', step.id];

    this.clearError();

    const cancelToken = axios.CancelToken;
    const canceler = cancelToken.source();
    const request = createScenarioStepRequest({
      env,
      ctx,
      runMap,
      stepPath,
      skipPrism,
      collection,
      cancelToken: canceler.token,
      withCredentials: this.rootStore.isClient,
      specs: spec ? [spec] : _.map(this.connectedSpecs, 'data'),
    });
    this.stepsRunning.set(stepId, canceler);

    let res;
    let err;
    try {
      res = yield axios.request(request);
    } catch (error) {
      err = error;
    }

    this.stepsRunning.set(stepId, false);

    if (err) {
      if (skipPrism) {
        if (axios.isCancel(err)) {
          alert.warning(err.message);
          return;
        }

        if (err.response) {
          res = err.response;
        } else {
          this.handleRunError(err);
          return;
        }
      } else {
        this.handleRunError(err);
        return;
      }
    }

    if (!res) return;

    let stepResult = _.get(res.data, stepResultPath) || {};

    if (skipPrism) {
      stepResult = {
        ...stepResult,
        output: {
          ...stepResult.output,
          status: res.status,
          headers: res.headers,
        },
      };
    }

    this.addStepResult(scenario, step, stepResult);

    // update env
    this.updateVariables(_.get(res, ['data', 'env'], {}));

    // update ctx
    this.updateScenarioCtx(scenario.id, _.get(res.data, [...stepResultPath, 'ctx'], {}), {
      overwrite: true,
    });
  });

  @action
  stopStep() {
    const scenarioId = _.get(this.currentScenario, 'id');
    if (!scenarioId) {
      return;
    }

    const stepId = this.currentStep.id;
    const key = `${scenarioId}-${stepId}`;
    const req = this.stepsRunning.get(key);
    if (!req) {
      alert.error('Step is not currently running.');
      return;
    }

    req.cancel('Step canceled by user.');
  }

  /*
   * CTX
   */

  @action
  updateScenarioCtx(scenarioId, ctx = {}, { overwrite } = {}) {
    if (overwrite) {
      this.scenarioCtx.set(scenarioId, ctx);
      return;
    }

    if (_.isEmpty(ctx)) {
      return;
    }

    let existing = this.scenarioCtx.get(scenarioId) || {};

    _.merge(existing, ctx);

    this.scenarioCtx.set(scenarioId, existing);
  }

  ctxVariables(step) {
    const target = _.merge({}, _.get(step, 'input', {}), _.get(step, 'after.assertions', {}));

    // return unique $.ctx values, stripped of the $.ctx
    return _.compact(
      _.uniq(
        _.map(extractVariables(target), v => {
          if (!_.includes(v, '$.ctx')) {
            return null;
          }

          return v.replace(/\$\.ctx[.]*|{|}/g, '');
        })
      )
    );
  }

  populatedCtxVariables(scenarioId, variables) {
    if (!scenarioId) {
      return {};
    }

    const currentVariables = this.scenarioCtx.get(scenarioId) || {};
    const populatedVariables = {};

    _.forEach(variables, v => {
      _.set(populatedVariables, v, _.get(currentVariables, v));
    });

    return populatedVariables;
  }

  @computed
  get currentCtxVariables() {
    return this.ctxVariables(_.get(this.currentStep, 'data', {}));
  }

  @computed
  get currentPopulatedCtxVariables() {
    return this.populatedCtxVariables(
      _.get(this.currentScenario, 'data.id'),
      this.currentCtxVariables
    );
  }

  @action
  updateCurrentCtxVariables(newVars) {
    const scenarioId = _.get(this.currentScenario, 'data.id');
    if (scenarioId) {
      this.updateScenarioCtx(scenarioId, safeParse(newVars));
    }
  }

  // Path params
  getStepPathParams(step, scenarioId) {
    const key = `${scenarioId}-${step.id}`;
    const existing = this.stepPathParams.get(key);

    const currentPathParams = {};

    const pathParams = extractParams(_.get(step, 'data.input.url', ''));
    for (let param of pathParams) {
      if (!/^\$\.ctx\..*/gi.test(param) && !/^\$\$\.env\..*/gi.test(param)) {
        currentPathParams[param] = _.get(existing, param);
      }
    }

    return currentPathParams;
  }

  @computed
  get currentStepPathParams() {
    return this.getStepPathParams(this.currentStep, this.currentScenario.id);
  }

  @action
  updateCurrentStepPathParams(newParams) {
    const scenarioId = _.get(this.currentScenario, 'id');
    const stepId = _.get(this.currentStep, 'id');
    const key = `${scenarioId}-${stepId}`;

    let existing = this.stepPathParams.get(key);

    if (existing) {
      this.stepPathParams.delete(key);
    } else {
      existing = {};
    }
    _.merge(existing, newParams);

    this.stepPathParams.set(key, existing);
  }

  replaceCollectionPathParams(collection) {
    return produce(collection, draft => {
      for (let sType of ['before', 'scenarios', 'after']) {
        _.keys(draft[sType]).forEach(scenarioKey => {
          _.set(
            draft,
            [sType, scenarioKey],
            this.replaceScenarioPathParams(draft[sType][scenarioKey], scenarioKey)
          );
        });
      }
    });
  }

  replaceScenarioPathParams(scenario, scenarioId) {
    if (!scenario) {
      return;
    }

    return produce(scenario, draft => {
      _.keys(scenario.steps).forEach(stepIndex => {
        const newStep = this.replaceStepPathParams(
          {
            index: stepIndex,
            id: stepIndex,
            data: draft.steps[stepIndex],
          },
          scenarioId
        );

        _.set(draft, ['steps', stepIndex], newStep);
      });
    });
  }

  replaceStepPathParams(step, scenarioId) {
    const pathParams = this.getStepPathParams(step, scenarioId);

    if (!_.isEmpty(pathParams)) {
      step.data = produce(step.data, draft => {
        _.set(
          draft,
          ['input', 'url'],
          replacePathParams(_.get(step, 'data.input.url'), pathParams)
        );
      });
    }

    return step.data;
  }

  /*
   * QUERY PARAMS
   */

  setQueryString(stepData) {
    let url = _.get(stepData, 'input.url');
    const params = _.get(stepData, 'input.query');

    const v = buildQueryString({ url, params });
    const newUrl = v.url;
    if (newUrl) {
      stepData = produce(stepData, draft => {
        _.set(draft, ['input', 'url'], newUrl);
      });
    }

    return stepData;
  }

  /*
   * ACCORDIONS
   */

  @computed
  get currentScenarioTab() {
    return this.scenarioTabs[this.currentScenarioId] || 'settings';
  }

  @action
  toggleScenarioTab(scenarioId, tab) {
    this.scenarioTabs = {
      ...this.scenarioTabs,
      [scenarioId]: tab,
    };
  }

  @action
  toggleCurrentScenarioTab(tab) {
    this.toggleScenarioTab(this.currentScenarioId, tab);
  }

  @action
  clearError = () => {
    this.error = null;
  };

  @action
  resetStepResults() {
    this.stepResults = [];
  }
}

export default class CollectionEditorStore {
  rootStore;
  editors = {};

  constructor({ rootStore }) {
    this.rootStore = rootStore;
  }

  initEditor = ({ entity, stepPathParams, editPath } = {}) => {
    this.editors[entity.id] = new CollectionEditor({
      entity,
      stepPathParams,
      editPath,
      rootStore: this.rootStore,
    });
  };

  getEditor = ({ id }) => {
    return this.editors[id];
  };
}
