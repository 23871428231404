import * as React from 'react';

const cn: any = require('classnames');

import {
  FaSymbol,
  FlipProp,
  IconDefinition,
  IconLookup,
  PullProp,
  RotateProp,
  Transform,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, sizes } from '@core/ui/enums';

export type IIcon = IconDefinition;

export interface IconProps {
  icon: IIcon;
  className?: string;
  color?: colors;
  size?: sizes;
  mask?: IconLookup;
  spin?: boolean;
  pulse?: boolean;
  border?: boolean;
  fixedWidth?: boolean;
  inverse?: boolean;
  listItem?: boolean;
  flip?: FlipProp;
  pull?: PullProp;
  rotation?: RotateProp;
  transform?: string | Transform;
  symbol?: FaSymbol;
  style?: React.CSSProperties;
}

export const Icon = ({ className, color, size, ...iconProps }: IconProps) => {
  return (
    <FontAwesomeIcon
      className={cn(className, {
        [`text-${colors[color || colors.black]}`]: color,
        [`text-${sizes[size || sizes.md]}`]: size,
      })}
      {...iconProps}
    />
  );
};
