import _ from 'lodash';
import URL from 'url-parse';
import JsonSchemaGenerator from 'json-schema-generator';

import { safeStringify, safeParse } from '../json';
import { pruneObject } from '../general';
import { extendSchema } from '../schemas/json-schema-generator';
import { parseDynamicParams, getEndpointFromSpec, computePathParamsFromUri } from '../specs';
import { parseQuery } from '../query';

// result is a full collection result object
export const collectionResultToHar = ({ result = {} }) => {
  const harObj = {
    entries: [],
  };

  const { scenarios = {} } = result;
  _.forEach(scenarios, scenario => {
    const { steps = [] } = scenario;
    _.forEach(steps, step => {
      const { input = {}, output = {} } = step;

      let url;
      let query = {};
      try {
        url = new URL(input.url);
        if (!_.isEmpty(url.search)) {
          query = parseQuery(url.search);
        }
      } catch (e) {
        console.warn('invalid url', input.url);
        return;
      }

      // build the entry
      const entry = {
        request: {
          method: input.method,
          url: url.origin + url.pathname,
        },
      };

      if (!_.isEmpty(input.headers)) {
        entry.request.headers = _.map(input.headers, (v, k) => ({
          name: k,
          value: v,
        }));
      }

      if (!_.isEmpty(query)) {
        entry.request.queryString = _.map(query, (v, k) => ({
          name: k,
          value: v,
        }));
      }

      if (!_.isEmpty(input.body)) {
        entry.request.postData = {};

        const contentType = _.get(input, 'headers.Content-Type', '');
        if (contentType) {
          entry.request.postData.mimeType = input.headers['Content-Type'];
        }

        if (contentType.match(/javascript|json/g)) {
          entry.request.postData.text = safeStringify(input.body);
        } else {
          const params = safeParse(input.body);
          entry.request.postData.params = _.map(params, (v, k) => ({
            name: k,
            value: v,
          }));
        }
      }

      // buid the entry response
      if (!_.isEmpty(output)) {
        entry.response = {
          status: output.status,
        };

        if (!_.isEmpty(output.headers)) {
          entry.response.headers = _.map(output.headers, (v, k) => ({
            name: k,
            value: v,
          }));
        }

        if (!_.isEmpty(output.body)) {
          entry.response.content = {};

          const contentType = _.get(output, 'headers.Content-Type', '');
          if (contentType) {
            entry.response.content.mimeType = output.headers['Content-Type'];
          }

          entry.response.content.text = safeStringify(output.body);
        }
      }

      harObj.entries.push(entry);
    });
  });

  return harObj;
};

const headerBlacklist = [
  'Content-Type',
  'Cache-Control',
  'Connection',
  'Content-Length',
  'Date',
  'Etag',
  'Server',
  'Via',
];

const extendParameters = ({ targetLocation, targetValue, parameters = [], extraProps = {} }) => {
  _.forEach(targetValue, ({ name, value }) => {
    if (_.includes(headerBlacklist, name) || _.startsWith(name, 'X-')) {
      return;
    }

    const index = _.findIndex(parameters, { in: targetLocation, name: name });
    const existing = parameters[index] || {
      in: targetLocation,
      name: name,
    };

    existing.type = typeof safeParse(value, value);
    _.merge(existing, extraProps);

    if (index < 0) {
      parameters.push(existing);
    } else {
      parameters[index] = existing;
    }
  });

  return parameters;
};

export const harToSwagger2 = ({ existingSpec = {}, har = {} }) => {
  const spec = {
    paths: {},
  };

  const { entries = [] } = har;
  _.forEach(entries, entry => {
    // REQUEST
    const { request = {} } = entry;
    const { headers = [], queryString = [], postData = {} } = request;

    const method = _.toLower(request.method);
    let url;
    let pathname;
    try {
      url = new URL(request.url);
      pathname = parseDynamicParams({ url: url.pathname || '/' });
    } catch (e) {
      console.warn('invalid url', request.url);
      return;
    }

    if (!method || !url) {
      return;
    }

    // is this path already in the spec?
    if (!_.isEmpty(existingSpec)) {
      const endpoint = getEndpointFromSpec({
        spec: {
          data: existingSpec,
        },
        url,
      });
      if (endpoint && endpoint.path) {
        pathname = endpoint.path;
      }
    }

    const targetPath =
      _.get(existingSpec, ['paths', pathname]) || _.get(spec, ['paths', pathname]) || {};
    const targetOperation = _.get(targetPath, [method]) || _.get(spec, [method]) || {};

    // content type stuff
    const consumes = _.first(
      (postData.mimeType || _.get(_.find(headers, { name: 'Content-Type' }), 'value', '')).split(
        ';'
      )
    );
    if (consumes) {
      spec.consumes = spec.consumes || [];
      spec.consumes.push(consumes);
      spec.consumes = _.uniq(spec.consumes);
    }

    // query params
    if (!_.isEmpty(queryString)) {
      targetOperation.parameters = extendParameters({
        targetLocation: 'query',
        targetValue: queryString,
        parameters: targetOperation.parameters,
      });
    }

    // body params
    if (!_.isEmpty(postData.params)) {
      targetOperation.parameters = extendParameters({
        targetLocation: 'formData',
        targetValue: postData.params,
        parameters: targetOperation.parameters,
      });
    } else if (!_.isEmpty(postData.text)) {
      // handle text bodies

      targetOperation.parameters = targetOperation.parameters || [];
      const index = _.findIndex(targetOperation.parameters, { in: 'body' });
      const bodyParam = targetOperation.parameters[index] || {
        in: 'body',
        name: 'body',
      };

      const example = safeParse(postData.text, postData.text);
      let newSchema = {};
      try {
        newSchema = JsonSchemaGenerator(JSON.parse(postData.text));
      } catch (e) {}

      if (bodyParam.schema) {
        bodyParam.schema = extendSchema({
          base: bodyParam.schema,
          extend: newSchema,
          extendIsSchema: true,
        });
      } else {
        bodyParam.schema = newSchema;
      }

      // add the request body example
      if (consumes && !_.isEmpty(example)) {
        if (!_.get(bodyParam, ['x-examples', consumes])) {
          _.set(bodyParam, ['x-examples', consumes], pruneObject(example));
        }
      }

      if (index < 0) {
        targetOperation.parameters.push(bodyParam);
      } else {
        targetOperation.parameters[index] = bodyParam;
      }
    }

    // RESPONSE
    const { response = {} } = entry;
    if (!_.isEmpty(response) && response.status) {
      // content type stuff
      const contentHeader = _.find(response.headers, { name: 'Content-Type' });
      const produces =
        _.get(response, 'content.mimeType') ||
        _.first(_.split(_.get(contentHeader, 'value', ''), ';'));

      if (produces) {
        spec.produces = spec.produces || [];
        spec.produces.push(produces);
        spec.produces = _.uniq(spec.produces);
      }

      const targetResponse = _.get(targetOperation, ['responses', response.status]) || {
        description: '',
      };

      // headers
      if (!_.isEmpty(response.headers)) {
        targetResponse.headers = targetResponse.headers || {};

        _.forEach(response.headers, ({ name, value }) => {
          if (_.includes(headerBlacklist, name) || _.startsWith(name, 'X-')) {
            return;
          }

          const newHeader = targetResponse.headers[name] || {};
          if (!newHeader.type || newHeader.type === 'null') {
            newHeader.type = typeof safeParse(value, value);
          }
          targetResponse.headers[name] = newHeader;
        });

        if (_.isEmpty(targetResponse.headers)) {
          delete targetResponse.headers;
        }
      }

      let example = _.get(response, 'content.text');
      example = safeParse(example, example);

      // schema
      try {
        let newSchema = {};
        newSchema = JsonSchemaGenerator(JSON.parse(_.get(response, 'content.text')));
        if (targetResponse.schema) {
          targetResponse.schema = extendSchema({
            base: targetResponse.schema,
            extend: newSchema,
            extendIsSchema: true,
          });
        } else {
          targetResponse.schema = newSchema;
        }
      } catch (e) {}

      // example
      if (produces && !_.isEmpty(example)) {
        if (!_.get(targetResponse, ['examples', produces])) {
          _.set(targetResponse, ['examples', produces], pruneObject(example));
        }
      }

      targetOperation.responses = targetOperation.responses || {};
      _.set(targetOperation, ['responses', response.status], targetResponse);
    }

    // path params
    const pathParams = computePathParamsFromUri(pathname);
    if (!_.isEmpty(pathParams)) {
      const parameters = extendParameters({
        targetLocation: 'path',
        targetValue: pathParams,
        parameters: targetPath.parameters,
        extraProps: {
          type: 'string',
          required: true,
        },
      });

      if (!_.isEmpty(parameters)) {
        _.set(spec, ['paths', pathname, 'parameters'], parameters);
      }
    }

    // SET IT
    _.set(spec, ['paths', pathname, method], targetOperation);
  });

  return spec;
};
