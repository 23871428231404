import _ from 'lodash';

import { parseQuery, stringifyQuery } from '../query';

export const buildParams = (location = {}, params = {}, options = {}) => {
  let currentParams = options.preserve && location.search ? parseQuery(location.search) : {};

  const computed = {};
  Object.assign(computed, currentParams, params);

  return _.omitBy(computed, _.isEmpty);
};

export const buildQueryParams = (params, options = {}) => {
  let pathname = options.pathname || _.get(window, 'location.pathname');

  const newParams = buildParams(window.location, params, {
    preserve: true,
    ...options,
  });

  if (!_.isEmpty(newParams)) {
    pathname += `?${stringifyQuery(newParams)}`;
  }
  return pathname;
};

// accepts array or string path
// any slashes present in the path must be converted to ~1
export const pathToHash = ({ path }) => {
  const targetPath = _.map(_.cloneDeep(_.toPath(path || [])), part => part.replace(/\//g, '~1'));
  let jsonPath = `/${targetPath.join('/')}`;

  return jsonPath;
};

// ~1 in the hash must be converted to /
export const hashToPath = ({ hash }) => {
  return _.compact(_.map(_.trim(hash, '#/').split('/'), part => part.replace(/~1/g, '/')));
};

export const buildHash = (hash, options = {}) => {
  let pathname = options.pathname || _.get(window, 'location.pathname');

  let targetHash = hash;
  if (!_.startsWith(hash, '#') && (!options.noSign && !_.isEmpty(hash))) {
    targetHash = `#${hash}`;
  }

  return `${pathname}${targetHash}`;
};
