import { observable, computed, action, toJS } from 'mobx';
import _ from 'lodash';
import { registerLogger } from '@platform/utils/logging';

import Base from './base';

const log = registerLogger('@platform/stores', 'AppStore');

export default class AppStore extends Base {
  persist = [
    {
      key: 'splitPanes',
      watch: 'splitPanesLastUpdated',
      deserialize: val => {
        return observable.map(val, { deep: false });
      },
    },
    {
      key: 'srnHistory',
    },
    {
      key: 'desktopDownloadClosed',
    },
    {
      key: 'headerHidden',
    },
  ];

  constructor(opts) {
    super(opts);

    this.init(opts || {});
  }

  setupReactions() {
    super.setupReactions();

    if (typeof window === 'undefined') return;
  }

  /*
  * GENERIC ERRORS
  */

  @observable
  errors = [];

  @computed
  get hasErrors() {
    return this.errors.length > 0;
  }

  @action
  addError(error, { id, ...options } = {}) {
    this.errors.push({
      id,
      error,
      ...options,
    });
  }

  @action
  removeError(id) {
    _.remove(this.errors, { id });
  }

  @action.bound
  clearErrors() {
    this.errors = [];
  }

  @action
  updateError(index, value) {
    this.errors[index] = value;
  }

  @action
  clearError(index) {
    _.pullAt(this.errors, index);
  }

  /*
  * UI CACHE
  */

  @observable
  ui = observable.map({}, { deep: false });

  @action
  updateUi(transformation, key, path, value, defaultValue) {
    let current = this.ui.get(key) || defaultValue;

    log.debug('cache.updateUi', { transformation, key, path, value, defaultValue });

    switch (transformation) {
      case 'set':
        if (_.isEmpty(path)) {
          current = value;
        } else {
          _.set(current, path, value);
        }

        break;
      case 'push':
        _.set(
          current,
          path,
          _.get(current, path, []).concat(value instanceof Array ? value : [value])
        );
        break;
      case 'pull':
        _.pullAt(_.get(current, path, []), value);
        break;
      case 'unset':
        _.unset(current, path);
        break;
      default:
        console.warn(`AppStore updateUi ${transformation} transformation not supported`);
        return;
    }

    // We have to complete delete/set the key to rebuild observers
    // this leads to two observer fires (shown in dev tools) but one re-render
    this.ui.delete(key);
    this.ui.set(key, current);
  }

  injectUi(key, defaultValue = {}) {
    const existing = this.ui.get(key);
    if (!existing && defaultValue) {
      this.ui.set(key, defaultValue);
    }

    return {
      ui: existing || defaultValue,
      clearUi: () => {
        this.updateUi('set', key, '', {});
      },
      updateUi: (t, p, v) => {
        this.updateUi(t, key, p, v, defaultValue);
      },
    };
  }

  /*
  * SPLIT PANES
  */

  @observable
  splitPanesLastUpdated = 0;

  @observable
  splitPanes = observable.map({}, { deep: false });

  @action
  updateSplitPane(id, data) {
    this.splitPanes.delete(id);
    this.splitPanes.set(id, data);
    this.splitPanesLastUpdated = new Date().getTime();
  }

  injectSplitPane(id) {
    return {
      pane: this.splitPanes.get(id) || {},
      updateSplitPane: data => {
        this.updateSplitPane(id, data);
      },
    };
  }

  // srn history (DEPRECATED - replaced by upcoming "tabs" system)

  @observable.ref
  srnHistory = [];

  @action
  addSrn({ srn, name }) {
    const newHistory = this.removeSrn(srn);
    newHistory.unshift({ srn, name });
    this.srnHistory = newHistory.slice(0, 5);
  }

  @action
  removeSrn(srn) {
    this.srnHistory = _.reject(this.srnHistory, { srn });
    return this.srnHistory;
  }

  /*
  * DESKTOP DOWNLOAD SELECTOR
  */

  @observable
  desktopDownloadClosed = false;

  @action
  hideDesktopDownload() {
    this.desktopDownloadClosed = true;
  }

  /*
  * MODALS
  */

  @computed
  get activeModal() {
    return _.get(this.rootStore, 'stores.routerStore.location.query.modal');
  }

  openModal(modal) {
    this.rootStore.stores.routerStore.setQueryParams({ modal });
  }

  closeModal() {
    this.rootStore.stores.routerStore.setQueryParams({ modal: null }, { preserve: true });
  }

  /*
  * APP HEADER
  */

  @observable
  headerHidden = false;

  @action.bound
  toggleHeader() {
    this.headerHidden = !this.headerHidden;
  }

  /*
  * DISCUSSIONS
  */

  // TODO: support more status options
  // status can be null, open
  @action
  toggleDiscussions(status) {
    const params = { discussions: status };

    // if toggling discussions to off, also remove any active discussion id
    if (!params.discussions) {
      params.discussion = null;
    }

    this.rootStore.stores.routerStore.setQueryParams(params, { replace: true, preserve: false });
  }

  @computed
  get discussionsOpen() {
    return _.get(this.rootStore.stores.routerStore, 'location.query.discussions') ? true : false;
  }

  @computed
  get currentDiscussionId() {
    return _.get(this.rootStore.stores.routerStore, 'location.query.discussion');
  }

  /*
  * ENVIRONMENTS
  */

  @computed
  get environmentsOpen() {
    return _.get(this.rootStore.stores.routerStore, 'location.query.environments') ? true : false;
  }

  @computed
  get currentEnvironmentId() {
    return _.get(this.rootStore.stores.routerStore, 'location.query.environment');
  }
}
