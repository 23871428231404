import { difference, get, split, trim } from 'lodash';

export const getEnv = () => {
  if (typeof Electron !== 'undefined') {
    return 'electron';
  } else if (typeof window !== 'undefined') {
    return 'browser';
  } else if (typeof self !== 'undefined') {
    return 'worker';
  } else if (typeof process !== 'undefined') {
    return 'node';
  }
};

export const getConfigVar = path => {
  let env = getEnv();

  switch (env) {
    case 'electron':
      return get(Electron, `env.${path}`);
    case 'browser':
      return get(window, `__SL.config.${path}`);
    case 'worker':
      return get(self, `__SL.config.${path}`);
    case 'node':
      return get(process, `CONFIG.${path}`) || get(process, `env.${path}`);
  }

  return undefined;
};

export const isOnPrem = () => {
  return getConfigVar('STRIPE_PK') ? false : true;
};

export const isStandardAuthDisabled = () => {
  return !!(
    getConfigVar('SL_SSO_ENTRYPOINT') ||
    getConfigVar('GITHUB_URL') ||
    getConfigVar('SL_LOGIN_PATH')
  );
};

// When we decide that a feature goes live we should remove it from this list
const ExperimentalFeatures = ['user-projects', 'user-explorer', 'org-explorer'];

// We return the cross section of ENV Vars and ExperimentalFeatures to get the true feature list
export const getExperimentalFeatures = () => {
  let features = getConfigVar('SL_EXPERIMENTAL_FEATURES') || '';
  features = split(features, ',').map(trim);

  return difference(ExperimentalFeatures, features);
};
