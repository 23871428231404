const _ = require('lodash');
const yaml = require('js-yaml');

export const safeLoad = (target, defaultValue) => {
  if (typeof target === 'string') {
    try {
      return yaml.load(target);
    } catch (e) {
      return !_.isUndefined(defaultValue) ? defaultValue : {};
    }
  }

  return target;
};

export const safeDump = target => {
  if (!_.isNil(target) && typeof target !== 'string') {
    return yaml.dump(target);
  }

  return target;
};
