import _ from 'lodash';

const buildPart = (router, parts = [], data) => {
  const targetPath = [parts[0]];
  const remainingParts = parts.slice(1);

  if (parts[0] instanceof RegExp) {
    targetPath.unshift('__regexs');
    if (!_.isEmpty(remainingParts)) {
      let val = _.get(router, targetPath);
      if (!val) {
        val = {
          __matcher: parts[0],
          __routes: {},
        };
      } else {
        val.__routes = val.__routes || {};
      }
      _.set(router, targetPath, val);
      buildPart(val.__routes, remainingParts, data);
    } else {
      _.set(router, targetPath, {
        __matcher: parts[0],
        __data: data,
      });
    }
  } else {
    if (!_.isEmpty(remainingParts)) {
      const val = _.get(router, targetPath, {});
      _.set(router, targetPath, val);
      buildPart(val, remainingParts, data);
    } else {
      _.set(router, targetPath.concat(['__data']), data);
    }
  }
};

// route map must be an es6 map where the keys are array paths, and the values are
// what will be resolved when the path matches.
//
// for example:
// const routeMap = new Map();
// routeMap.set(['foo', 'bar'], 'foo bar route');
// routeMap.set(['foo', /[0-9]*/], 'foo number route');
// const router = build(routeMap);
export const build = (routeMap = {}, options = {}) => {
  const router = {};

  for (const [path, route] of routeMap) {
    if (_.isEmpty(path)) {
      router.__root = {
        __data: route,
      };
    } else {
      buildPart(router, path, route);
    }
  }

  return router;
};

const routePart = (router, parts = []) => {
  if (router && _.isEmpty(parts)) {
    return _.get(router, '__root.__data');
  }

  const targetPart = parts[0];
  if (!targetPart) {
    return null;
  }

  let match = router[targetPart];
  let childRouter = match;

  if (!match && router.__regexs) {
    for (const i in router.__regexs) {
      if (targetPart.match(router.__regexs[i].__matcher)) {
        match = router.__regexs[i];
        childRouter = router.__regexs[i].__routes;
        break;
      }
    }
  }

  const remainingParts = parts.slice(1);

  if (match && _.isEmpty(remainingParts)) {
    return match.__data;
  } else if (childRouter) {
    return routePart(childRouter, remainingParts);
  }

  return null;
};

// takes a router (built with above build function), and a path.
// Given the routeMap in the build example above:
// route(routerMap, 'foo/bar') === 'foo bar route';
// route(routerMap, 'foo/123') === 'foo number route';
export const route = (router, path, options = {}) => {
  const { delimiter = '/' } = options;

  let pathParts;
  if (path instanceof Array) {
    pathParts = path;
  } else {
    const cleanedPath = _.trim(path, '#/');
    pathParts = cleanedPath.split(delimiter);
  }

  return routePart(router, pathParts);
};
