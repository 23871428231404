import _ from 'lodash';
import qs from 'qs';

export const setQuery = (url, queryObj, options) => {
  options = options || {};

  if (_.isEmpty(queryObj)) {
    return url;
  }

  const urlParts = _.split(url, '?');
  const existingQueryObj = parseQuery(urlParts[1]);

  if (options.preserve) {
    _.merge(queryObj, existingQueryObj);
  }

  _.merge(existingQueryObj, queryObj);

  let queryStr = stringifyQuery(existingQueryObj);

  // replace brackets and $ sign in the URL (mostly used for variables)
  queryStr = queryStr
    .replace(/\%7B/gi, '{')
    .replace(/\%7D/gi, '}')
    .replace(/\%24/gi, '$');

  return `${urlParts[0]}?${queryStr}`;
};

export const parseQuery = (search, opts = {}) => {
  const { arrayFormat = 'brackets' } = opts;

  return qs.parse(_.trimStart(search, '?'), { arrayFormat });
};

export const stringifyQuery = (query = {}, opts = {}) => {
  const { arrayFormat = 'brackets' } = opts;

  return qs.stringify(query, { ...opts, arrayFormat });
};
