import * as React from 'react';

const cn: any = require('classnames');

import { colors } from './enums';
import { divClickHandler } from './events';
import { Icon, IIcon } from './Icon';

export interface ISidebar {
  sections: ISidebarSection[];
  className?: string;
}

export interface ISidebarSection {
  children: Array<ISidebarChild | SidebarChildRenderer>;
  className?: string;
  title?: string;
}

export type SidebarChildRenderer = (renderChild: (opts: ISidebarChild) => any) => void;

export interface ISidebarChild {
  title: string;
  subtitle?: string;
  icon?: IIcon;
  color?: colors;
  active?: boolean;
  className?: string;
  onClick?: divClickHandler;
}

export class Sidebar extends React.Component<ISidebar> {
  public render() {
    const { className, sections = [] } = this.props;

    if (!sections.length) return null;

    return (
      <div className={cn('bg-grey-lightest w-1/4 border-r border-grey-light', className)}>
        {sections.map((section, index) => {
          return this._renderSection(section, index);
        })}
      </div>
    );
  }

  private _renderSection = (section: ISidebarSection, index: number) => {
    const { children, title, className } = section;

    return (
      <div className={cn('flex flex-col', className)} key={index}>
        {title && <div className="px-4 py-3 text-muted">{title}</div>}

        {children.map((child, childIndex) => {
          if (child instanceof Function) {
            return child(this._renderChild);
          }

          return this._renderChild(child, childIndex);
        })}
      </div>
    );
  };

  private _renderChild = (child: ISidebarChild, index?: number) => {
    const { title, subtitle, icon, active, className, onClick } = child;

    return (
      <div
        key={index}
        className={cn(
          'py-3 px-6 font-semibold cursor-pointer opacity-50 hover:opacity-75',
          className,
          {
            'opacity-100': active,
            'bg-darken-50': active,
            'text-primary': active,
          }
        )}
        onClick={onClick}
      >
        {icon && (
          <div
            className="w-12 text-center -ml-4 text-grey"
            style={{ fontSize: '0.9em', marginTop: 2 }}
          >
            <Icon icon={icon} />
          </div>
        )}

        <div>
          <div className="font-bold truncate">{title}</div>
          {subtitle && <div className="mt-1 text-xs text-muted truncate">{subtitle}</div>}
        </div>
      </div>
    );
  };
}
