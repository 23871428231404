import _ from 'lodash';

import { safeParse, safeStringify } from '../json';

export const extractVariables = (target, re) => {
  const toProcess = safeStringify(target);
  const matches = [];

  const reg =
    re ||
    new RegExp(/\{(\$\.[\[\]\.\w- ']+)\}|\{(\$\$\.[\[\]\.\w- ']+)\}|\{(input\.[\[\]\.\w- ']+)\}/g);

  while (true) {
    const match = reg.exec(toProcess);
    if (!match || _.isEmpty(match)) {
      return _.uniq(matches);
    }

    matches.push(match[0]);
  }
};

const cleanVariableValue = value => {
  let cleaned = safeStringify(value);

  if (typeof cleaned === 'string') {
    // escape double quotes
    cleaned = cleaned.replace(/\"/g, '\\"');
  }

  return cleaned;
};

export const replaceVariables = (target, variables = {}) => {
  const parsedVariables = safeParse(variables);
  if (_.isEmpty(target) || _.isEmpty(parsedVariables)) {
    return target;
  }

  let processed = false;
  let toProcess = safeStringify(target);
  const matches = extractVariables(target);

  _.forEach(matches, match => {
    const trimmedVariable = _.trim(match, '{} ');
    const variable = _.replace(trimmedVariable, /^(\$\$|\$|input)\./gi, '');

    let value = _.get(parsedVariables, variable);
    if (_.isUndefined(value)) {
      value = _.get(parsedVariables, trimmedVariable);
    }

    if (typeof value !== 'undefined') {
      if (typeof value === 'string') {
        const replaceRegExp = new RegExp(_.escapeRegExp(match), 'g');
        toProcess = toProcess.replace(replaceRegExp, cleanVariableValue(value));
      } else {
        let m = _.replace(match, '$$.', '\\$\\$.');
        if (m === match) {
          m = _.replace(match, '$.', '\\$.');
        }
        match = m;
        toProcess = toProcess.replace(
          new RegExp(`"${match}"|${match}`, 'g'),
          cleanVariableValue(value)
        );
      }
      processed = true;
    }
  });

  if (processed) {
    toProcess = replaceVariables(toProcess, variables);
  }
  return safeParse(toProcess, toProcess || target);
};
