// adapted from https://github.com/Microsoft/vscode-uri

// @ts-ignore
import { extname, normalize } from '@core/path';

import { ILocation, IUri, IUriComponents, IUriState } from './types';

// OS detection
declare const process: { platform: 'win32' };
declare const navigator: { userAgent: string };
let isWindows: boolean;
if (typeof process === 'object') {
  isWindows = process.platform === 'win32';
} else if (typeof navigator === 'object') {
  const userAgent = navigator.userAgent;
  isWindows = userAgent.indexOf('Windows') >= 0;
}

function _encode(ch: string): string {
  return (
    '%' +
    ch
      .charCodeAt(0)
      .toString(16)
      .toUpperCase()
  );
}

// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
function encodeURIComponent2(str: string): string {
  return encodeURIComponent(str).replace(/[!'()*]/g, _encode);
}

function encodeNoop(str: string): string {
  return str.replace(/[#?]/, _encode);
}

/**
 * Uniform Resource Identifier (URI) http://tools.ietf.org/html/rfc3986.
 * This class is a simple parser which creates the basic component paths
 * (http://tools.ietf.org/html/rfc3986#section-3) with minimal validation
 * and encoding.
 *
 *       foo://example.com:8042/over/there?name=ferret#nose
 *       \_/   \______________/\_________/ \_________/ \__/
 *        |           |            |            |        |
 *     scheme     authority       path        query   fragment
 *        |   _____________________|__
 *       / \ /                        \
 *       urn:example:animal:ferret:nose
 *
 *
 */
export class URI {
  public static isUri(thing: any): thing is URI {
    if (thing instanceof URI) {
      return true;
    }
    if (!thing) {
      return false;
    }
    return (
      typeof (thing as URI).authority === 'string' &&
      typeof (thing as URI).fragment === 'string' &&
      typeof (thing as URI).path === 'string' &&
      typeof (thing as URI).query === 'string' &&
      typeof (thing as URI).scheme === 'string'
    );
  }

  // ---- parse & validate ------------------------

  public static parse(value: string, decode?: boolean): URI {
    const ret = new URI();
    const data = URI._parseComponents(value);
    ret._scheme = data.scheme;
    ret._authority = decode ? decodeURIComponent(data.authority) : data.authority;
    ret._path = decode ? decodeURIComponent(data.path) : data.path;
    ret._query = decode ? decodeURIComponent(data.query) : data.query;
    ret._fragment = decode ? decodeURIComponent(data.fragment) : data.fragment;
    URI._validate(ret);
    return ret;
  }

  public static file(path: string): URI {
    const ret = new URI();
    ret._scheme = 'file';

    // normalize to fwd-slashes on windows,
    // on other systems bwd-slaches are valid
    // filename character, eg /f\oo/ba\r.txt
    if (isWindows) {
      path = path.replace(/\\/g, URI._slash);
    }

    // check for authority as used in UNC shares
    // or use the path as given
    if (path[0] === URI._slash && path[0] === path[1]) {
      const idx = path.indexOf(URI._slash, 2);
      if (idx === -1) {
        ret._authority = path.substring(2);
      } else {
        ret._authority = path.substring(2, idx);
        ret._path = path.substring(idx);
      }
    } else {
      ret._path = path;
    }

    ret._path = normalize(ret._path);

    // Ensure that path starts with a slash
    // or that it is at least a slash
    if (ret._path[0] !== URI._slash) {
      ret._path = URI._slash + ret._path;
    }

    URI._validate(ret);

    return ret;
  }

  public static from(components: IUriComponents): URI {
    return new URI().with(components);
  }

  public static revive(data: any): URI {
    const result = new URI();
    result._scheme = (data as IUriState).scheme || URI._empty;
    result._authority = (data as IUriState).authority || URI._empty;
    result._path = (data as IUriState).path || URI._empty;
    result._query = (data as IUriState).query || URI._empty;
    result._fragment = (data as IUriState).fragment || URI._empty;
    result._fsPath = (data as IUriState).fsPath;
    result._formatted = (data as IUriState).external;
    URI._validate(result);
    return result;
  }

  private static _schemePattern = /^\w[\w\d+.-]*$/;
  private static _singleSlashStart = /^\//;
  private static _doubleSlashStart = /^\/\//;
  private static _empty = '';
  private static _slash = '/';
  private static _regexp = /^(([^:/?#]+?):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
  private static _driveLetterPath = /^\/[a-zA-z]:/;
  private static _upperCaseDrive = /^(\/)?([A-Z]:)/;

  private static _parseComponents(value: string): IUri {
    const ret: IUri = {
      scheme: URI._empty,
      authority: URI._empty,
      path: URI._empty,
      query: URI._empty,
      fragment: URI._empty,
    };

    const match = URI._regexp.exec(value);
    if (match) {
      ret.scheme = match[2] || ret.scheme;
      ret.authority = match[4] || ret.authority;
      ret.path = match[5] || ret.path;
      ret.query = match[7] || ret.query;
      ret.fragment = match[9] || ret.fragment;
    }
    return ret;
  }

  private static _validate(ret: URI): void {
    // scheme, https://tools.ietf.org/html/rfc3986#section-3.1
    // ALPHA *( ALPHA / DIGIT / "+" / "-" / "." )
    if (ret.scheme && !URI._schemePattern.test(ret.scheme)) {
      throw new Error('[UriError]: Scheme contains illegal characters.');
    }

    // path, http://tools.ietf.org/html/rfc3986#section-3.3
    // If a URI contains an authority component, then the path component
    // must either be empty or begin with a slash ("/") character.  If a URI
    // does not contain an authority component, then the path cannot begin
    // with two slash characters ("//").
    if (ret.path) {
      if (ret.authority) {
        if (!URI._singleSlashStart.test(ret.path)) {
          throw new Error(
            '[UriError]: If a URI contains an authority component, then the path component must either be empty or begin with a slash ("/") character'
          );
        }
      } else {
        if (URI._doubleSlashStart.test(ret.path)) {
          throw new Error(
            '[UriError]: If a URI does not contain an authority component, then the path cannot begin with two slash characters ("//")'
          );
        }
      }
    }
  }

  private static _asFormatted(uri: URI, skipEncoding: boolean): string {
    const encoder = !skipEncoding ? encodeURIComponent2 : encodeNoop;

    const parts: string[] = [];

    const { scheme, query, fragment } = uri;
    let { authority, path } = uri;

    if (scheme) {
      parts.push(scheme, ':');
    }

    if (authority || scheme === 'file') {
      parts.push('//');
    }

    if (authority) {
      authority = authority.toLowerCase();
      const idx = authority.indexOf(':');
      if (idx === -1) {
        parts.push(encoder(authority));
      } else {
        parts.push(encoder(authority.substr(0, idx)), authority.substr(idx));
      }
    }

    if (path) {
      // lower-case windows drive letters in /C:/fff or C:/fff
      const m = URI._upperCaseDrive.exec(path);
      if (m) {
        if (m[1]) {
          path = '/' + m[2].toLowerCase() + path.substr(3); // "/c:".length === 3
        } else {
          path = m[2].toLowerCase() + path.substr(2); // // "c:".length === 2
        }
      }

      if (scheme === 'mailto') {
        parts.push(path);
      } else {
        // encode every segement but not slashes
        // make sure that # and ? are always encoded
        // when occurring in paths - otherwise the result
        // cannot be parsed back again
        let lastIdx = 0;
        while (true) {
          const idx = path.indexOf(URI._slash, lastIdx);
          if (idx === -1) {
            parts.push(encoder(path.substring(lastIdx)));
            break;
          }
          parts.push(encoder(path.substring(lastIdx, idx)), URI._slash);
          lastIdx = idx + 1;
        }
      }
    }

    if (query) {
      parts.push('?', encoder(query));
    }

    if (fragment) {
      parts.push('#', encoder(fragment));
    }

    return parts.join(URI._empty);
  }

  private _scheme: string;
  private _authority: string;
  private _path: string;
  private _query: string;
  private _fragment: string;
  private _formatted: string | null;
  private _fsPath: string | null;

  constructor() {
    this._scheme = URI._empty;
    this._authority = URI._empty;
    this._path = URI._empty;
    this._query = URI._empty;
    this._fragment = URI._empty;

    this._formatted = null;
    this._fsPath = null;
  }

  /**
   * scheme is the 'http' part of 'http://www.msft.com/some/path?query#fragment'.
   * The part before the first colon.
   */
  get scheme() {
    return this._scheme;
  }

  /**
   * authority is the 'www.msft.com' part of 'http://www.msft.com/some/path?query#fragment'.
   * The part between the first double slashes and the next slash.
   */
  get authority() {
    return this._authority;
  }

  /**
   * path is the '/some/path' part of 'http://www.msft.com/some/path?query#fragment'.
   */
  get path() {
    return this._path;
  }

  /**
   * query is the 'query' part of 'http://www.msft.com/some/path?query#fragment'.
   */
  get query() {
    return this._query;
  }

  /**
   * fragment is the 'fragment' part of 'http://www.msft.com/some/path?query#fragment'.
   */
  get fragment() {
    return this._fragment;
  }

  // ---- filesystem path -----------------------

  /**
   * Returns a string representing the corresponding file system path of this URI.
   * Will handle UNC paths and normalize windows drive letters to lower-case. Also
   * uses the platform specific path separator. Will *not* validate the path for
   * invalid characters and semantics. Will *not* look at the scheme of this URI.
   */
  get fsPath() {
    if (!this._fsPath) {
      let value: string;
      if (this._authority && this._path && this.scheme === 'file') {
        // unc path: file://shares/c$/far/boo
        value = `//${this._authority}${normalize(this._path)}`;
      } else if (URI._driveLetterPath.test(this._path)) {
        // windows drive letter: file:///c:/far/boo
        value = this._path[1].toLowerCase() + normalize(this._path.substr(2));
      } else {
        // other path
        value = normalize(this._path);
      }
      if (isWindows) {
        value = value.replace(/\//g, '\\');
      }
      this._fsPath = value;
    }

    return this._fsPath;
  }

  get origin(): string {
    if (this.scheme && this.authority) {
      return `${this.scheme}://${this.authority}`;
    }

    return '';
  }

  get location(): ILocation {
    const res = {
      hash: '',
      host: '',
      hostname: '',
      href: this.toURL(),
      origin: this.origin,
      pathname: '',
      port: '',
      protocol: '',
      search: '',
    } as ILocation;

    if (this.scheme) {
      res.protocol = `${this.scheme}:`;
    }

    if (this.authority) {
      res.host = this.authority;
      const parts = this.authority.split(':');
      res.hostname = parts[0];
      res.port = parts[1];
    }

    if (this.path) {
      res.pathname = this.path;
    }

    if (this.query) {
      res.search = `?${this.query}`;
    }

    if (this.fragment) {
      res.hash = `#${this.fragment}`;
    }

    return res;
  }

  public isAbsolute(): boolean {
    return this.scheme === 'mailto' || (this.scheme && this.authority) ? true : false;
  }

  public isFile(root?: URI): boolean {
    if (root && this.scheme === '') {
      return root.scheme === '' || root.scheme === 'file';
    }
    return this.scheme === 'file';
  }

  public isJSONPointer(): boolean {
    return this.fragment !== '' && this.scheme === '' && this.authority === '' && this.path === '';
  }

  // ---- modify to new -------------------------

  public with(change: {
    scheme?: string;
    authority?: string;
    path?: string;
    query?: string;
    fragment?: string;
  }): URI {
    if (!change) {
      return this;
    }

    let { scheme, authority, path, query, fragment } = change;
    if (scheme === void 0) {
      scheme = this.scheme;
    } else if (scheme === null) {
      scheme = '';
    }
    if (authority === void 0) {
      authority = this.authority;
    } else if (authority === null) {
      authority = '';
    }
    if (path === void 0) {
      path = this.path;
    } else if (path === null) {
      path = '';
    }
    if (query === void 0) {
      query = this.query;
    } else if (query === null) {
      query = '';
    }
    if (fragment === void 0) {
      fragment = this.fragment;
    } else if (fragment === null) {
      fragment = '';
    }

    if (
      scheme === this.scheme &&
      authority === this.authority &&
      path === this.path &&
      query === this.query &&
      fragment === this.fragment
    ) {
      return this;
    }

    const ret = new URI();
    ret._scheme = scheme;
    ret._authority = authority;
    ret._path = path;
    ret._query = query;
    ret._fragment = fragment;

    if (ret.isFile()) {
      ret._path = normalize(ret._path);
    }

    URI._validate(ret);

    return ret;
  }

  // ---- printing/externalize ---------------------------

  /**
   *
   * @param skipEncoding Do not encode the result, default is `true`
   */
  public toString(skipEncoding: boolean = true): string {
    if (!skipEncoding) {
      if (!this._formatted) {
        this._formatted = URI._asFormatted(this, false);
      }
      return this._formatted;
    } else {
      // we don't cache that
      return URI._asFormatted(this, true);
    }
  }

  public toURL(): any {
    let url = '';

    if (this._scheme) {
      url += `${this._scheme}:`;

      if (this._authority || this._scheme === 'file') {
        url += '//';
      }
    }

    if (this._authority) {
      url += this._authority;
    }

    if (this._path) {
      url += this._path;
    }

    if (this._query) {
      url += `?${this._query}`;
    }

    if (this._fragment) {
      url += `#${this._fragment}`;
    }

    return url;
  }

  public toJSON(): any {
    const res = {
      fsPath: this.fsPath,
      external: this.toString(true),
      $mid: 1,
    } as IUriState;

    if (this.path) {
      res.path = this.path;
    }

    if (this.scheme) {
      res.scheme = this.scheme;
    }

    if (this.authority) {
      res.authority = this.authority;
    }

    if (this.query) {
      res.query = this.query;
    }

    if (this.fragment) {
      res.fragment = this.fragment;
    }

    return res;
  }

  public toJSONPointer(): string {
    return this.fragment ? `#${this.fragment || '/'}` : '';
  }

  public queryParams(): { [key: string]: string } {
    const params = {};
    const queries = this.query.split('&');

    for (const param of queries) {
      const [key, value] = param.split('=');
      params[key] = value;
    }

    return params;
  }

  public fileExtension(param: string): string {
    const params = this.queryParams();
    const value = params[param];

    if (value) {
      return extname(value);
    }

    return '';
  }
}
